import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { Lock, Mail, RemoveRedEye, VisibilityOff } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { usePostLoginMutation } from '../../../api/api.generated'
// import { useLoginMutation } from '../../../api/authApi'
import { loginFormAdapter } from '../../../utils/adapters'
import ROUTES from '../../../const/routes'
import OphopLogo from '../../../assets/icons/OphopLogo'
import Typography from '../../../components/UI/Typography'
import TextInput from '../../../components/UI/Inputs/TextInput'
import { BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import { ForgetPassword, PasswordInputStack, RegisterWrap } from './styles'
import { setToken } from '../../../utils/axios-utils'
import LinkButton from '../../../components/UI/Buttons/LinkButton'
import { AuthFormWrap, AuthWelcomeMessage } from '../../../global/styles'
import { setWelcomeMessage } from '../authSlice'

const Login = ({ theme }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [login, { isLoading }] = usePostLoginMutation()
  // const [login, { isLoading }] = useLoginMutation()
  const [passwordInputType, setPasswordInputType] = useState('password')
  const submitLoginForm = (formData, formik) => {
    // login(loginFormAdapter(formData))
    login({ body: loginFormAdapter(formData) })
      ?.unwrap()
      .then((res) => {
        if (res.token) {
          setToken(res.token)
          history.push(ROUTES.DASHBOARD)
        }
      })
      .catch((err) => {
        formik.setErrors(err)
      })
  }

  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text')
      return
    }
    setPasswordInputType('password')
  }

  useEffect(() => {
    dispatch(setWelcomeMessage('Welcome to'))
  }, [])

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          password: Yup.string().required('Required')
        })}
        onSubmit={submitLoginForm}
      >
        <AuthFormWrap>
          <OphopLogo fill={theme.colors.skyBase} />
          <AuthWelcomeMessage variant="textM" color={theme.colors.skyNeutral_1}>
            Welcome Back! Please enter with your own account to access the
            experience
          </AuthWelcomeMessage>
          <TextInput
            type="email"
            placeholder="Enter your Email"
            leftIcon={<Mail />}
            size={INPUT_SIZES.LARGE}
            inputName="email"
            label="Email"
          />

          <PasswordInputStack justify="flex-start" alignItems="flex-end">
            <TextInput
              type={passwordInputType}
              placeholder="Enter your password"
              leftIcon={<Lock />}
              rightIcon={
                passwordInputType === 'password' ? (
                  <RemoveRedEye />
                ) : (
                  <VisibilityOff />
                )
              }
              rightIconClick={handleShowPassword}
              size={INPUT_SIZES.LARGE}
              inputName="password"
              label="Password"
            />
            {false &&
            <ForgetPassword
              onClick={() => {
                history.push(ROUTES.FORGOT_PASSWORD)
              }}
              type="button"
            >
              Forgot Password?
            </ForgetPassword>
            }
          </PasswordInputStack>
          <Button
            isLoading={isLoading}
            size={BUTTONS_SIZES.LARGE}
            type="submit"
          >
            Login to account
          </Button>
          { false &&
          <RegisterWrap justify="center" alignItems="center">
            <Typography variant="textS" color={theme.colors.skyNeutral1}>
              Don&apos;t have an account?
            </Typography>
            <LinkButton
              onClick={() => {
                history.push(ROUTES.CREATE_ACCOUNT)
              }}
              type="button"
            >
              Register
            </LinkButton>
          </RegisterWrap>
          }
        </AuthFormWrap>
      </Formik>
    </>
  )
}

export default withTheme(Login)

Login.propTypes = {
  theme: PropTypes.object.isRequired
}
