import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { RemoveRedEye, VisibilityOff } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { useSelector } from 'react-redux'
import { InputsWrap, SettingsWrap, ActionButtonsSection } from './styles'
import Typography from '../../../components/UI/Typography'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextInput from '../../../components/UI/Inputs/TextInput'

import Button from '../../../components/UI/Buttons/Button'
import { settingsFormAdapter } from '../../../utils/adapters'
import { selectUserId } from '../../../app/selectors'
import { useShopSettingsMutation } from '../../../api/api.generated'

const Settings = ({ theme }) => {
  const loginEmail = useSelector((state) => state.auth.email)
  const userId = useSelector(selectUserId)
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [updateSettings, { isLoading }] = useShopSettingsMutation()

  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text')
      return
    }
    setPasswordInputType('password')
  }

  const handleSubmitForm = (values, formik) => {
    if (values.email !== loginEmail || values.newPassword.length > 0) {
      updateSettings({ userId, data: settingsFormAdapter(values, loginEmail) })
        ?.unwrap()
        .then()
        .catch((err) => formik.setErrors(err))
    }
  }

  return (
    <SettingsWrap>
      <Formik
        initialValues={{
          email: loginEmail || '',
          currentPassword: '',
          newPassword: '',
          repeatNewPassword: ''
        }}
        validationSchema={Yup.object().shape(
          {
            email: Yup.string()
              .email('Invalid email address')
              .required('Email is required'),
            currentPassword: Yup.string().when('newPassword', {
              is: (newPassword) => newPassword && newPassword.length > 0,
              then: Yup.string().required('Old password is required')
            }),
            newPassword: Yup.string()
              .min(6, 'Password must contain at least 6 characters')
              .when('currentPassword', {
                is: (currentPassword) =>
                  currentPassword && currentPassword.length > 0,
                then: Yup.string().notOneOf(
                  [Yup.ref('currentPassword')],
                  'New password should be different from old one'
                )
              }),
            repeatNewPassword: Yup.string().when('newPassword', {
              is: (newPassword) => newPassword && newPassword.length > 0,
              then: Yup.string()
                .oneOf([Yup.ref('newPassword')], "Password doesn't match")
                .required('Please confirm your password')
            })
          },
          ['currentPassword', 'newPassword', 'repeatNewPassword']
        )}
        onSubmit={handleSubmitForm}
      >
        <Form>
          <Typography variant="headingM" color={theme.colors.skyNeutral_2} bold>
            Store profile Settings
          </Typography>
          <Typography variant="textXS" color={theme.colors.skyNeutral2}>
            Here you can change login email and password
          </Typography>
          <InputsWrap>
            <TextInput
              disabled={isLoading}
              type="email"
              placeholder="Enter your Email"
              size={INPUT_SIZES.SMALL}
              inputName="email"
              label="Email"
            />
            <TextInput
              disabled={isLoading}
              type={passwordInputType}
              placeholder="Enter your current password"
              rightIcon={
                passwordInputType === 'password' ? (
                  <RemoveRedEye />
                ) : (
                  <VisibilityOff />
                )
              }
              rightIconClick={handleShowPassword}
              size={INPUT_SIZES.SMALL}
              inputName="currentPassword"
              label="Current Password"
            />
            <TextInput
              disabled={isLoading}
              type="password"
              placeholder="Enter your new password"
              rightIconClick={handleShowPassword}
              size={INPUT_SIZES.SMALL}
              inputName="newPassword"
              label="New Password"
            />
            <TextInput
              disabled={isLoading}
              type="password"
              placeholder="Repeat your new password"
              rightIconClick={handleShowPassword}
              size={INPUT_SIZES.SMALL}
              inputName="repeatNewPassword"
              label="Repeat new password"
            />
            <ActionButtonsSection>
              <Button
                type="submit"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
                isLoading={isLoading}
              >
                Save
              </Button>
            </ActionButtonsSection>
          </InputsWrap>
        </Form>
      </Formik>
    </SettingsWrap>
  )
}

export default withTheme(Settings)

Settings.propTypes = {
  theme: PropTypes.object.isRequired
}
