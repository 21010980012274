import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { Add, QuestionAnswer } from '@styled-icons/material'
import {
  useLazyGetShopQuestionsQuery,
  useDeleteShopQuestionMutation
} from '../../../api/shopQuestionsApi'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import Paginator from '../../../components/Paginator'
import ShopQuestionItem from '../../../components/ShopQuestionItem'
import ShopQuestionModal from '../ShopQuestionModal'
import { Footer, ErrorMessageTopWrap } from '../../../global/styles'
import {
  AddShopQuestions,
  LabelWrap,
  IconWrap,
  QuestionList,
} from './styles'

const ITEMS_PER_PAGE = 30

const ShopQuestions = ({ theme, shopId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [error, setError] = useState('')
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editQuestionId, setEditQuestionId] = useState(null)
  const [getQuestions, { data: shopQuestions, isLoading, error: loadError }] = useLazyGetShopQuestionsQuery()
  const [deleteShopQuestion] = useDeleteShopQuestionMutation()

  const handleOpenAddQuestion = () => {
    setError("")
    setAddNewModalIsOpen(true)
  }

  const handleCloseAddQuestion = () => {
    setAddNewModalIsOpen(false)
    setEditQuestionId(null)
  }
  
  const handleEditQuestion = (questionId) => {
    setError("")
    setEditModalIsOpen(true)
    setEditQuestionId(questionId)
  }

  const handleCloseEdit = () => {
    setEditModalIsOpen(false)
    setEditQuestionId(null)
  }

  const handleDeleteQuestion = (questionId) => {
    setError("")
    deleteShopQuestion(questionId)
      ?.unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }

  const loadQuestions = () => {
    setError('')
    getQuestions({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      ?.unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadQuestions()
  }, [])

  useEffect(() => {
    loadQuestions()
  }, [currentPage])

  useEffect(() => {
    if (shopQuestions) {
      setTotalPages(shopQuestions.pagination.pages)
      setTotalRows(shopQuestions.pagination.total)
      if (currentPage >= shopQuestions.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopQuestions])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {error &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageTopWrap>
      }       
      {isNil(shopQuestions) && !isLoading && !loadError && <p>No questions added yet</p>}
      {addNewModalIsOpen &&
        <ShopQuestionModal
          isOpen={addNewModalIsOpen}
          onClose={handleCloseAddQuestion}
          shopId={shopId}
        />
      }
      {editModalIsOpen &&
        <ShopQuestionModal
          isOpen={editModalIsOpen}
          onClose={handleCloseEdit}
          editQuestionId={editQuestionId}
        />
      }      
      {shopQuestions && (
        <>
          <AddShopQuestions>
            <LabelWrap>
              <IconWrap>
                <QuestionAnswer size={19} color={theme.colors.greenBase} />
              </IconWrap>
              <Typography variant="textS" color={theme.colors.greenCreator(7, 46)}>
                Add your question here
              </Typography>
            </LabelWrap>
            <div>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                fillColor={theme.colors.green_1}
                type="button"
                onClick={handleOpenAddQuestion}
              >
                <Add size={18} color={theme.colors.white} />
                Add Question
              </Button>
            </div>
          </AddShopQuestions>
          <QuestionList>
            {shopQuestions.questions.map((item) => (
            <ShopQuestionItem
              key={item.id}
              id={item.id}
              question={item.question}
              answer={item.answer}
              canEdit={!item.isOrganizationQuestion}
              handleEditQuestion={handleEditQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
            />
          ))}
        </QuestionList>
        </>
      )}
      {shopQuestions && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopQuestions.questions.length} from ${totalRows} questions`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
    </>
  )
}

export default withTheme(ShopQuestions)

ShopQuestions.propTypes = { 
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
