import React from 'react'
import { LocalSee } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { ImageContainer } from './styles'

const UploadImagePreview = ({ initialImage, uploadedImage, height }) => (
  <ImageContainer bgImage={initialImage || uploadedImage} {...{ height }}>
    {!initialImage && !uploadedImage && <LocalSee size={32} color="#737E92" />}
  </ImageContainer>
)

export default UploadImagePreview
UploadImagePreview.defaultProps = {
  initialImage: null,
  uploadedImage: null,
  height: 176
}
UploadImagePreview.propTypes = {
  height: PropTypes.number,
  initialImage: PropTypes.string,
  uploadedImage: PropTypes.string
}
