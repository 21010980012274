import styled from 'styled-components'
import { isNull } from 'lodash/lang'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'
import Typography from '../Typography'

const { SMALL } = BADGE_SIZES

export const BadgeBody = styled.div`
  display: inline-flex;
  min-height: ${({ size }) => size}px;
  flex-direction: row;
  border-radius: 8px;
  cursor: ${({ onClick }) => (isNull(onClick) ? 'default' : 'pointer')};
  padding: ${({ size }) => (size === SMALL ? '0px 6px' : '0px 10px')};
  align-items: center;
  color: ${({ theme, variant }) =>
    variant === BADGE_VARIANTS.DARK
      ? theme.colors.white
      : variant === BADGE_VARIANTS.GREEN
      ? theme.colors.greenCreator(37, 47)
      : variant === BADGE_VARIANTS.GOLD
      ? 'hsla(47, 58%, 33%, 1)'
      : variant === BADGE_VARIANTS.PINK
      ? theme.colors.pinkBase
      : theme.colors.skyNeutral1};

  background-color: ${({ theme, variant }) =>
    variant === BADGE_VARIANTS.DARK
      ? theme.colors.white
      : variant === BADGE_VARIANTS.GREEN
      ? theme.colors.greenCreator(50, 97)
      : variant === BADGE_VARIANTS.GOLD
      ? theme.colors.yellowCreator(86, 97)
      : variant === BADGE_VARIANTS.PINK
      ? theme.colors.pinkBase4
      : theme.colors.skyCreator(14, 96)};
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.yellowBase : 'transparent'};
`

export const BadgeIcon = styled.div`
  width: ${({ size }) => (size === SMALL ? 18 : 20)}px;
  height: ${({ size }) => (size === SMALL ? 18 : 20)}px;
  display: flex;

  margin-right: 4px;
  justify-content: center;
  align-items: center;
`

export const BadgeLabel = styled(Typography)`
  font-size: 12px;
  color: inherit;
`
