import React from 'react'
import { TableDarkText } from '../../../global/styles'

const EmailTemplatesTableRow = (emailTemplate) => ({
  templateId: emailTemplate.id,
  emailName: <TableDarkText>{emailTemplate?.event}</TableDarkText>,
  subject: <TableDarkText>{emailTemplate?.subject}</TableDarkText>,
  intended: <TableDarkText>{emailTemplate?.intended}</TableDarkText>
})

export default EmailTemplatesTableRow

EmailTemplatesTableRow.propTypes = {}
