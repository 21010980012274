import styled from 'styled-components'
import { HStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const FooterWrap = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 0;
`

export const ItemsCounter = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral};
`
