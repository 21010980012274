import { some } from 'lodash/collection'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
import { USER_ROLE_STORE, WEEK_DAYS } from '../const/app'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

export const createAccountFormAdapter = (formData) => {
  const timezone = dayjs.tz.guess()
  const device = window.innerWidth > 1000 ? 'desktop' : 'mobile'
  const { storeName, email, password } = formData
  return {
    email,
    password,
    timezone,
    storeName,
    role: USER_ROLE_STORE,
    device_name: device,
    phone: null
  }
}

export const loginFormAdapter = (formData) => {
  const { email, password } = formData
  const timezone = dayjs.tz.guess()
  const device = window.innerWidth > 1000 ? 'desktop' : 'mobile'

  return {
    email,
    password,
    timezone,
    device_name: device,
    role: USER_ROLE_STORE
  }
}
export const shopDetailsFormAdapter = (formData, shopLogotype) => {
  const {
    shopName,
    description,
    address,
    addressOverride,
    email,
    categories,
    website,
    phone,
    facebook,
    instagram,
    twitter,
    geoData,
    chatMessage,
    volunteerTitle,
    volunteerMessage,
    onlineShopUrl
  } = formData
  return {
    shopName,
    description,
    address,
    addressOverride,
    email,
    categories,
    website,
    phone,
    facebook,
    instagram,
    twitter,
    geoData,
    chatMessage,
    volunteerTitle,
    volunteerMessage,
    onlineShopUrl,
    isStoreEnabled: formData.isStoreEnabled?.[0] === 'isStoreEnabled',
    showProducts: formData.showProducts?.[0] === 'showProducts',
    isChatEnabled: formData.isChatEnabled?.[0] === 'isChatEnabled',
    showChatMessage: formData.showChatMessage?.[0] === 'showChatMessage',
    logotype: shopLogotype,
    schedule: WEEK_DAYS.reduce(
      (result, DAY) => ({
        ...result,
        [DAY]: {
          isOpen: some(formData.openDays, (openDay) => openDay === DAY),
          open: formData[`${DAY}Open`],
          close: formData[`${DAY}Close`]
        }
      }),
      {}
    )
  }
}

export const settingsFormAdapter = (submittedFormData, initialEmail) => ({
  email:
    submittedFormData.email === initialEmail 
      ? null 
      : submittedFormData.email,
  password: submittedFormData.newPassword || null
})
