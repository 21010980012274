import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const ShopVideosWrap = styled(HStack)`
  flex-wrap: wrap;
  margin: 16px 0;
`

export const ShopVideo = styled(VStack)`
  margin-right: 2px;
  margin-bottom: 2px;
`

export const TimeStampAndMenuWrap = styled(HStack)`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: rgb(41, 41, 41);
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 0.304359243697479) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

export const AddShopVideos = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  padding: 8px;
  border-radius: 4px;
`
export const LabelWrap = styled(HStack)`
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  max-width: 190px;
`
export const IconWrap = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.green4};
  width: 54px;
  min-width: 54px;
  border-radius: 4px;
  height: 53px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

`
