import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  usePostDeleteProductsMutation,
  useGetShopProductQuery
} from '../../../api/shopProductsApi'
import { getErrorMessage } from '../../../utils/helpers'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import Button from '../../../components/UI/Buttons/Button/Button'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableImageLabelCell from '../../../components/TableImageLabelCell'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import EntityPicture from '../../../components/EntityPicture'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock,
  DetailsPanelTopSection
} from '../../../global/styles'
import { ButtonWrap, ProductDetailsWrap, TagsWrap } from './styles'

const ProductDetails = ({ activeProductId, closePanel }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [error, setError] = useState('')
  const { data: productData, isLoading } = useGetShopProductQuery(activeProductId)
  const [deleteProduct, { isLoading: deletingShop }] = usePostDeleteProductsMutation()

  const handleDeleteProduct = () => {
    setError("");
    deleteProduct({ body: { productsIdsToDelete: [activeProductId] } })
      ?.unwrap()
      .then(() => {
        closePanel()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })    
  }
  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {productData && (
        <>
          <DetailsPanelTopSection>
            <EntityPicture
              size={USER_AVATAR_SIZES.LARGE}
              image={productData.productImage}
              isRounded={false}
            />
            <AsideDetailsTitle align="center">
              {productData.productName}
            </AsideDetailsTitle>
          </DetailsPanelTopSection>
          <ProductDetailsWrap>
            <AsideDetailsTitle>Product Details</AsideDetailsTitle>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Store</AsideDetailsLabel>
              <TableImageLabelCell
                image={productData.shopLogo}
                label={productData.shopName}
                isRounded={false}
              />
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Location</AsideDetailsLabel>
              <AsideDetailsDescription>
                {productData.shopLocation}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Price
              </AsideDetailsLabel>
              <AsideDetailsDescription>
              ${productData.productPrice}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Shipping Cost
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                ${productData.shippingCost}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Shipping
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                  key="isShippingEnabled"
                  variant={productData.isShippingEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
                  size={BADGE_SIZES.SMALL}
                  label={productData.isShippingEnabled ? "Yes" : "No"}
                />
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Click & Collect
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                    key="isCollectEnabled"
                    variant={productData.isCollectEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
                    size={BADGE_SIZES.SMALL}
                    label={productData.isCollectEnabled ? "Yes" : "No"}
                  />
              </AsideDetailsDescription>
            </DetailsInfoBlock>            
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Quantity
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {productData.quantity}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Product Categories</AsideDetailsLabel>
              <TagsWrap>
                {productData.tags.map((tag) => (
                  <Badge
                    key={tag}
                    variant={BADGE_VARIANTS.LIGHT}
                    sie={BADGE_SIZES.SMALL}
                    label={tag}
                  />
                ))}
              </TagsWrap>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Organisation Product
              </AsideDetailsLabel>
              <AsideDetailsDescription>
              <Badge 
                label={productData.isOrganizationProduct ? 'Yes' : 'No'} 
                variant={productData.isOrganizationProduct ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
              />
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Status
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                  label={productData.isActive ? 'Active' : 'Inactive'}
                  variant={productData.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
                />
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={deletingShop}
                isFullWidth
              >
                Delete
              </Button>
            </ButtonWrap>
          </ProductDetailsWrap>
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={productData.productName}
            entityType="Product"
            handleDelete={handleDeleteProduct}
            error={error}
          />
        </>
      )}
    </>
  )
}

export default ProductDetails
ProductDetails.defaultProps = {
  activeProductId: null
}
ProductDetails.propTypes = {
  activeProductId: PropTypes.string,
  closePanel: PropTypes.func.isRequired
}
