import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const DashboardRecentItemWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
  border-top: 1px solid #ece9ef;
  &:first-child {
    border-top: none;
  }
`
export const InfoWrap = styled(VStack)`
  width: 100%;
  margin-left: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0;
  margin-right: auto;
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
