import styled from 'styled-components'
import { Card, HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ShopAsideReviewWrapper = styled(Card)`
  padding: 24px 20px;
  flex-grow: 0;
  height: 100%;
  margin-left: auto;
  overflow: scroll;
  align-self: flex-start;
  display: flex;
  width: 336px;
  flex-direction: column;
  min-width: 336px;
`
export const CustomerReviewWrap = styled(HStack)`
  padding: 10px 8px 10px 4px;

  width: 100%;
`
export const ReviewDetails = styled(VStack)`
  width: 100%;
  margin-left: 15px;
`
export const CustomerName = styled(Typography)``

export const TimeStamp = styled(Typography)``
export const ReviewWrap = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  height: 16px;
`
export const NoReviewsWrap = styled(VStack)`
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  align-items: center;
  min-height: 180px;
`
export const BadgeTabsWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin: 14px 0;
`
