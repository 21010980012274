import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter
} from 'react-table'
import PropTypes from 'prop-types'
import ROUTES from '../../../const/routes'
import { getErrorMessage } from '../../../utils/helpers'
import { getMetrics, tableColumns } from '../helpers/shopsHelpers'
import { useLazyGetTransferOrdersQuery } from '../../../api/api.generated'
import Typography from '../../../components/UI/Typography'
import MetricItem from '../../../components/MetricItem'
import LoadingSpinner from '../../../components/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import TableButtonCell from '../../../components/TableButtonCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import OrderTableRow from '../OrderTableRow'
import OrderDetails from '../../orders/OrderDetails'
import {
  Toolbar
} from '../../../global/styles'
import { MetricsItem, ShopsDashboardWrap, ShopsWrap } from './styles'

const ManageTransferOrders = ( {showStore} ) => {
  const { transferId } = useParams()
  const history = useHistory()
  const [getOrders, { data: ordersTableData, isLoading }] = useLazyGetTransferOrdersQuery()
  const [metrics, setMetrics] = useState([])
  const [error, setError] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [showDetails, setShowDetails] = useState(false)
  const [activeId, setActiveId] = useState(null)

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      ordersTableData
        ? ordersTableData?.orders?.map((orderItem) => OrderTableRow(orderItem))
        : [],
    [ordersTableData]
  )

  const handleShowOrder = (orderId) => {
    history.push(generatePath(ROUTES.STORE_ORDER_PRODUCTS, { orderId }))
  }

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
        },
        ...columnsList,
        {
          id: 'buttons',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleShowDetails={handleShowOrder}
              canEdit={false}
              showView
              showDelete={false}
            />
          )
        }
      ])
    }
  )

  const loadOrders = () => {
    setError('')
    getOrders({ page: pageIndex, limit: pageSize, transferId })
      ?.unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleShowDetails = (id) => {
    if (id !== activeId) {
      setShowDetails(true)
      setActiveId(id)
    } else {
      setShowDetails(false)
      setActiveId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
  }

  useEffect(() => {
    loadOrders()
  }, [])

  useEffect(() => {
    if (ordersTableData) {
      setTotalPages(ordersTableData.pagination.pages)
      setTotalRows(ordersTableData.pagination.total)
      if (pageIndex >= ordersTableData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [ordersTableData])

  useEffect(() => {
    if (ordersTableData) {
      loadOrders()
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (ordersTableData) {
      setMetrics(getMetrics(ordersTableData, showStore))
    }
  }, [ordersTableData])

  return (
    <ShopsDashboardWrap>
      <ShopsWrap>
        <DashboardHeader
          headline="Transfer Orders"
          disableDelete
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }
        {ordersTableData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowDetails}
              activeItemId={activeId}             
            />
          </>
        )}
      </ShopsWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <OrderDetails
          activeId={activeId}
          showStore={showStore}
        />
      </DetailsAsidePanel>
      }      
    </ShopsDashboardWrap>
  )
}

export default ManageTransferOrders

ManageTransferOrders.propTypes = {
  showStore: PropTypes.bool.isRequired
}
