import React from 'react'
import PropTypes from 'prop-types'
import { ShopNameWrap } from './styles'
import EntityPicture from '../EntityPicture'
import { USER_AVATAR_SIZES } from '../../const/UIvariants'
import { TableText } from '../../global/styles'

const TableImageLabelCell = ({ image, label, isRounded, bgColor }) => (
  <ShopNameWrap>
    <EntityPicture
      entityName={image ? '' : label}
      size={USER_AVATAR_SIZES.MED}
      {...{ isRounded, bgColor, image }}
    />
    <TableText>{label}</TableText>
  </ShopNameWrap>
)

export default TableImageLabelCell

TableImageLabelCell.defaultProps = {
  image: null,
  bgColor: undefined
}
TableImageLabelCell.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  isRounded: PropTypes.bool.isRequired,
  bgColor: PropTypes.string
}
