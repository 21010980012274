/*eslint-disable*/
import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableDarkText, TableText } from '../../../global/styles'
import { BADGE_SIZES } from '../../../const/UIvariants'

const OrderTableRow = (orderItem) => ({
  id: orderItem.id,
  code: <TableDarkText>{orderItem.code}</TableDarkText>,
  shopName: <TableDarkText>{orderItem.shopName}</TableDarkText>,
  date: <TableDarkText>{orderItem.createdAt}</TableDarkText>,
  firstName: <TableText>{orderItem.firstName}</TableText>,
  lastName: <TableText>{orderItem.lastName}</TableText>,
  phone: <TableText>{orderItem.phone === null ? '' : '+' }{orderItem.phone}</TableText>,
  address: <TableText>{orderItem.address}</TableText>,
  total: <TableText>${orderItem.total}</TableText>,
  fee: <TableText>${orderItem.fee}</TableText>,
  isTransfer: (
    <Badge
      size={BADGE_SIZES.LARGE}
      label={orderItem.transfer ? 'Yes' : 'No'}
      variant={orderItem.transfer ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.PINK}
    />
  ),
  isDelivery: (
    <Badge
      size={BADGE_SIZES.LARGE}
      label={orderItem.isDelivery ? 'Delivery' : 'Click and Collect'}
      variant={orderItem.isDelivery ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.PINK}
    />
  ),
  status: (
    <Badge
      size={BADGE_SIZES.LARGE}
      label={orderItem.statusId === 1 ? 'Not Fullfiled' : 'Fullfiled'}
      variant={orderItem.statusId === 1 ? BADGE_VARIANTS.PINK : BADGE_VARIANTS.GREEN}
    />
  )  
})

export default OrderTableRow

OrderTableRow.propTypes = {}
