import React from 'react'
import { useParams } from 'react-router-dom'
import ShopHolidays from '../../shop/ShopHolidays'

const ManageShopHolidays = () => {
  const { shopId } = useParams()

  return (
    <ShopHolidays shopId={shopId}/>
  )
}

export default ManageShopHolidays

ManageShopHolidays.propTypes = {}
