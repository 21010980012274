import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const ChatWrapper = styled(VStack)`
  /*
  margin-left: 32px;
  */
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
`
export const ChatHeadlineWrap = styled(HStack)`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
  padding: 20px 27px;
`
export const Headline = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
export const SelectedChatUserDetailsWrap = styled(VStack)`
  margin-left: 15px;
`
export const HeaderButtonWrap = styled(HStack)`
  justify-content: space-between;
  button {
    margin-left: 10px;
  }
`
export const ChatMessagesWrap = styled(VStack)`
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 24px;
`
export const ShowMoreWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
export const ReceiverName = styled(Typography).attrs({
  variant: 'textM',
  bold: true
})`
  color: ${({ theme }) => theme.colors.skyNeutral_2};
`
export const ContactNameWrap = styled(HStack)`
`
export const Pronouns = styled(Typography).attrs({
  variant: 'textXS',
  bold: true
})`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
