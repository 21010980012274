import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Close, Search } from '@styled-icons/material'
import { filter } from 'lodash/collection'
import { get } from 'lodash/object'
import { findIndex } from 'lodash/array'
import { RoomItemsListWrap, RoomSearchWrap, RoomsWrapper } from './styles'
import Typography from '../../../components/UI/Typography'
import ChatPreviewItem from '../../../components/RoomPreviewItem'
import { INPUT_SIZES } from '../../../const/UIvariants'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import { useGetRoomsQuery } from '../../../api/messages'
import LoadingSpinner from '../../../components/LoadingSpinner'

const RoomsList = ({
  selectedRoomId,
  setSelectedRoomId,
  setReceiverName,
  setReceiverAvatar,
  setPreferredPronouns
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredRooms, setFilteredRooms] = useState([])
  const [newMessagesCount, setNewMessagesCount] = useState(0)
  const { data: roomsList, isLoading } = useGetRoomsQuery(undefined, {
    pollingInterval: 25000
  })

  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }
  const handleSelectRoom = (roomItem) => {
    const receiverName = get(roomItem, 'userName')
    const receiverAvatar = get(roomItem, 'userAvatar')
    const preferredPronouns = get(roomItem, 'preferredPronouns')

    setSelectedRoomId((currentRoomId) =>
      currentRoomId === roomItem.id ? null : roomItem.id
    )
    setReceiverName((current) =>
      current === receiverName ? null : receiverName
    )
    setReceiverAvatar((current) =>
      current === receiverAvatar ? null : receiverAvatar
    )
    setPreferredPronouns((current) =>
      current === preferredPronouns ? "" : preferredPronouns
    )
    
    setFilteredRooms((current) => {
      const indexToReplace = findIndex(
        current,
        (room) => room.id === roomItem.id
      )
      const statusUpdate = current

      statusUpdate.splice(indexToReplace, 1, {
        ...current[indexToReplace],
        hasNewMessages: false
      })
      return statusUpdate
    })

    setNewMessagesCount((prev) => {
      if (roomItem.hasNewMessages) {
        return prev - 1
      }
      return prev
    })
  }

  const truncateMessage = (message, limit = 90) => {
    if (message && typeof message === 'string') {
      return `${message?.substring(0, limit).trim()}${
        message?.length > 90 ? '...' : ''
      }`
    }
    return ''
  }
  const getLastMessagePreview = (lastMessage, image) => {
    if (image) {
      return '🏙 image'
    }
    if (lastMessage) {
      return truncateMessage(lastMessage)
    }
    return 'No messages found'
  }

  useEffect(() => {
    if (roomsList) {
      setFilteredRooms(
        filter(roomsList, (room) =>
          room.userName.toLowerCase().includes(searchQuery.trim())
        )
      )
    }
  }, [roomsList, searchQuery])

  useEffect(() => {
    setNewMessagesCount(filter(roomsList, (room) => room.hasNewMessages).length)
  }, [roomsList])

  return (
    <RoomsWrapper>
      {isLoading && <LoadingSpinner />}
      {roomsList && (
        <>
          <RoomSearchWrap>
            <Typography variant="textS">
              {`You have ${newMessagesCount} New Messages `}
            </Typography>
            <CustomTextInput
              value={searchQuery}
              size={INPUT_SIZES.SMALL}
              handleChange={handleSearch}
              leftIcon={<Search />}
              rightIcon={searchQuery ? <Close /> : undefined}
              rightIconClick={() => {
                setSearchQuery('')
              }}
              inputName="search"
              placeholder="Search customers"
            />
          </RoomSearchWrap>
          <RoomItemsListWrap>
            {filteredRooms.map((chatItem) => (
              <ChatPreviewItem
                key={chatItem.id}
                handleClick={() => {
                  handleSelectRoom(chatItem)
                }}
                isSelected={selectedRoomId === chatItem.id}
                userName={chatItem.userName}
                preferredPronouns={chatItem.preferredPronouns}
                userPic={chatItem.userAvatar}
                timeStamp={chatItem.lastMessageTimeStamp}
                message={getLastMessagePreview(
                  chatItem.lastMessage,
                  chatItem.image
                )}
                isOnline={chatItem.isOnline ?? false}
                hasNewMessage={chatItem.hasNewMessages}
                shopName={chatItem.shopName}
              />
            ))}
          </RoomItemsListWrap>
        </>
      )}
    </RoomsWrapper>
  )
}

export default RoomsList
RoomsList.defaultProps = {
  selectedRoomId: null
}

RoomsList.propTypes = {
  selectedRoomId: PropTypes.string,
  setSelectedRoomId: PropTypes.func.isRequired,
  setReceiverName: PropTypes.func.isRequired,
  setReceiverAvatar: PropTypes.func.isRequired,
  setPreferredPronouns: PropTypes.func.isRequired
}
