import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableDarkText, TableText } from '../../../global/styles'

const ShopTableRow = (user) => ({
  id: user.id,
  name: <TableText>{user.fullName}</TableText>,
  phoneNumber: <TableText>{user.phoneNumber === null ? '' : '+' }{user.phoneNumber}</TableText>,
  email: <TableDarkText>{user.email}</TableDarkText>,
  shopName: <TableText>{user.shopName}</TableText>,
  organizationName: <TableText>{user.organizationName}</TableText>,
  roleName: <TableText>{user.roleName}</TableText>,
  forSearch: `${user.fullName} ${user.email} ${user.phoneNumber} ${user.shopName} ${user.organizationName}`,
  status: (
    <Badge
      label={user.isActive ? 'Active' : 'Inactive'}
      variant={user.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
    />
  )
})

export default ShopTableRow

ShopTableRow.propTypes = {}
