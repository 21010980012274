import React from 'react'
import { useField } from 'formik'
import { CalendarToday } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { values } from 'lodash/object'
import dayjs from 'dayjs'
import {
  InputBodyWrapper,
  InputError,
  InputWrapper,
  Placeholder,
  RightSideIcon,
  StyledInput
} from './styles'
import { INPUT_SIZES } from '../../../../const/UIvariants'
import { globalTheme } from '../../../../global/theme'
import { InputOuterLabel } from '../TextInput/styles'

const DateInput = ({
  inputName,
  size,
  disabled,
  placeholder,
  minValue,
  label
}) => {
  const [field, meta, helpers] = useField({ name: inputName })
  const { name, onBlur, value, multiple } = field
  const handleChange = (event) => {
    helpers.setValue(dayjs(event.target.value).format())
  }
  return (
    <InputWrapper {...{ size }} error={meta.touched && meta.error}>
      <InputOuterLabel
        {...{ disabled }}
        htmlFor={name}
        variant="textS"
        component="label"
      >
        {label}
      </InputOuterLabel>
      <InputBodyWrapper>
        {!meta.value && placeholder && (
          <Placeholder component="span" variant="textS">
            {placeholder}
          </Placeholder>
        )}
        <StyledInput
          {...{
            name,
            onBlur,
            multiple,
            disabled,
            placeholder,
            size
          }}
          value={value && dayjs(value).format('YYYY-MM-DD')}
          onChange={handleChange}
          type="date"
          min={minValue && dayjs(minValue).format('YYYY-MM-DD')}
          error={meta.touched && meta.error}
        />
        <RightSideIcon>
          <CalendarToday size={22} color={globalTheme.colors.skyNeutral} />
        </RightSideIcon>
      </InputBodyWrapper>
      <InputError>{meta.touched && meta.error ? meta.error : null}</InputError>
    </InputWrapper>
  )
}

export default DateInput

DateInput.defaultProps = {
  disabled: false,
  placeholder: 'mm/dd/yyyy',
  size: INPUT_SIZES.SMALL,
  minValue: null,
  label: null
}
DateInput.propTypes = {
  placeholder: PropTypes.string,
  minValue: PropTypes.string,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(values(INPUT_SIZES)),
  disabled: PropTypes.bool
}
