import styled from 'styled-components'
// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-wrap: nowrap;
  overflow: scroll;
`

export const TrailingLine = styled.li`
  width: 100%;
  opacity: 0.5;
  border-bottom: 2px solid ${({ theme }) => theme.colors.skyNeutral3};
`
export const TabWrap = styled.li`
  display: block;
`
