import { findIndex } from 'lodash/array'
import { api as apiSlice} from './api.generated'

const shopVolunteersApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShopVolunteers: build.query({
      query: ({ shopId, page, limit }) => ({
        url: `/shop/${shopId}/volunteers?page=${page}&limit=${limit}`,
        method: 'get'
      }),
      providesTags: ['ShopVolunteers']
    }),
    getShopVolunteer: build.query({
      query: (volunteerId) => ({ url: `/shop/volunteers/${volunteerId}` }),
      providesTags: ['ShopVolunteer']
    }),    
    postShopVolunteer: build.mutation({
      query: (data) => ({
        url: `/shop/volunteers`,
        method: 'post',
        data
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopVolunteers', undefined, (draft) => {
            draft.unshift(data)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['ShopVolunteers']
    }),
    putShopVolunteer: build.mutation({
      query: ({ volunteerId, data }) => ({
        url: `/shop/volunteers/${volunteerId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopVolunteers', 'ShopVolunteer'],
      async onQueryStarted({ volunteerId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopVolunteers', undefined, (draft) => {
            const index = findIndex(draft, (volunteer) => volunteer.id === volunteerId)
            draft.splice(index, 1, data)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteShopVolunteer: build.mutation({
      query: (volunteerId) => ({
        url: `/shop/volunteers/${volunteerId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopVolunteers'],
      async onQueryStarted(volunteerId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopVolunteers', undefined, (draft) => {
            const index = findIndex(draft, (volunteer) => volunteer.id === volunteerId)
            draft.splice(index, 1)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  }),

  overrideExisting: true
})

export const {
  useGetShopVolunteersQuery,
  useLazyGetShopVolunteersQuery,
  useGetShopVolunteerQuery,
  useLazyGetShopVolunteerQuery,
  usePostShopVolunteerMutation,
  usePutShopVolunteerMutation,
  useDeleteShopVolunteerMutation
} = shopVolunteersApi
