import styled from 'styled-components/macro'
import Typography from '../../Typography'
import { INPUT_SIZES } from '../../../../const/UIvariants'

export const StyledInput = styled.textarea`
  width: 100%;
  resize: none;
  min-height: 56px;
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  padding-top: 16px;
  outline: none;
  border: none;
  display: flex;
  border-radius: 8px;
  transition: padding-top 0.2s ease-in-out;

  padding-left: ${({ leftPadding, size }) =>
    leftPadding ? 52 : size === INPUT_SIZES.LARGE ? 12 : 16}px;
  font-size: ${({ size }) => (size === INPUT_SIZES.LARGE ? 17 : 15)}px;
  line-height: ${({ size }) => (size === INPUT_SIZES.LARGE ? 20 : 17)}px;
`
export const InputWrapper = styled.div`
  margin-bottom: 8px;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;

  & :focus-within {
    input {
      box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
    }
  }
`
export const InputError = styled.p`
  font-size: 13px;
  margin-top: 2px;
  height: 16px;
  color: ${({ theme }) => theme.colors.greenCreator(7, 46)};
`
export const InputOuterLabel = styled(Typography)`
  margin-bottom: ${({ labelMarginBottom }) => labelMarginBottom}px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
