import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { BASE_URL, LOCALHOST } from '../const/api'

export const apiV1 = axios.create({
  baseURL: BASE_URL,
  timeout: 10000
})

const axiosBaseQuery =
  () =>
  async ({ url, method, body, data }) => {
    try {
      const result = await apiV1({ url, method: method ?? 'get', data: body ?? data})
      return { data: result?.data?.data }
    } catch (axiosError) {
      const err = axiosError
      return {
        error: err?.response?.data?.message ?? err
      }
    }
  }

apiV1.interceptors.request.use(
  (response) => response,
  (e) => {
    if (e.toJSON().message === 'Network Error') {
      // eslint-disable-next-line no-console
      console.log('No internet connection')
    }
    return Promise.reject(e)
  }
)

export default axiosBaseQuery
