import React from 'react'
import { useParams } from 'react-router-dom'
import ShopVolunteers from '../../shop/ShopVolunteers'

const ManageShopVolunteers = () => {
  const { shopId } = useParams()

  return (
    <ShopVolunteers shopId={shopId}/>
  )
}

export default ManageShopVolunteers

ManageShopVolunteers.propTypes = {}
