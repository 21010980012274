import styled from 'styled-components/macro'
import { VStack } from '../../../global/styles'

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.white};
`
export const AuthPictureStub = styled.div`
  background-color: ${({ theme }) => theme.colors.skyBase};
  height: 100%;
  width: 100%;
  flex-grow: 1;
`
export const AuthFormContentWrap = styled(VStack)`
  flex-grow: 1;
  padding: 0 0 0 107px;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  margin-top: 13vh;
  // height: 100%;
  margin-bottom: auto;
  width: 562px;
  min-width: 562px;
`
