import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopVolunteers from '../ShopVolunteers'

const ShopProfileVolunteers = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopVolunteers shopId={shopId} />
  )
}

export default ShopProfileVolunteers

ShopProfileVolunteers.propTypes = {}
