import styled from 'styled-components'
import { VStack } from '../../../global/styles'

export const RoomsWrapper = styled(VStack)`
  padding: 32px 0 0 0;
  width: 386px;
  height: 100%;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 386px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: inset -1px 0px 0px rgba(95, 110, 130, 0.15);
`
export const RoomSearchWrap = styled(VStack)`
  padding: 0 32px 24px 32px;
  justify-content: space-between;
  & > p {
    margin-bottom: 16px;
  }
`
export const RoomItemsListWrap = styled(VStack)`
  overflow: scroll;
  height: 100%;
  padding: 0 32px;
`
