import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const EditShopDonations = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  padding: 8px;
  border-radius: 4px;
`
export const LabelWrap = styled(HStack)`
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  max-width: 190px;
`
export const IconWrap = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.green4};
  width: 54px;
  min-width: 54px;
  border-radius: 4px;
  height: 53px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`
export const NoDataWrap = styled(VStack)`
  align-items: center;
  justify-content: center;
  padding-top: 32px;
`

export const DonationWrap = styled(HStack)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  padding: 8px;
  min-width: 23.5%;
  width: 23.5%;
  margin-right: 2%;
  position: relative;
  margin-bottom: 12px;
  &:nth-child(4n) {
    margin-right: 0;
  }
`
export const DonationIconWrap = styled(VStack)`
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
export const DonationsWrapper = styled(HStack)`
  margin-top: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`
export const DonationDetailsWrap = styled(VStack)`
  margin-left: 16px;
  min-width: 60%;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`
export const DonationNameWrap = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DonationDataWrap = styled(VStack)`
`
