import styled from 'styled-components/macro'
import { Form } from 'formik'
import { VStack } from '../../../global/styles'

export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ActionButtonsSection = styled.div`
  display: flex;
  padding: 0 32px 32px 32px;
`
export const FormInputsWrap = styled(VStack)`
  padding: 32px 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const StyledForm = styled(Form)`
  width: 462px;
`
