import React, { useMemo, useState, useEffect } from 'react'
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter
} from 'react-table'
import { Search } from '@styled-icons/material'
import {
  useLazyGetContactsQuery
} from '../../../api/api.generated'
import { getErrorMessage } from '../../../utils/helpers'
import { INPUT_SIZES, BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button/Button'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import LoadingSpinner from '../../../components/LoadingSpinner'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import TableButtonCell from '../../../components/TableButtonCell'
import SendMessageModal from '../../../components/SendMessageModal'
import ShopDetails from '../ShopDetails'
import ShopTableRow from '../ShopTableRow'
import {
  Toolbar,
  ButtonWrap
} from '../../../global/styles'
import { 
  ShopsDashboardWrap, 
  ShopsWrap, 
  EntityDashboardHeadline,
  SectionName
} from './styles'

export const tableColumns = [
  {
    Header: 'Name',
    accessor: 'shopName'
  },
  {
    Header: 'Location',
    accessor: 'location'
  },
  {
    Header: 'Email',
    accessor: 'email'
  },
  {
    Header: 'Days Operational',
    accessor: 'daysOperational'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }
]

const ManageContacts = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [sendMessageIsOpen, setSendMessageIsOpen] = useState(false)
  const [activeShopId, setActiveShopId] = useState(null)
  const [error, setError] = useState('')
  const [getContacts, { data: shopsTableData, isLoading }] = useLazyGetContactsQuery()
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch] = useState('')

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      shopsTableData
        ? shopsTableData?.contacts?.map((shopItem) => ShopTableRow(shopItem))
        : [],
    [shopsTableData]
  )

  const handleShowShopDetails = (shopId) => {
    if (shopId !== activeShopId) {
      setShowDetails(true)
      setActiveShopId(shopId)
    } else {
      setShowDetails(false)
      setActiveShopId(null)
    }
  }

  const handleClosePanel = () => {
    setShowDetails(false)
  }
  
  const handleSendMessage = (shopId) => {
    setActiveShopId(shopId)
    setSendMessageIsOpen(true)
  }

  const handleCloseSendMessage = () => {
    setSendMessageIsOpen(false)
  }

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.shopId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages      
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'first'
        },
        ...columnsList,
        {
          id: 'buttons',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              showDelete={false}
              showSendMessage
              handleShowSendMessage={handleSendMessage}
            />
          )
        }        
      ])
    }
  )

  const loadContacts = () => {
    setError('')
    getContacts({ page: pageIndex, limit: pageSize, search })
      ?.unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadContacts()
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    loadContacts();
  }, [])

  useEffect(() => {
    if (shopsTableData) {
      setTotalPages(shopsTableData.pagination.pages)
      setTotalRows(shopsTableData.pagination.total)
      if (pageIndex >= shopsTableData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [shopsTableData])

  useEffect(() => {
    if (shopsTableData) {
      loadContacts()
    }
  }, [pageIndex, pageSize]);

  return (
    <ShopsDashboardWrap>
      <ShopsWrap>
        <EntityDashboardHeadline>
          <SectionName variant="headingM" bold>
            Contacts
          </SectionName>
        </EntityDashboardHeadline>
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }        
        {shopsTableData && (
          <>
            <Toolbar>
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search contact by name or address"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>              
            </Toolbar>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowShopDetails}
              activeItemId={activeShopId}
            />
          </>
        )}
      </ShopsWrap>

      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleClosePanel}>
        <ShopDetails
          activeShopId={activeShopId}
          onSendMessage={handleSendMessage}
        />
      </DetailsAsidePanel>
      }
      { sendMessageIsOpen &&
        <SendMessageModal
          isOpen={sendMessageIsOpen}
          onClose={handleCloseSendMessage}
          shopId={activeShopId}
        />
      }      
    </ShopsDashboardWrap>
  )
}

export default ManageContacts

ManageContacts.propTypes = {}
