import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Favorite } from '@styled-icons/material'
import {
  ActivityDetails,
  ActivityItem,
  DashboardActivitiesWrap,
  IconWrap,
  ShopAddress,
  ShopDetails,
  ShopName,
  ShopRecentActivity
} from './styles'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { useGetShopDetailsQuery } from '../../../api/shopApi'
import ShopLogo from '../../../components/ShopLogo'
import Typography from '../../../components/UI/Typography'
import Badge from '../../../components/UI/Badge'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'
import { isShopOpen } from '../../../utils/helpers'
import { useGetShopDashboardDetailsQuery } from '../../../api/dashboardApi'

const DashboardActivities = ({ theme, shopId }) => {
  const { data: dashboardData = [], isLoading: loadingDashboard } = useGetShopDashboardDetailsQuery({ shopId, timeFrame: 6, from: "", to: "" })
  const { data: shopDetails, isLoading: loadingShopDetails } =
    useGetShopDetailsQuery(shopId)
  return (
    <DashboardActivitiesWrap>
      {(loadingDashboard || loadingShopDetails) && <LoadingSpinner />}
      {dashboardData && shopDetails && (
        <>
          <ShopDetails>
            <ShopLogo size={112} logoUrl={shopDetails?.logotype} />
            <ShopName
              variant="textL"
              color={theme.colors.skyNeutral_2}
              align="center"
            >
              {shopDetails.shopName}
            </ShopName>
            <ShopAddress
              variant="headingS"
              color={theme.colors.skyNeutral2}
              align="center"
            >
              {shopDetails.displayAddress}
            </ShopAddress>
            {isShopOpen(shopDetails.schedule) ? (
              <Badge
                size={BADGE_SIZES.LARGE}
                label="Open now"
                variant={BADGE_VARIANTS.GREEN}
              />
            ) : (
              <Badge
                size={BADGE_SIZES.LARGE}
                label="Closed"
                variant={BADGE_VARIANTS.LIGHT}
              />
            )}
          </ShopDetails>
          { false &&
          <ShopRecentActivity>
            <Typography
              variant="textL"
              color={theme.colors.skyNeutral_2}
              align="left"
            >
              Recent activity
            </Typography>
            <ActivityItem>
              <IconWrap>
                <Favorite color={theme.colors.white} size={19} />
              </IconWrap>
              <ActivityDetails>
                <Typography variant="textS" color={theme.colors.skyNeutral_1}>
                  10 People added your store to Favorites list
                </Typography>
                <Typography variant="textXS" color={theme.colors.skyNeutral2}>
                  5 mins
                </Typography>
              </ActivityDetails>
            </ActivityItem>
          </ShopRecentActivity>
          }
        </>
      )}
    </DashboardActivitiesWrap>
  )
}

export default withTheme(DashboardActivities)

DashboardActivities.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
