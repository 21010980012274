import { api as apiSlice} from './api.generated'

const shopProductsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    /*
    getShopProducts: build.query({
      query: (shopId) => ({
        url: `/shop/${shopId}/products`,
        method: 'get'
      }),
      providesTags: ['ShopProducts']
    }),
    */
    getOrganizationShopProducts: build.query({
      query: ({organizationId, search, page, limit}) => ({ url: `/admin/organizations/${organizationId}/shop-products?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['ShopProducts']
    }),
    getShopProducts: build.query({
      query: ({shopId, page, limit}) => ({
        url: `/shop/${shopId}/products?page=${page}&limit=${limit}`,
        method: 'get'
      }),
      providesTags: ['ShopProducts']
    }),
    getShopProduct: build.query({
      query: (productId) => ({ url: `/shop/products/${productId}` }),
      providesTags: ['ShopProduct']
    }),
    postShopProduct: build.mutation({
      query: (data) => ({
        url: `/shop/products`,
        method: 'post',
        data
      }),
      /*
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getShopProducts',
            undefined,
            (draft) => {
              draft.unshift(data)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      */
      invalidatesTags: ['ShopProducts']
    }),
    putShopProduct: build.mutation({
      query: ({ productId, data }) => ({
        url: `/shop/products/${productId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopProducts', 'ShopProduct'],
      /*
      onQueryStarted: async (
        { productId, data },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getShopProducts',
            undefined,
            (draft) => {
              const index = findIndex(
                draft,
                (product) => product.id === productId
              )
              draft.splice(index, 1, data)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
      */
    }),
    deleteShopProduct: build.mutation({
      query: (productId) => ({
        url: `/shop/products/${productId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopProducts'],
      /*
      onQueryStarted: async (productId, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            'getShopProducts',
            undefined,
            (draft) => {
              const index = findIndex(
                draft,
                (product) => product.id === productId
              )
              draft.splice(index, 1)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
      */
    }),
    postDeleteProducts: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteProducts`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopProducts'],
    }),
  }),
  overrideExisting: true
})

export const {
  useGetOrganizationShopProductsQuery,
  useLazyGetOrganizationShopProductsQuery,
  useGetShopProductsQuery,
  useLazyGetShopProductsQuery,
  useGetShopProductQuery,
  useLazyGetShopProductQuery,
  usePostShopProductMutation,
  usePutShopProductMutation,
  useDeleteShopProductMutation,
  usePostDeleteProductsMutation,
} = shopProductsApi
