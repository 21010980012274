import styled from 'styled-components'

const LinkButton = styled.button`
  margin: 0 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.skyCreator(66, 29)};
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  :hover {
    color: ${({ theme }) => theme.colors.skyBase};
  }
`
export default LinkButton
