import React from 'react'
import { values } from 'lodash/object'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Status, UserAvatarWrap, UserInitials } from './styles'
import { USER_AVATAR_SIZES } from '../../const/UIvariants'
import { globalTheme } from '../../global/theme'
import { getImageFromServer } from '../../utils/helpers'

const EntityPicture = ({
  size,
  entityName,
  isOnline,
  image,
  isRounded,
  bgColor
}) => {
  const getUserInitials = (initUserName) => {
    if (isEmpty(initUserName)) return initUserName
    const nameToArray = initUserName.split(' ')
    const abbreviatedName = nameToArray
      .map((word) => word[0])
      .join('')
      .toUpperCase()
    if (abbreviatedName.length > 2) {
      return abbreviatedName.slice(0, 2)
    }
    return abbreviatedName
  }

  return (
    <UserAvatarWrap
      {...{ size }}
      isRounded={isRounded}
      bgVariant={bgColor}
      bgImage={getImageFromServer(image)}
    >
      {isEmpty(image) && (
        <UserInitials variant="textXS" color={globalTheme.colors.white}>
          {getUserInitials(entityName)}
        </UserInitials>
      )}
      {isOnline && <Status {...{ size }} />}
    </UserAvatarWrap>
  )
}

export default EntityPicture

EntityPicture.defaultProps = {
  size: USER_AVATAR_SIZES.SMALL,
  isOnline: false,
  entityName: '',
  bgColor: 'hsla(173,63%,52%,1)',
  image: null,
  isRounded: true
}

EntityPicture.propTypes = {
  size: PropTypes.oneOf(values(USER_AVATAR_SIZES)),
  entityName: PropTypes.string,
  bgColor: PropTypes.string,
  isOnline: PropTypes.bool,
  isRounded: PropTypes.bool,
  image: PropTypes.string
}
