import React from 'react'
import PropTypes from 'prop-types'
import { round } from 'lodash/math'
import { Dashboard, Grade, ShoppingBag } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import Typography from '../UI/Typography'
import { 
  HStack,
} from '../../global/styles'
import {
  DashboardStatisticsWrap,
  IconWrap,
  MetricItem,
  MetricLabel,
  MetricsWrap
} from './styles'

const DashboardStatistics = ({ theme, dashboardData }) => (
  <DashboardStatisticsWrap>
    <MetricsWrap>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
          Average review score
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {round(dashboardData?.reviews?.average, 2)}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          from {dashboardData?.reviews?.total} reviews
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.greenNeutral_2}>
          Products Listed
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.green3}>
            <ShoppingBag size={24} color={theme.colors.green_1} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.green_2}>
            {dashboardData?.products}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.greenNeutral}>
          Products
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.cyanNeutral_1}>
          Total Posts
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.cyanBase3}>
            <Dashboard size={24} color={theme.colors.cyanBase_1} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.cyanBase_1}>
            {dashboardData?.posts}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.cyanNeutral}>
          Posts by you
        </Typography>
      </MetricItem>
    </MetricsWrap>
    <MetricsWrap>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
          Profile Views
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {dashboardData?.profileViews}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          &nbsp;
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.greenNeutral_2}>
        Total Shares
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.green3}>
            <ShoppingBag size={24} color={theme.colors.green_1} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.green_2}>
            {dashboardData?.totalShares}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.greenNeutral}>
          &nbsp;
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.cyanNeutral_1}>
        Total Go Now click
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.cyanBase3}>
            <Dashboard size={24} color={theme.colors.cyanBase_1} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.cyanBase_1}>
            {dashboardData?.goToClicks}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.cyanNeutral}>
          &nbsp;
        </Typography>
      </MetricItem>
    </MetricsWrap>
    <MetricsWrap>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
          Total Favourite
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {dashboardData?.totalFavourite}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          All Active Favourited: {dashboardData?.totalFavouriteActive}
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.greenNeutral_2}>
          Total Subscribers
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.green3}>
            <ShoppingBag size={24} color={theme.colors.green_1} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.green_2}>
            {dashboardData?.totalSubscribers}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.greenNeutral}>
          Subscribers: {dashboardData?.totalSubscribersActive}
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
          Post Likes
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {dashboardData?.totalPostLikes}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          All Active Post Likes: {dashboardData?.totalPostLikesActive}
        </Typography>
      </MetricItem>
    </MetricsWrap>
    <MetricsWrap>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
        Total Event Post &quot;Interested&quot;
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {dashboardData?.totalEventPostInterested}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          All Active &quot;Interested&quot;: {dashboardData?.totalEventPostInterestedActive}
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
          Total Event Post &quot;Got Deal&quot;
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {dashboardData?.totalEventPostGotDeal}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          All Active &quot;Got Deal&quot;: {dashboardData?.totalEventPostGotDealActive}
        </Typography>
      </MetricItem>
      <MetricItem>
        <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
          Total Event Post &quot;Attended&quot;
        </MetricLabel>
        <HStack justify="flex-start" alignItems="center">
          <IconWrap bgColor={theme.colors.yellowBase3}>
            <Grade size={24} color={theme.colors.yellowBase} />
          </IconWrap>
          <Typography variant="headingXL" color={theme.colors.yellowBase}>
            {dashboardData?.totalEventPostAttended}
          </Typography>
        </HStack>
        <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
          All Active &quot;Attended&quot;: {dashboardData?.totalEventPostAttendedActive}
        </Typography>
      </MetricItem>          
    </MetricsWrap>
  </DashboardStatisticsWrap>
)

export default withTheme(DashboardStatistics)

DashboardStatistics.propTypes = {
  theme: PropTypes.object.isRequired,
  dashboardData: PropTypes.object.isRequired
}
