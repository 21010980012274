import React from 'react'
import OrganizationDashboardMainSection from '../OrganizationDashboardMainSection'
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary'
import { DashboardWrap } from './styles'

const OrganizationDashboard = () => (
  <DashboardWrap>
    <ErrorBoundary>
      <OrganizationDashboardMainSection />
    </ErrorBoundary>
  </DashboardWrap>
)

export default OrganizationDashboard

OrganizationDashboard.propTypes = {
}
