import styled from 'styled-components'
import { VStack } from '../../../global/styles'

export const TicketDetailsWrap = styled(VStack)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 150px;
`

export default TicketDetailsWrap


