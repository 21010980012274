import { findIndex } from 'lodash/array'
import dayjs from 'dayjs'
import { api as apiSlice} from './api.generated'

const shopGalleryApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShopImages: build.query({
      query: ({shopId, page, limit }) => ({
        url: `/shop/${shopId}/gallery?page=${page}&limit=${limit}`,
        method: 'get'
      }),
      providesTags: ['ShopGallery']
    }),
    postShopImage: build.mutation({
      query: (data) => ({
        url: '/shop/gallery',
        method: 'post',
        data
      }),
      invalidatesTags: ['ShopGallery'],
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopImages', undefined, (draft) => {
            draft.unshift({
              id: 'newImage',
              createdAt: dayjs().format(),
              image: data.image
            })
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    deleteShopImage: build.mutation({
      query: (imageId) => ({
        url: `/shop/gallery/${imageId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopGallery'],
      onQueryStarted: async (imageId, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopImages', undefined, (draft) => {
            const index = findIndex(draft, (product) => product.id === imageId)
            draft.splice(index, 1)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    })
  }),
  overrideExisting: true
})

export const {
  useGetShopImagesQuery,
  useLazyGetShopImagesQuery,
  usePostShopImageMutation,
  useDeleteShopImageMutation
} = shopGalleryApi
