import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const DashboardWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
`
