import { get } from 'lodash/object'

export const selectCurrentCityId = state => state.shop.selectedCityId
export const selectCitiesSelectOptions = state => state.shop.citiesOptions

export const selectShopId = (state) => get(state, 'auth.shopId', null)
export const selectOrganizationId = (state) => get(state, 'auth.organizationId', null)
export const selectUserId = (state) => get(state, 'auth.userId', null)
export const selectIsEditingShop = (state) =>
  get(state, 'shop.isEditingShop', false)
export const selectShopLogotype = (state) =>
  get(state, 'shop.shopLogotype', null)
