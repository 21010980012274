import React, { useMemo } from 'react'
import { useTable, useRowSelect, usePagination, useGlobalFilter } from 'react-table'
import Modal from 'react-modal'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import PropTypes from 'prop-types'
import { Search } from '@styled-icons/material'
import {
  useGetOrganizationShopsQuery
} from '../../api/api.generated'
import {
  INPUT_SIZES,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../const/UIvariants'
import { TableDarkText } from '../../global/styles'
import LoadingSpinner from '../LoadingSpinner'
import TableCheckboxCell from '../TableCheckboxCell'
import EntityTable from '../EntityTable'
import CustomTextInput from '../UI/Inputs/TextInput/CustomTextInput'
import Typography from '../UI/Typography'
import Button from '../UI/Buttons/Button'
import TableImageLabelCell from '../TableImageLabelCell'
import { 
  ModalBody,
  Headline,
  InputsWrapper,
  ErrorWrap,
  Toolbar,
  ButtonWrap,
} from './styles'

const AddToStoresModal = ({ title, isOpen, onSubmit, onClose, organizationId, error }) => {
  const { data: shopsTableData, isLoading, error: shopsError } = useGetOrganizationShopsQuery(organizationId)

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'shopName'
    },
    {
      Header: 'Location',
      accessor: 'location'
    },
    {
      Header: 'forSearch',
      accessor: 'forSearch'
    }
  ]

  const ShopTableRow = (shopItem) => ({
    shopId: shopItem.id,
    shopName: (
      <TableImageLabelCell
        image={shopItem.logotype}
        bgColor={shopItem.avatarBackground}
        label={shopItem.shopName}
        isRounded
      />
    ),
    location: <TableDarkText>{shopItem.displayAddress}</TableDarkText>,
    forSearch: `${shopItem.displayAddress} ${shopItem.shopName}`
  })

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      shopsTableData
        ? shopsTableData?.shops?.map((shopItem) => ShopTableRow(shopItem))
        : [],
    [shopsTableData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, globalFilter },
    pageOptions,
    setGlobalFilter,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.shopId,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'last',
        }
      ])
    }
  )
 
  const handleSubmit = () => {
    if (!isEmpty(selectedRowIds)) {
      onSubmit(keys(selectedRowIds));
    }
  } 

  return (
    <Modal {...{ isOpen }}>
      <ModalBody>
          <Headline>{title}</Headline>
          {isLoading && <LoadingSpinner />}
          {shopsError && 
            <>
              <Typography variant="textS" color="red">Network Error!</Typography>
              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                  type="button"
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </>
          }
          {shopsTableData && (
            <>
              <Toolbar>
                <CustomTextInput
                  handleChange={(e) => {
                    setGlobalFilter(e.target.value)
                  }}
                  placeholder="Search store by name or address"
                  inputName="search"
                  size={INPUT_SIZES.SMALL}
                  value={globalFilter || ''}
                  leftIcon={<Search />}
                />
              </Toolbar>
              <InputsWrapper>
                <EntityTable
                    pageName="stores"
                    {...{
                    getTableProps,
                    headerGroups,
                    getTableBodyProps,
                    page,
                    prepareRow,
                    rows,
                    pageOptions,
                    pageIndex,
                    gotoPage
                  }}
                />
              </InputsWrapper>
              {error && 
                <ErrorWrap>
                  <Typography variant="textS" color="red">
                  {error}
                  </Typography>
                </ErrorWrap>          
              }
              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={handleSubmit}
                  type="button"
                >
                  Save
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                  type="button"
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </>
          )}
      </ModalBody>
    </Modal>
  )
}

export default AddToStoresModal

AddToStoresModal.defaultProps = {}

AddToStoresModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired
}
