import styled from 'styled-components/macro'

export const ShopPageWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
`
export const ShopInfoSection = styled.section`
  display: flex;
  padding: 32px;
  flex-grow: 2;
  align-self: stretch;
  flex-direction: column;
  overflow: scroll;
`
export const ShopTabsInfo = styled.div`
  background-color: white;
  display: flex;
  padding: 12px 24px 32px 24px;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  box-shadow: 0px 6px 16px -4px rgba(15, 18, 20, 0.1);
  border-radius: 12px;
`

export const test = styled.div``
