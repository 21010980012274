import styled from 'styled-components'
import { DetailsPanelTopSection, HStack, VStack } from '../../../global/styles'

export const PostDetailsHeader = styled(DetailsPanelTopSection)`
  align-items: flex-start;
  padding-bottom: 24px;
  margin-bottom: 24px;  
`
export const PostDetailsWrap = styled(VStack)`
  width: 100%;
  height: 100%;
`
export const BadgeWrap = styled(HStack)`
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
`
