import styled from 'styled-components'
import { VStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const WeekDay = styled(VStack)`
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.skyBase : theme.colors.skyNeutral2};
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.skyBase4 : theme.colors.skyNeutral4};
  border-radius: 28px;
  height: 28px;
  margin-right: 10px;
  min-width: 28px;
`
