import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { withTheme } from 'styled-components'
import { RoomItemWrap, RoomMessageWrap, TimeStampWrap, ContactNameWrap, Pronouns } from './styles'
import { USER_AVATAR_SIZES } from '../../const/UIvariants'
import UserAvatar from '../UI/UserAvatar'
import Typography from '../UI/Typography'

dayjs.extend(relativeTime)
const RoomPreviewItem = ({
  theme,
  userName,
  preferredPronouns,
  userPic,
  timeStamp,
  message,
  isOnline,
  hasNewMessage,
  isSelected,
  shopName,
  handleClick
}) => (
  <RoomItemWrap onClick={handleClick} {...{ isSelected }}>
    <UserAvatar
      size={USER_AVATAR_SIZES.SMALL}
      userPic={userPic}
      userName={userName}
      isOnline={isOnline}
    />
    <RoomMessageWrap>
      <ContactNameWrap>
        <Typography variant="textXS" color={theme.colors.skyNeutral_2}>
          {userName}
        </Typography>
        {preferredPronouns !== '' &&
        <Pronouns>({preferredPronouns})</Pronouns>
        }
      </ContactNameWrap>  
      <Typography variant="textXS" color={theme.colors.skyNeutral_2}>
        {shopName}
      </Typography>
      <Typography variant="textXS" color={theme.colors.skyNeutral1}>
        {message}
      </Typography>
      <TimeStampWrap>
        <Typography variant="textXS" color={theme.colors.skyNeutral2}>
          {dayjs(timeStamp).fromNow()}
        </Typography>
        {hasNewMessage && (
          <Typography
            variant="textXS"
            color={theme.colors.pinkBase}
            align="right"
          >
            New message
          </Typography>
        )}
      </TimeStampWrap>
    </RoomMessageWrap>
  </RoomItemWrap>
)

export default withTheme(RoomPreviewItem)

RoomPreviewItem.defaultProps = {
  preferredPronouns: '',
  userPic: null,
  timeStamp: "",
}

RoomPreviewItem.propTypes = {
  theme: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  preferredPronouns: PropTypes.string,
  userPic: PropTypes.string,
  timeStamp: PropTypes.string,
  message: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  shopName: PropTypes.string.isRequired,
  hasNewMessage: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}
