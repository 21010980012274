import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { 
  useGetOrganizationShopsQuery
} from '../../../api/api.generated'
import {
  useLazyGetShopQuestionQuery,
  usePostShopQuestionMutation,
  usePutShopQuestionMutation
} from '../../../api/shopQuestionsApi'
import { getErrorMessage } from '../../../utils/helpers'
import { ErrorWrap } from '../../../global/styles'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import ShowErrorModal from '../../../components/ShowErrorModal'
import {
  FormWrap,
  Headline,
  ButtonWrap
} from './styles'

const emptyQuestion = {
  shopId: '',
  question: '',
  answer: ''
}

const AddQuestionModal = ({ isOpen, onClose, editQuestionId, organizationId, shopId, showShops }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyQuestion)
  const [error, setError] = useState('')
  const [getQuestion, { data: shopQuestion, error: questionError }] = useLazyGetShopQuestionQuery()
  const [postQuestion] = usePostShopQuestionMutation()
  const [updateQuestion] = usePutShopQuestionMutation()

  const title = (isNull(editQuestionId)) ? 'Add FAQ' : 'Edit FAQ';

  let validationSchema
  let shopList = [];
  if (showShops) {
    validationSchema = Yup.object({
      shopId: Yup.string().required('Store is required'),
      question: Yup.string().max(250, 'Question is too long').required('Question is required'),
      answer: Yup.string().max(5000, 'Answer is too long').required('Answer is required')
    })

    const { data: shopsData } = useGetOrganizationShopsQuery(organizationId)
    shopList = useMemo(
      () =>
        shopsData
          ? shopsData?.shops?.map((shop) => ({value: shop.id, label: shop.shopName })) 
          : [],
      [shopsData]
    )
  } else {
    validationSchema = Yup.object({
      question: Yup.string().max(250, 'Question is too long').required('Question is required'),
      answer: Yup.string().max(5000, 'Answer is too long').required('Answer is required')
    })
  } 

  useEffect(() => {
    if (!isNull(editQuestionId)) {
      getQuestion(editQuestionId)
    } else {
      setInitialData(emptyQuestion)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopQuestion) {
      setInitialData({
        shopId: shopQuestion.shopId,
        question: shopQuestion.question,
        answer: shopQuestion.answer,
      })
      setIsSuccess(true)
    }
  }, [shopQuestion])

  const handleSubmitForm = (values) => {
    setError('')
    const data = values;
    if (!isNull(shopId)) {
      data.shopId = shopId
    }    
    data.question = values.question
    data.answer = values.answer

    if (editQuestionId) {
      updateQuestion({
        questionId: editQuestionId,
        data
      })
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    } else {
      postQuestion(data)
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !questionError &&
        <LoadingSpinner />
      } 
      {questionError && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }         
      {isSuccess && (
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmitForm(values)
            }}
          >
            <Form>
              <Headline>{ title }</Headline>
              {showShops &&
                <FormSelectOneInput
                  inputName="shopId"
                  label="Store"
                  options={shopList}
                  size="100%"
                  value={initialData.shopId}
                  isGrey
                />
              }
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="question"
                label="Question"
                placeholder="Enter question"
              />
              <TextAreaInput
                inputName="answer"
                label="Answer"
                rows={14}
                placeholder="Enter answer"
                labelMarginBottom={0}
              />  
              {error && 
                <ErrorWrap>
                  <Typography variant="textS" color="red">
                  {error}
                  </Typography>
                </ErrorWrap>          
              }
              <ButtonWrap>
                <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </Form>
          </Formik>
        </FormWrap>
      )}
    </Modal>
  )
}

export default withTheme(AddQuestionModal)

AddQuestionModal.defaultProps = {
  editQuestionId: null,
  organizationId: null,
  shopId: null,
  showShops: false
}

AddQuestionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editQuestionId: PropTypes.number,
  organizationId: PropTypes.string,
  shopId: PropTypes.string,
  showShops: PropTypes.bool
}
