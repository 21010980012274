import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'

export const ListItemWrap = styled(HStack)`
  padding: 12px 4px 18px 4px;
  &:not(:first-child) {
    box-shadow: inset 0 1px 0 rgba(208, 201, 214, 0.4);
  }
`

export const Tick = styled(VStack)`
  align-items: center;
  justify-content: center;
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  margin-right: 16px;
  height: 32px;
  border-radius: 32px;
  background: ${({ isCompleted, theme }) =>
    isCompleted ? theme.colors.greenBase : theme.colors.skyNeutral4};
`
