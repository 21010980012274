import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const ProductImage = styled.img`
  background-size: cover;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  border-radius: 3px;
  min-width: 81px;
  max-width: 81px;
`
export const ProductDetailsWrap = styled(VStack)`
  margin-left: 16px;
  min-width: 60%;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`
export const AddNewProductWrap = styled(HStack)`
  height: 106px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  min-width: 30%;
  width: 32%;
  margin-right: 2%;
  padding: 8px;
  position: relative;
  margin-bottom: 12px;
  &:nth-child(3n) {
    margin-right: 0;
  }
`
export const ProductWrap = styled(AddNewProductWrap)``
export const ImagePlaceholder = styled(VStack)`
  min-width: 81px;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greenCreator(50, 97)};
`

export const AddNewProductBody = styled(VStack)`
  height: 100%;
  justify-content: space-between;
  margin-left: 15px;
`
export const ProductsList = styled(HStack)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-top: 24px;
`
export const AddNewProductLabel = styled(Typography)`
  color: hsla(120, 7%, 46%, 1);
`
export const LinkTag = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme, isActive }) => isActive ? theme.colors.skyBase : theme.colors.skyNeutral1};
  margin-right: 5px;
`

export const ProductNameWrap = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const TagLinkWrap = styled(HStack)`
  margin-top: auto;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: 0;
`
export const BadgesWrap = styled(HStack)`
  align-items: center;
  padding: 18px 0 0 0;
  & > * {
    margin-right: 12px;
  }
`
export const Footer = styled(HStack)`
  justify-content: space-between;
  align-items: center;
`
export const ErrorMessageWrap = styled(VStack)`
  padding: 16px 16px 0 16px;
  max-width: 50vw;
  align-items: stretch;
`
