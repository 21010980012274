import styled from 'styled-components/macro'
import Typography from '../UI/Typography'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`
export const ContentWrap = styled.div`
  z-index: 100;
  position: absolute;
  bottom: 60%;
  left: 45%;
`
export const TopHead = styled.div`
  position: absolute;
  z-index: 20;
  transform: rotateY(180deg);
  bottom: 15%;
  left: 65%;
  width: 85%;
  height: 85%;
  svg {
    width: 100%;
    height: 100%;
  }
`
export const BottomHead = styled.div`
  position: absolute;
  z-index: 20;
  bottom: -20%;
  left: 10%;
  width: 85%;
  height: 85%;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const BlueBgSlice = styled.div`
  border-style: solid;
  border-width: 0 0 100vh 365px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  border-color: transparent transparent ${({ theme }) => theme.colors.skyBase}
    transparent;
`
export const OrangeBgSlice = styled.div`
  border-style: solid;
  border-width: 0 0 100vh 280px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  border-color: transparent transparent
    ${({ theme }) => theme.colors.earthCreator(62, 86)} transparent;
`
export const WelcomeMessage = styled(Typography)`
  margin-bottom: 16px;
`
