import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { values } from 'lodash/object'
import { withTheme } from 'styled-components'
import {
  InputBodyWrapper,
  InputError,
  InputInnerLabel,
  InputOuterLabel,
  InputWrapper,
  LeftSideIcon,
  RightSideIcon,
  StyledInput
} from './styles'
import IconButton from '../../Buttons/IconButton'
import { INPUT_SIZES } from '../../../../const/UIvariants'

const TextInput = ({
  disabled,
  label,
  size,
  inputName,
  rightIconClick,
  rightIcon,
  leftIcon,
  placeholder,
  type,
  theme,
  labelMarginBottom
}) => {
  const [field, meta] = useField({ name: inputName })
  const { name, onBlur, onChange, value, multiple } = field
  return (
    <InputWrapper {...{ size }} error={meta.touched && meta.error}>
      {size === INPUT_SIZES.LARGE ? (
        <InputInnerLabel
          {...{ disabled }}
          htmlFor={name}
          leftPadding={!!leftIcon}
          labelMarginBottom={labelMarginBottom}
        >
          {label}
        </InputInnerLabel>
      ) : (
        label && (
          <InputOuterLabel
            {...{ disabled }}
            htmlFor={name}
            variant="textS"
            component="label"
          >
            {label}
          </InputOuterLabel>
        )
      )}
      <InputBodyWrapper>
        {leftIcon && (
          <LeftSideIcon style={{ color: value && theme.colors.skyNeutral_1 }}>
            {leftIcon}
          </LeftSideIcon>
        )}
        <StyledInput
          {...{
            name,
            onBlur,
            onChange,
            value,
            multiple,
            disabled,
            placeholder,
            size,
            type
          }}
          autocomplete="off"
          autofill="off"
          list="testList"
          id={inputName}
          leftPadding={!!leftIcon}
          error={meta.touched && meta.error}
        />
        {rightIcon && (
          <RightSideIcon>
            <IconButton onClick={rightIconClick}>
              {React.cloneElement(rightIcon, {
                width: 20,
                height: 20,
                color: theme.colors.skyNeutral2
              })}
            </IconButton>
          </RightSideIcon>
        )}
      </InputBodyWrapper>
      <InputError>{meta.touched && meta.error ? meta.error : null}</InputError>
    </InputWrapper>
  )
}

export default withTheme(TextInput)

TextInput.defaultProps = {
  rightIconClick: null,
  rightIcon: null,
  leftIcon: null,
  disabled: false,
  labelMarginBottom: 12,
  placeholder: 'Enter data',
  size: INPUT_SIZES.LARGE,
  type: 'text',
  label: null
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  rightIconClick: PropTypes.func,
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  size: PropTypes.oneOf(values(INPUT_SIZES)),
  disabled: PropTypes.bool,
  labelMarginBottom: PropTypes.number,
  theme: PropTypes.object.isRequired
}
