import React from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash/object'
import {
  InputBodyWrapper,
  InputError,
  InputInnerLabel,
  InputOuterLabel,
  InputWrapper,
  LeftSideIcon,
  RightSideIcon,
  StyledInput
} from './styles'
import IconButton from '../../Buttons/IconButton'
import { INPUT_SIZES } from '../../../../const/UIvariants'
import { globalTheme } from '../../../../global/theme'

const CustomTextInput = ({
  disabled,
  label,
  size,
  inputName,
  rightIconClick,
  rightIcon,
  leftIcon,
  placeholder,
  type,
  isWhite,
  value,
  handleChange,
  handleBlur,
  handleKeyPress,
  error
}) => (
  <InputWrapper {...{ size }} error={error}>
    {size === INPUT_SIZES.LARGE ? (
      <InputInnerLabel
        {...{ disabled }}
        htmlFor={inputName}
        leftPadding={!!leftIcon}
      >
        {label}
      </InputInnerLabel>
    ) : (
      label && (
        <InputOuterLabel
          {...{ disabled }}
          htmlFor={inputName}
          variant="textS"
          component="label"
        >
          {label}
        </InputOuterLabel>
      )
    )}
    <InputBodyWrapper>
      {leftIcon && (
        <LeftSideIcon
          style={{ color: value && globalTheme.colors.skyNeutral_1 }}
        >
          {leftIcon}
        </LeftSideIcon>
      )}
      <StyledInput
        {...{
          value,
          disabled,
          isWhite,
          placeholder,
          size,
          type,
          error
        }}
        onKeyPress={handleKeyPress}
        name={inputName}
        onBlur={handleBlur}
        onChange={handleChange}
        leftPadding={!!leftIcon}
      />
      {rightIcon && (
        <RightSideIcon>
          <IconButton onClick={rightIconClick}>
            {React.cloneElement(rightIcon, {
              width: 20,
              height: 20,
              color: globalTheme.colors.skyNeutral2
            })}
          </IconButton>
        </RightSideIcon>
      )}
    </InputBodyWrapper>
    {error && <InputError>{error}</InputError>}
  </InputWrapper>
)

export default CustomTextInput

CustomTextInput.defaultProps = {
  rightIconClick: null,
  handleKeyPress: null,
  rightIcon: null,
  leftIcon: null,
  disabled: false,
  isWhite: false,
  placeholder: 'Enter data',
  size: INPUT_SIZES.LARGE,
  type: 'text',
  value: '',
  handleBlur: null,
  error: null,
  label: ''
}

CustomTextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  rightIconClick: PropTypes.func,
  handleKeyPress: PropTypes.func,
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  size: PropTypes.oneOf(values(INPUT_SIZES)),
  disabled: PropTypes.bool,
  isWhite: PropTypes.bool,
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  error: PropTypes.string
}
