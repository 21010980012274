/*eslint-disable*/
import React from 'react'
import ShopTableOpenDays from '../../../components/ShopTableOpenDays'
import { TableDarkText, TableText } from '../../../global/styles'

const ShopTableRow = (shopItem) => ({
  shopId: shopItem.id,
  shopName: <TableDarkText>{shopItem.shopName}</TableDarkText>,
  location: <TableDarkText>{shopItem.displayAddress}</TableDarkText>,
  daysOperational: <ShopTableOpenDays shopSchedule={shopItem.schedule} />,
  email: <TableText>{shopItem.email}</TableText>,
  forSearch: `${shopItem.displayAddress} ${shopItem.shopName}`
})

export default ShopTableRow

ShopTableRow.propTypes = {}
