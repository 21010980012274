import { Form } from 'formik'
import styled from 'styled-components'
import Typography from '../components/UI/Typography'

export const AppWrap = styled.div`
  display: block;
  width: 100%;
  justify-content: flex-start;
`
export const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 16px -4px rgba(15, 18, 20, 0.1);
`
export const HStack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
`
export const VStack = styled.div`
  display: flex;flex-direction: column;
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
`
export const AuthWelcomeMessage = styled(Typography)`
  margin: 20px 0 40px 0;
`
export const AuthFormWrap = styled(Form)`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 32px;
  position: relative;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
`
export const ManageEntityDashboardWrap = styled(HStack)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: space-between;
  align-items: stretch;
`
export const DashboardInfoWrap = styled(VStack)`
  overflow: scroll;
  width: 100%;
  height: 100%;
  padding: 42px 32px;
`
export const TableDarkText = styled(Typography).attrs({
  variant: 'textS'
})`
  word-wrap: anywhere;
  overflow-wrap: anywhere;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const TableText = styled(Typography).attrs({
  variant: 'textS'
})`
  word-wrap: anywhere;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
export const ST = styled(Typography)`
  border: 10px solid red;
`
export const NavWrap = styled.nav`
  display: block;
  position: absolute;
  bottom: 0;
  padding-right: 20px;
`
export const MainContainer = styled.div`
  display: block;
  overflow: hidden;
  height: 100%;
  background: ${({ theme }) => theme.colors.appBackground};
`
export const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`
export const Footer = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const DetailsInfoBlock = styled(VStack)`
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin-top: 32px;
`
export const DetailsPanelTopSection = styled(VStack)`
  width: 100%;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.skyNeutral4};

  & > *:first-child {
    margin-bottom: 16px;
  }
`
export const AsideDetailsTitle = styled(Typography).attrs(({ theme }) => ({
  variant: 'textL',
  color: theme.colors.skyNeutral_2
}))``
export const AsideDetailsLabel = styled(Typography).attrs(({ theme }) => ({
  variant: 'textS',
  color: theme.colors.skyNeutral1
}))`
  word-wrap: anywhere;
`
export const AsideDetailsDescription = styled(Typography).attrs(
  ({ theme }) => ({
    variant: 'textS',
    color: theme.colors.skyNeutral_1,
    as: 'h2'
  })
)``
export const IsEmptyText = styled(Typography)`
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const Toolbar = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  > * {
    margin-right: 10px;
  }
`
export const ErrorWrap = styled(HStack)`
  padding: 0;
  max-width: 50vw;
  align-items: stretch;
`
export const ErrorMessageWrap = styled(HStack)`
  padding: 0 32px 16px;
  max-width: 50vw;
  align-items: stretch;
`
export const ErrorMessageTopWrap = styled(VStack)`
  padding: 16px 16px 0 16px;
  max-width: 50vw;
  align-items: stretch;
`
export const ButtonWrap = styled(HStack)`
  margin-right: 0;
  > * {
    height: 44px;
  }
`
export const SearchButtonWrap = styled(HStack)`
  margin-right: 0;
  > * {
    height: 44px;
  }
`
export const InputError = styled.p`
  font-size: 13px;
  margin-top: 2px;
  height: 16px;
  color: ${({ theme }) => theme.colors.greenCreator(7, 46)};
`