import React from 'react'
import { Preview, Edit, DeleteOutline, Chat, QueryStats, Receipt } from '@styled-icons/material'
import PropTypes from 'prop-types'
import IconButton from '../UI/Buttons/IconButton'
import { ButtonWrap } from './styles'

const TableButtonCell = ({
  cell,
  handleShowConfirmModal,
  handleSetIdToDelete,
  handleSelectRow,
  handleShowEditModal,
  handleShowDetails,
  handleShowDashboard,
  handleShowSendMessage,
  handleSendReceipt,
  canEdit,
  checkCanEdit,
  showView,
  showDashboard,
  showDelete,
  showSendMessage,
  showSendReceipt
}) => (
  <ButtonWrap>
    {showView && 
    <IconButton
      onClick={(e) => {
        e.stopPropagation()
        handleShowDetails(cell.row.id)
      }}
    >
      <Preview size={20} color="#A0A7B5" />
    </IconButton>
    }
    {showDashboard && 
    <IconButton
      onClick={(e) => {
        e.stopPropagation()
        handleShowDashboard(cell.row.id)
      }}
    >
      <QueryStats size={20} color="#A0A7B5" />
    </IconButton>
    }
    {canEdit && (!checkCanEdit || cell.row.original.canEdit) && 
    <IconButton
      onClick={(e) => {
        console.log(cell.row.original.canEdit)
        e.stopPropagation()
        handleSetIdToDelete(cell.row.id)
        handleSelectRow(cell.row)
        handleShowEditModal(true)
      }}
    >
      <Edit size={20} color="#A0A7B5" />
    </IconButton>
    }
    {showDelete && 
    <IconButton
      onClick={(e) => {
        e.stopPropagation()
        handleSetIdToDelete(cell.row.id)
        handleSelectRow(cell.row)
        handleShowConfirmModal(true)
      }}
    >
      <DeleteOutline size={20} color="#EC72A5" />
    </IconButton>
    }
    {showSendMessage && 
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          handleShowSendMessage(cell.row.id)
        }}
      >
        <Chat size={20} color="#A0A7B5" />
      </IconButton>
    }
    {showSendReceipt && 
      <IconButton
      onClick={(e) => {
        e.stopPropagation()
        handleSendReceipt(cell.row.id)
      }}
    >
      <Receipt size={20} color="#A0A7B5" />
    </IconButton>
    }
  </ButtonWrap>
)

TableButtonCell.defaultProps = {
  handleShowEditModal: () => {},
  handleShowDetails: () => {},
  handleShowDashboard: () => {},
  handleSelectRow: () => {},
  handleShowConfirmModal: () => {},
  handleShowSendMessage: () => {},
  handleSendReceipt: () => {},
  handleSetIdToDelete: null,
  canEdit: false,
  checkCanEdit: false,
  showView: false,
  showDelete: true,
  showDashboard: false,
  showSendMessage: false,
  showSendReceipt: false
}

TableButtonCell.propTypes = {
  cell: PropTypes.object.isRequired,
  handleShowConfirmModal: PropTypes.func,
  handleSetIdToDelete: PropTypes.func,
  handleShowEditModal: PropTypes.func,
  handleShowDetails: PropTypes.func,
  handleShowDashboard: PropTypes.func,
  handleShowSendMessage: PropTypes.func,
  handleSendReceipt: PropTypes.func,
  handleSelectRow: PropTypes.func,
  canEdit: PropTypes.bool,
  checkCanEdit: PropTypes.bool,
  showView: PropTypes.bool,
  showDashboard: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSendMessage: PropTypes.bool,
  showSendReceipt: PropTypes.bool
}
export default TableButtonCell
