import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import ImageUploading from 'react-images-uploading'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTheme } from 'styled-components'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { getImageFromServer, getImageBase64, getErrorMessage } from '../../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import SelectManyInput from '../../../components/UI/Inputs/SelectManyInput'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import Typography from '../../../components/UI/Typography'
import UploadImagePreview from '../../../components/UploadImagePreview'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import ShowErrorModal from '../../../components/ShowErrorModal'
import { 
  useGetOrganizationShopsQuery
} from '../../../api/api.generated'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import {
  useLazyGetShopProductQuery,
  usePostShopProductMutation,
  usePutShopProductMutation
} from '../../../api/shopProductsApi'
import { ErrorMessageWrap, InputError } from '../../../global/styles'
import {
  OrganisationWrapper,
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap,
  ImageTitleWrap,
  ImageControlButtonsWrap,
  ImageUploadContent,
  ImageUploadWrap,
  StyledForm,
  TitleWrap,
  LeftHalf,
  RightHalf,
  FormWrap,
  PriceWrap,
  QuantityWrap,
  SwitchWrap
} from './styles'

const emptyProduct = {
  shopId: '',
  productName: '',
  price: '',
  shippingCost: '',
  quantity: '',
  isShippingEnabled: [],
  isCollectEnabled: [],
  categories: [],
  productImage: ''
}

const AddNewProductModal = ({ isOpen, onClose, editProductId, theme, showShops, organizationId, shopId }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyProduct)
  const [error, setError] = useState('')
  const { data: categories, error: categoriesError } = useGetCategoriesQuery()
  const [getProduct, { data: shopProduct, error: productError }] = useLazyGetShopProductQuery()
  const [postShopProduct] = usePostShopProductMutation()
  const [updateShopProduct] = usePutShopProductMutation()

  const title = (isNull(editProductId)) ? 'Add Product' : 'Edit Product';

  let validationSchema
  let shopList = [];
  if (showShops) {
    validationSchema = Yup.object({
      shopId: Yup.string().required('Store is required'),
      productImage: Yup.string().required('Product image is required'),
      productName: Yup.string().required('Product name is required'),
      price: Yup.number().required('Product price is required'),
      shippingCost: Yup.number().required('Shipping cost is required'),
      quantity: Yup.number().integer().required('Quantity cost is required'),
      categories: Yup.array()
        .min(1, 'At lease one category should be selected')
        .max(5, 'Only 5 categories per product allowed')
    })

    const { data: shopsData } = useGetOrganizationShopsQuery(organizationId)
    shopList = useMemo(
      () =>
        shopsData
          ? shopsData?.shops?.map((shop) => ({value: shop.id, label: shop.shopName })) 
          : [],
      [shopsData]
    )
  } else {
    validationSchema = Yup.object({
      productImage: Yup.string().required('Product image is required'),
      productName: Yup.string().required('Product name is required'),
      price: Yup.number().required('Product price is required'),
      shippingCost: Yup.number().required('Shipping cost is required'),
      quantity: Yup.number().integer().required('Quantity cost is required'),
      categories: Yup.array()
        .min(1, 'At lease one category should be selected')
        .max(5, 'Only 5 categories per product allowed')
    })
  } 

  useEffect(() => {
    if (!isNull(editProductId)) {
      getProduct(editProductId)
    } else {
      setInitialData(emptyProduct)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopProduct) {
      setInitialData({
        shopId: shopProduct.shopId,
        productName: shopProduct.productName,
        price: shopProduct.price,
        shippingCost: shopProduct.shippingCost,
        quantity: shopProduct.quantity,
        isShippingEnabled: shopProduct.isShippingEnabled ? ['isShippingEnabled'] : [],
        isCollectEnabled: shopProduct.isCollectEnabled ? ['isCollectEnabled'] : [],
        categories: shopProduct.categories,
        productImage: getImageFromServer(shopProduct.productImage)
      })
      setIsSuccess(true)
    }
  }, [shopProduct])

  const displayError = (err) => {
    setError(getErrorMessage(err))
  }

  const handleSubmitForm = (values) => {
    setError('')

    const isShippingEnabled = values.isShippingEnabled?.[0] === 'isShippingEnabled'
    const isCollectEnabled = values.isCollectEnabled?.[0] === 'isCollectEnabled'

    if (!isShippingEnabled && !isCollectEnabled) {
      setError('Shipping or Click & Collect must be enabled')
      return
    }

    const data = {
      shopId: values.shopId,
      productName: values.productName,
      price: values.price,
      shippingCost: values.shippingCost,
      quantity: values.quantity,
      isShippingEnabled,
      isCollectEnabled,
      categories: values.categories,
    }
    if (!isNull(shopId)) {
      data.shopId = shopId
    }

    if (editProductId) {
      const image = values?.productImage?.includes('data:image')
        ? values.productImage
        : undefined

      data.productImage = image
      updateShopProduct({
        productId: editProductId,
        data
      })
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    } else {
      data.productImage = values.productImage
      postShopProduct(data)
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        displayError(err);
      })
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!(categories && isSuccess) && !productError && !categoriesError && 
      <LoadingSpinner />
      }      
      {(productError || categoriesError) && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }       
      {categories && isSuccess && (
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmitForm(values, editProductId)
            }}
          >
            {({ values, setFieldValue, errors }) => (
              <StyledForm>
                <TitleWrap>
                  <Typography align="center" variant="headingM">{ title } </Typography>
                </TitleWrap>
                {showShops &&
                  <OrganisationWrapper>
                    <FormSelectOneInput
                      inputName="shopId"
                      label="Store"
                      options={shopList}
                      size="100%"
                      value={initialData.shopId}
                      isGrey
                    />
                  </OrganisationWrapper>
                }
                <FormInputsWrap>
                  <LeftHalf>
                    {(isNull(editProductId) || !shopProduct.isOrganizationProduct) &&
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName="productName"
                      label="Name"
                      placeholder="Enter name"
                    />
                    }
                    <PriceWrap>
                      <TextInput
                        type="number"
                        size={INPUT_SIZES.SMALL}
                        inputName="price"
                        label="Price"
                        placeholder="$00.00"
                      />
                      <TextInput
                        type="number"
                        size={INPUT_SIZES.SMALL}
                        inputName="shippingCost"
                        label="Shipping Cost"
                        placeholder="$00.00"
                      />
                    </PriceWrap>
                    <QuantityWrap>
                      <TextInput
                        type="number"
                        size={INPUT_SIZES.SMALL}
                        inputName="quantity"
                        label="Quantity"
                        placeholder="0"
                      />
                    </QuantityWrap>
                    { (isNull(editProductId) || !shopProduct.isOrganizationProduct) && categories && (
                      <SelectManyInput
                        options={categories.map((category) => ({
                          value: category.id,
                          label: category.tagName
                        }))}
                        label="Category"
                        inputName="categories"
                      />
                    )}
                  </LeftHalf>
                  <RightHalf>
                    { (isNull(editProductId) || !shopProduct.isOrganizationProduct) &&
                    <ImageUploading
                      value={values.productImage}
                      onChange={(image) => {
                        setFieldValue(
                          'productImage',
                          getImageBase64({ source: image })
                        )
                      }}
                      acceptType={['jpg', 'gif', 'png']}
                      maxFileSize={4000000}
                      dataURLKey="data_url"
                    >
                      {({ onImageUpload }) => (
                        <>
                          <ImageUploadWrap>
                            <ImageUploadContent>
                              <ImageTitleWrap>
                                <Typography
                                  variant="headingS"
                                  color={theme.colors.skyNeutral_2}
                                >
                                  Image
                                </Typography>
                                <Typography
                                  variant="textXS"
                                  color={theme.colors.skyNeutral2}
                                >
                                  File type (JPG, JPEG, or PNG) max. 4MB
                                </Typography>
                              </ImageTitleWrap>
                              <ImageControlButtonsWrap>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.OUTLINED}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={onImageUpload}
                                >
                                  Upload
                                </Button>
                                <Button
                                  type="button"
                                  variant={BUTTON_VARIANTS.SECONDARY}
                                  size={BUTTONS_SIZES.SMALL}
                                  onClick={() => {
                                    setFieldValue('productImage', '')
                                  }}
                                >
                                  Remove
                                </Button>
                              </ImageControlButtonsWrap>
                              <UploadImagePreview
                                uploadedImage={values.productImage}
                              />
                              <InputError>{errors.productImage ? 'Image is required!' : null}</InputError>
                            </ImageUploadContent>
                          </ImageUploadWrap>
                        </>
                      )}
                    </ImageUploading>
                    }
                    <SwitchWrap>
                      <SwitchInput inputName="isShippingEnabled" label="Shipping" value="isShippingEnabled" />
                    </SwitchWrap>
                    <SwitchWrap>
                      <SwitchInput inputName="isCollectEnabled" label="Click & Collect" value="isCollectEnabled" />
                    </SwitchWrap>
                  </RightHalf>
                </FormInputsWrap>
                {error && 
                  <ErrorMessageWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorMessageWrap>          
                }                
                <ActionButtonsSection>
                  <ButtonWrap>
                    <Button
                      type="submit"
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.MED}
                    >
                      Save
                    </Button>
                    <Button
                      type="button"
                      variant={BUTTON_VARIANTS.SECONDARY}
                      size={BUTTONS_SIZES.MED}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </ButtonWrap>
                </ActionButtonsSection>
              </StyledForm>
            )}
          </Formik>
        </FormWrap>
      )}
    </Modal>
  )
}

export default withTheme(AddNewProductModal)

AddNewProductModal.defaultProps = {
  editProductId: null,
  showShops: false,
  organizationId: null,
  shopId: null
}

AddNewProductModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editProductId: PropTypes.string,
  showShops: PropTypes.bool,
  organizationId: PropTypes.string,
  shopId: PropTypes.string
}
