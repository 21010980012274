import React from 'react'
import { find } from 'lodash/collection'
import PropTypes from 'prop-types'
import { StyledSelect } from './styles'

const SelectOneInput = ({ options, setSelected, selected, size, isGrey }) => {
  const handleChange = (selectedOption) => {
    setSelected(selectedOption.value)
  }
  return (
    <StyledSelect
      classNamePrefix="rs"
      onChange={handleChange}
      options={options}
      size={size}
      isGrey={isGrey}
      value={find(options, (option) => option.value === selected)}
    />
  )
}

export default SelectOneInput

SelectOneInput.defaultProps = {
  isGrey: false,
  size: "100%",
  selected: null
}

SelectOneInput.propTypes = {
  isGrey: PropTypes.bool,
  selected: PropTypes.any,
  setSelected: PropTypes.func.isRequired,
  size: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })
  ).isRequired
}
