import styled from 'styled-components/macro'
import { INPUT_SIZES } from '../../../../const/UIvariants'
import Typography from '../../Typography'

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 12px;
  & :focus-within {
    input {
      box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
    }
    label {
      display: block;
      opacity: 1;
    }

    div > svg:first-child {
      color: ${({ theme }) => theme.colors.skyNeutral_1};
    }
    span {
      display: none;
    }
  }
`
export const RightSideIcon = styled.div`
  display: flex;
  right: 11px;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
`

export const InputBodyWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  .react-datepicker-wrapper{
    width: 100%;
    height: ${({ size }) => size}px;

  }
  .react-datepicker__time-container  {
    width: 200px;
    background-color: white;
    border-radius: 4px;
    height: 100px;
    overflow: scroll;
    transform: translate3d(0px, 4px, 0px);
    transition: transform .3s ease-in-out;
    box-shadow: 0 8px 16px -5px rgba(7, 16, 34, 0.1);
    
  }
  .react-datepicker__time-list-item{
    padding: 4px 4px 4px 12px;
    border-bottom: 1px solid hsla(272, 14%, 81%, 0.4);
    font-size: 15px;
    color: ${({ theme }) => theme.colors.skyCreator(12, 51)};
    &:hover {
      background-color:${({ theme }) => theme.colors.skyBase4};
      cursor: pointer;
      color:${({ theme }) => theme.colors.skyBase}
    }

  }
  .react-datepicker-time__header{
    display: none;
  }
  .react-datepicker-popper{
    z-index: 100;
  }
  .react-datepicker__time-list-item--disabled{
    background-color: ${({ theme }) => theme.colors.skyNeutral3};
    color: ${({ theme }) => theme.colors.skyCreator(12, 51)};
    &:hover{
      background-color: ${({ theme }) => theme.colors.skyNeutral3};
      color: ${({ theme }) => theme.colors.skyCreator(12, 51)};
      cursor: not-allowed;
    }
  }
  .react-datepicker__input-container{
    width: 100%;
    height: 100%;
   input {
     width: 100%;
     background-color: ${({ theme }) => theme.colors.skyNeutral4};

     height: 44px;
     color: ${({ theme }) => theme.colors.skyNeutral_1};
     outline: none;
     border: none;
     display: flex;
     border-radius: 8px;
     padding-left: 12px;
     padding-right: 24px;
     font-size: ${({ size }) => (size === INPUT_SIZES.LARGE ? 17 : 15)}px;
     line-height: ${({ size }) => (size === INPUT_SIZES.LARGE ? 20 : 17)}px;
  
     &::-webkit-clear-button {
       display: none;
     }
   }
 }
  }
`

export const InputError = styled.p`
  font-size: 13px;
  margin-top: 8px;
  height: 16px;
  color: ${({ theme }) => theme.colors.greenCreator(7, 46)};
`

export const Placeholder = styled(Typography)`
  position: absolute;
  top: 12px;
  left: 15px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
