import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { values } from 'lodash/object'
import { ColorPreview } from './styles'
import { TAG_COLORS } from '../../../const/app'

const SelectIcon = ({ color, name }) => {
  const [field, meta] = useField({ name })
  return (
    <ColorPreview
      htmlFor={color}
      bgColor={color}
      isActive={meta.value === color}
    >
      <input {...field} value={color} type="radio" id={color} hidden />
    </ColorPreview>
  )
}

export default SelectIcon

SelectIcon.propTypes = {
  color: PropTypes.oneOf(values(TAG_COLORS)).isRequired,
  name: PropTypes.string.isRequired
}
