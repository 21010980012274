import styled from 'styled-components'
import { DetailsPanelTopSection, HStack, VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const TemplateDetailsWrap = styled(VStack)`
  width: 100%;
  height: 100%;
`
export const EmailNameWrap = styled(DetailsPanelTopSection)`
  align-items: flex-start;
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
  margin-bottom: 24px;
  & > :first-child {
    margin-right: 12px;
  }
`
