import React from 'react'
import { Dashboard, Store } from '@styled-icons/material'

export const getMetrics = (data) => [
  {
    description: '',
    icon: <Store />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Total',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: `$${data?.total}`
  },
  {
    description: '',
    icon: <Dashboard />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Fees',
    numberAndIconColor: 'cyanBase_1',
    onClick: () => {},
    value: `$${data?.fees}`
  }
]

export const tableColumns = [
  {
    Header: 'Order #',
    accessor: 'code'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Phone',
    accessor: 'phone'
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'Total',
    accessor: 'amount'
  },
  {
    Header: 'Fee',
    accessor: 'fee'
  }
]
