import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImageUploading from 'react-images-uploading'
import { AttachFile, Send } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import { withTheme } from 'styled-components'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import { INPUT_SIZES } from '../../../const/UIvariants'
import { ActionIconsWrap, ChatInputWrap } from './styles'
import IconButton from '../../../components/UI/Buttons/IconButton'
import { getImageBase64 } from '../../../utils/helpers'

const ChatInput = ({ handleSendMessage, theme }) => {
  const [userMessage, setUserMessage] = useState('')

  const handleSelectImage = (imageList) => {
    const selectedImage = getImageBase64({ source: imageList })
    if (selectedImage) {
      handleSendMessage(null, selectedImage)
    }
  }
  const handleSend = () => {
    if (!isEmpty(userMessage)) {
      setUserMessage('')
      handleSendMessage(userMessage)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend()
    }
  }

  return (
    <ChatInputWrap>
      <CustomTextInput
        value={userMessage}
        size={INPUT_SIZES.SMALL}
        handleChange={(e) => {
          setUserMessage(e.target.value)
        }}
        inputName="userMessage"
        placeholder="Type your message..."
        handleKeyPress={handleKeyPress}
      />
      <ActionIconsWrap>
        <ImageUploading
          value={[]}
          onChange={handleSelectImage}
          acceptType={['jpg', 'gif', 'png']}
          maxFileSize={4000000}
          dataURLKey="data_url"
        >
          {({ onImageUpload }) => (
            <IconButton onClick={onImageUpload}>
              <AttachFile size={32} color={theme.colors.skyNeutral2} />
            </IconButton>
          )}
        </ImageUploading>
        <IconButton type="submit" onClick={handleSend}>
          <Send size={32} color={theme.colors.skyBase} />
        </IconButton>
      </ActionIconsWrap>
    </ChatInputWrap>
  )
}

export default withTheme(ChatInput)

ChatInput.propTypes = {
  theme: PropTypes.object.isRequired,
  handleSendMessage: PropTypes.func.isRequired
}
