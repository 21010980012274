import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DoneAll, Edit, HighlightOff, Mail } from '@styled-icons/material'
import { usePagination, useRowSelect, useTable } from 'react-table'
import { MetricsItem } from './styles'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import MetricItem from '../../../components/MetricItem/MetricItem'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap
} from '../../../global/styles'
import {
  useGetEmailTemplatesQuery,
  usePatchEmailTemplateByTemplateIdMutation
} from '../../../api/api.generated'
import EntityTable from '../../../components/EntityTable'
import EmailTemplatesTableRow from '../EmailTemplatesTableRow'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import EmailDetails from '../EmailDetails'
import ManageEmailModal from '../ManageEmailModal'
import IconButton from '../../../components/UI/Buttons/IconButton'

const tableColumns = [
  {
    Header: 'Event',
    accessor: 'emailName'
  },
  {
    Header: 'Subject',
    accessor: 'subject'
  },
  {
    Header: 'Intended for',
    accessor: 'intended'
  }
]

const ManageEmailTemplates = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [metrics, setMetrics] = useState([])
  const [activeEmailTemplateId, setActiveEmailTemplateId] = useState(null)
  const { data: templatesData, isLoading } = useGetEmailTemplatesQuery()
  const [patchTemplateData, { isLoading: patchingData }] =
    usePatchEmailTemplateByTemplateIdMutation()

  const getMetrics = useCallback(
    (data) => [
      {
        description: 'All templates',
        icon: <Mail />,
        iconBgColor: 'green3',
        isActive: false,
        metricName: 'Total templates count',
        numberAndIconColor: 'greenBase',
        onClick: () => {},
        value: data?.templatesCount ?? 0
      },
      {
        description: 'All active templates',
        icon: <DoneAll />,
        iconBgColor: 'skyBase3',
        isActive: false,
        metricName: 'Templates active',
        numberAndIconColor: 'skyBase',
        onClick: () => {},
        value: data?.activeTemplates ?? 0
      },
      {
        description: 'All inactive templates',
        icon: <HighlightOff />,
        iconBgColor: 'skyNeutral3',
        isActive: false,
        metricName: 'Templates Inactive',
        numberAndIconColor: 'skyNeutral',
        onClick: () => {},
        value: data?.inactiveTemplates ?? 0
      }
    ],
    []
  )

  const handleShowTemplateDetails = (templateId) => {
    if (templateId !== activeEmailTemplateId) {
      setShowDetails(true)
      setActiveEmailTemplateId(templateId)
    } else {
      setShowDetails(false)
      setActiveEmailTemplateId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveEmailTemplateId(null)
  }

  const columns = useMemo(() => tableColumns, [])
  const data = useMemo(
    () =>
      templatesData
        ? templatesData?.templates?.map((templateItem) =>
            EmailTemplatesTableRow(templateItem)
          )
        : [],
    [templatesData]
  )
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    headerGroups,
    prepareRow,
    state: { pageIndex },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.templateId,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection'
        },
        ...columnsList,
        {
          id: 'editIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setShowEdit(true)
                // eslint-disable-next-line react/prop-types
                setActiveEmailTemplateId(cell.row.id)
              }}
            >
              <Edit size={20} color="#A0A7B5" />
            </IconButton>
          )
        }
      ])
    }
  )

  const handleToggleManageTemplateModal = () => {
    setShowEdit((p) => !p)
  }
  const handleToggleEditing = () => {
    handleToggleManageTemplateModal()
  }

  const handleSaveTemplate = (values, templateId) => {
    if (templateId) {
      patchTemplateData({ body: values, templateId })
        ?.unwrap()
        .then(() => {
          handleToggleEditing()
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
  }
  useEffect(() => {
    if (templatesData) {
      setMetrics(getMetrics(templatesData))
    }
  }, [templatesData])

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader headline="Email Templates" noActions />
        {isLoading && <LoadingSpinner />}
        {templatesData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={() => {}}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <EntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows,
                pageOptions,
                pageIndex,
                gotoPage
              }}
              handleShowDetails={handleShowTemplateDetails}
              activeItemId={activeEmailTemplateId}
            />
          </>
        )}
      </DashboardInfoWrap>
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <EmailDetails
          {...{ activeEmailTemplateId }}
          toggleEditing={handleToggleEditing}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      <ManageEmailModal
        isOpen={showEdit}
        handleSaveTemplate={handleSaveTemplate}
        handleCloseModal={handleToggleEditing}
        isLoading={patchingData}
        templateId={activeEmailTemplateId}
      />
    </ManageEntityDashboardWrap>
  )
}

export default ManageEmailTemplates

ManageEmailTemplates.propTypes = {}
