import React from 'react'
import PropTypes from 'prop-types'
import ShopAsideWizard from '../../shop/ShopAsideWizard'
import DashboardActivities from '../DashboardActivities'
import DashboardMainSection from '../DashboardMainSection'
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary'
import { DashboardWrap } from './styles'

const ShopDashboard = ({shopId, isEditing}) => (
  <DashboardWrap>
    <ErrorBoundary>
      <DashboardMainSection shopId={shopId} />
    </ErrorBoundary>
    <ErrorBoundary>
      {isEditing ? <ShopAsideWizard /> : <DashboardActivities shopId={shopId} />}
    </ErrorBoundary>
  </DashboardWrap>
)

export default ShopDashboard

ShopDashboard.propTypes = {
  shopId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired
}
