/*eslint-disable*/
import React from 'react'
import { TableDarkText, TableText } from '../../../global/styles'

const BankTransferTableRow = (orderItem) => ({
  id: orderItem.id,
  date: <TableDarkText>{orderItem.createdAt}</TableDarkText>,
  shopName: <TableDarkText>{orderItem.shopName}</TableDarkText>,
  total: <TableText>${orderItem.total}</TableText>
})

export default BankTransferTableRow

BankTransferTableRow.propTypes = {}
