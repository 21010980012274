import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  BadgeWrap,
  ButtonWrap,
  PostDetailsHeader,
  PostImage,
  PostDetailsWrap
} from './styles'
import {
  usePostDeletePostsMutation,
  useGetShopPostQuery
} from '../../../api/shopPostsApi'
import LoadingSpinner from '../../../components/LoadingSpinner'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock
} from '../../../global/styles'
import Badge from '../../../components/UI/Badge'
import Button from '../../../components/UI/Buttons/Button/Button'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableImageLabelCell from '../../../components/TableImageLabelCell'
import { formatPostDeadline, getImageFromServer, getErrorMessage } from '../../../utils/helpers'

const PostDetails = ({ activePostId, closePanel }) => {
  const [deleteError, setDeleteError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const { data: postData, isLoading } = useGetShopPostQuery(activePostId)
  const [deletePost, { isLoading: deletingPost }] = usePostDeletePostsMutation()

  const handleDeletePost = () => {
    setDeleteError("");
    deletePost({ body: { postsIdsToDelete: [activePostId] } })
      ?.unwrap()
      .then(() => {
        closePanel()
        setShowConfirmDelete(false)
      })
      .catch((err) => {
        setDeleteError(getErrorMessage(err))
      })       
  }
  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {postData && (
        <>
          <PostDetailsHeader>
            {postData.postImage && (
              <PostImage
                src={getImageFromServer(postData.postImage)}
                alt="omg"
              />
            )}
            <AsideDetailsTitle align="left">
              {postData.postTitle}
            </AsideDetailsTitle>
          </PostDetailsHeader>

          <PostDetailsWrap>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Post Description
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {postData.postDescription}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Store</AsideDetailsLabel>
              <TableImageLabelCell
                image={postData.shopImage}
                label={postData.shopName}
                isRounded
              />
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Event</AsideDetailsLabel>
              <BadgeWrap>
                <Badge
                  size={BADGE_SIZES.SMALL}
                  variant={
                    postData.startTime && postData.endTime
                      ? BADGE_VARIANTS.PINK
                      : BADGE_VARIANTS.LIGHT
                  }
                  label={formatPostDeadline(
                    postData.startTime,
                    postData.endTime
                  )}
                />
              </BadgeWrap>
            </DetailsInfoBlock>
            {postData.code !== '' &&
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Code
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {postData.code}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            }
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Organisation Post
              </AsideDetailsLabel>
              <AsideDetailsDescription>
              <Badge 
                label={postData.isOrganizationPost ? 'Yes' : 'No'} 
                variant={postData.isOrganizationPost ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
              />
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Like</AsideDetailsLabel>
              <AsideDetailsDescription>{postData.likeCount}</AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Interested</AsideDetailsLabel>
              <AsideDetailsDescription>{postData.interestedCount}</AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Attended/Got Deal</AsideDetailsLabel>
              <AsideDetailsDescription>{postData.attendedCount}</AsideDetailsDescription>
            </DetailsInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={deletingPost}
                isFullWidth
              >
                Delete
              </Button>
            </ButtonWrap>
          </PostDetailsWrap>
          { showConfirmDelete &&
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={postData.postTitle}
            entityType="Post"
            handleDelete={handleDeletePost}
            error={deleteError}
          />
          }
        </>
      )}
    </>
  )
}

export default PostDetails
PostDetails.defaultProps = {
  activePostId: null
}
PostDetails.propTypes = {
  activePostId: PropTypes.string,
  closePanel: PropTypes.func.isRequired
}
