import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import {
  useLazyGetShopDonationQuery,  
  usePutShopDonationMutation
} from '../../../api/shopDonationsApi'
import { getErrorMessage } from '../../../utils/helpers'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import LoadingSpinner from '../../../components/LoadingSpinner'
import TextInput from '../../../components/UI/Inputs/TextInput'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import ShowErrorModal from '../../../components/ShowErrorModal'
import { ErrorMessageWrap } from '../../../global/styles'
import {
  InputsWrap,
  FormInputWrap,
  StyledForm,
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap
} from './styles'

const PRIORITY_OPTIONS = [
  { value: 0, label: 'Select Priority' },
  { value: 1, label: 'Not Needed' },
  { value: 2, label: 'Low' },
  { value: 3, label: 'Medium' },
  { value: 4, label: 'High' }
]

const emptyDonation = {
  height: '',
  width: '',
  length: '',
  priority_id: null
}

const ShopDonationModal = ({ isOpen, onClose, theme, editDonationId }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyDonation)
  const [selectedPriority, setSelectedPriority] = useState(0)
  const [error, setError] = useState('')
  const [getDonation, { data: shopDonation, error: getError }] = useLazyGetShopDonationQuery()
  const [updateShopDonation, { isLoading: updatingDonation }] = usePutShopDonationMutation()

  useEffect(() => {
    if (!isNull(editDonationId)) {
      getDonation(editDonationId)
    } else {
      setInitialData(emptyDonation)
      setSelectedPriority(0);
      setInitialData(emptyDonation)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopDonation) {
      setInitialData({
        height: shopDonation.height ?? '',
        width: shopDonation.width ?? '',
        length: shopDonation.length ?? '',
        priority_id: shopDonation.priorityId
      })
      setSelectedPriority(shopDonation.priorityId === null ? 0 : shopDonation.priorityId);
      setIsSuccess(true)
    }
  }, [shopDonation]) 

  const handleSubmit = (values) => {
    setError('')
    updateShopDonation({
      donationId: editDonationId,
      data: {
        ...values,
        priority_id: selectedPriority
      }
    })
    ?.unwrap()
    .then(() => {
      onClose()
    })
    .catch((err) => {
      setError(getErrorMessage(err))
    })    
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !getError && 
      <LoadingSpinner />
      }      
      {getError && 
        <ShowErrorModal
          title="Edit Donation"
          error="Network Error"
          onClose={onClose}
        />
      }  
      {isSuccess && 
        <Formik
          initialValues={initialData}
          onSubmit={(values) => {
            handleSubmit(values, editDonationId)
          }}
        >
          <StyledForm>
            <InputsWrap>
              <Typography variant="headingM" color={theme.colors.skyNeutral_2}>Edit Donation</Typography>
            </InputsWrap>
            <FormInputsWrap>
              <Typography variant="textS" color={theme.colors.skyNeutral_2}>Maximum Size</Typography>
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="height"
                label="Height"
                placeholder="Enter height"
              />
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="width"
                label="Width "
                placeholder="Enter width"
              />
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="length"
                label="Length"
                placeholder="Enter length "
              />
              <FormInputWrap>
                <Typography variant="textS" color={theme.colors.skyNeutral_2}>Priority</Typography>
                <SelectOneInput
                  options={PRIORITY_OPTIONS}
                  selected={selectedPriority}
                  setSelected={setSelectedPriority}
                  size=""
                />
              </FormInputWrap>
            </FormInputsWrap> 
            {error && 
              <ErrorMessageWrap>
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              </ErrorMessageWrap>          
            }                     
            <ActionButtonsSection>
              <ButtonWrap>
                {updatingDonation ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.MED}
                    >
                      Update
                    </Button>
                    <Button
                      type="button"
                      variant={BUTTON_VARIANTS.SECONDARY}
                      size={BUTTONS_SIZES.MED}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </ButtonWrap>
            </ActionButtonsSection>
          </StyledForm>
        </Formik>
      }
    </Modal>
  )
}

export default withTheme(ShopDonationModal)

ShopDonationModal.defaultProps = {
  editDonationId: null
}
ShopDonationModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editDonationId: PropTypes.number
}
