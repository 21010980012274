import styled from 'styled-components'
import { DetailsPanelTopSection, HStack, VStack } from '../../../global/styles'

export const PostDetailsWrap = styled(VStack)`
  width: 100%;
  height: 100%;
`

export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
`
export const PostDetailsHeader = styled(DetailsPanelTopSection)`
  align-items: flex-start;
  margin-bottom: 0;
`
export const PostImage = styled.img`
  display: block;
  width: 100%;
  border-radius: 6px;
`
export const BadgeWrap = styled(HStack)``
