import styled from 'styled-components'
import { Card, HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const DashboardActivitiesWrap = styled(Card)`
  padding: 24px 12px;
  flex-grow: 0;
  height: 100%;
  margin-left: auto;
  overflow: scroll;
  align-self: flex-start;
  display: flex;
  width: 336px;
  flex-direction: column;
  min-width: 336px;
`
export const ShopDetails = styled(VStack)`
  align-items: center;
  padding-bottom: 28px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.skyNeutral4};
`

export const ShopRecentActivity = styled(VStack)`
  padding-top: 24px;
`
export const ShopName = styled(Typography)`
  margin: 16px auto 8px auto;
`
export const ShopAddress = styled(Typography)`
  margin-bottom: 12px;
  font-weight: 500;
`
export const ActivityItem = styled(HStack)`
  padding: 12px 4px 16px 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
`

export const IconWrap = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.pinkBase};
  width: 32px;
  height: 32px;
  min-width: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-top: 4px;
`
export const ActivityDetails = styled(VStack)`
  margin-left: 12px;
`
