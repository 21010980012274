import React, { useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useTable } from 'react-table'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import { Search, Add,  DeleteOutline } from '@styled-icons/material'
import {
  selectOrganizationId
} from '../../../app/selectors'
import {
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationProductsQuery,
  useDeleteOrganizationProductMutation,
  usePostDeleteOrganizationProductsMutation,
  usePostOrganizationProductsToShopsMutation,
  useDeleteOrganizationProductsToShopsMutation
} from '../../../api/api.generated'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  Toolbar,
  ErrorMessageTopWrap,
  ButtonWrap
} from '../../../global/styles'
import { INPUT_SIZES, BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import TableCheckboxCell from '../../../components/TableCheckboxCell/TableCheckboxCell'
import Typography from '../../../components/UI/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableButtonCell from '../../../components/TableButtonCell'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import AddToStoresModal from '../../../components/AddToStoresModal'
import ViewOrganizationProductStoresModal from '../../../components/ViewOrganizationProductStoresModal'
import AddOrganizationProductModal from '../AddOrganizationProductModal'
import ProductDetails from '../ProductDetails'
import ProductTableRow from '../ProductsTableRow'
import { 
  HeaderButtonWrap,
  EntityDashboardHeadline,
  SectionName,
  AddButton
} from './styles'

const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Organisation',
    accessor: 'organizationName'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Shipping Cost',
    accessor: 'shippingCost'
  },
  {
    Header: 'Shipping',
    accessor: 'isShippingEnabled'
  },
  {
    Header: 'Click & Collect',
    accessor: 'isCollectEnabled'
  },
  {
    Header: 'Categories',
    accessor: 'tags'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }  
]
const ManageOrganizationProducts = () => {
  const organizationId = useSelector(selectOrganizationId)
  const [activeOrganizationId, setActiveOrganizationId] = useState(null)
  const [activeProductId, setActiveProductId] = useState(null)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [showDetails, setShowDetails] = useState(false)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showAddtoStoreModal, setShowAddtoStoreModal] = useState(false)
  const [showDeleteFromShopModal, setShowDeleteFromShopModal] = useState(false)
  const [showViewShopsModal, setShowViewShopsModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [error, setError] = useState('')
  const [storeError, setStoreError] = useState('')
  const [ getOrganizations, { data: organizationsData } ] = useLazyGetOrganizationsQuery()
  const [ getOrganizationProducts, { data: productsData, isLoading } ] = useLazyGetOrganizationProductsQuery()
  const [deleteProduct] = useDeleteOrganizationProductMutation()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteOrganizationProductsMutation()
  const [addProducts] = usePostOrganizationProductsToShopsMutation()
  const [deleteProducts] = useDeleteOrganizationProductsToShopsMutation()
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch] = useState('')

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      productsData
        ? productsData?.products?.map((product) => ProductTableRow(product))
        : [],
    [productsData]
  )

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  const handleShowShops = (productId) => {
    setDeleteOneId(productId)
    setShowViewShopsModal(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleShowDetails={handleShowShops}
              handleSetIdToDelete={setDeleteOneId}
              handleShowEditModal={setShowEditModal}
              handleShowConfirmModal={setShowConfirmDelete}
              canEdit
              showView
            />
          )
        }
      ])
    }
  )

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const loadProducts = (orgId) => {
    setError('')
    getOrganizationProducts({ organizationId: orgId, page: pageIndex, limit: pageSize, search })
      ?.unwrap()
      .catch((err) => {
        displayError(err)
      })
  }

  const handleChangeSelectedOrganization = (value) => {
    setActiveProductId(null)
    setShowDetails(false)
    setActiveOrganizationId(value)
    if (pageIndex === 0) {
      loadProducts(value)
    } else {
      gotoPage(0)
    }
  }
  
  const handleShowProductDetails = (productId) => {
    if (productId !== activeProductId) {
      setShowDetails(true)
      setActiveProductId(productId)
    } else {
      setShowDetails(false)
      setActiveProductId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveProductId(null)
  }

  const handleShowAddNewModal = () => {
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
  }

  const handleShowAddToStoreModal = () => {
    setStoreError('')
    setShowAddtoStoreModal(true)
  }

  const handleSubmitAddToStoreModal = (shopIds) => {
    addProducts({ body: {
      organizationId: activeOrganizationId,
      organizationProductIds: keys(selectedRowIds),
      shopIds
    } })
      ?.unwrap()
      .then(() => {
        // display message
        setShowAddtoStoreModal(false)
        toggleAllPageRowsSelected(false)
        toggleAllRowsSelected(false)
      })
      .catch((err) => {
        setStoreError(getErrorMessage(err))
      })
  }

  const handleCloseAddToStoreModal = () => {
    setShowAddtoStoreModal(false)
  }

  const handleShowDeleteFromShopModal = () => {
    setStoreError('')
    setShowDeleteFromShopModal(true)
  }

  const handleSubmitDeleteFromShopModal = (shopIds) => {
    deleteProducts({ body: {
      organizationId: activeOrganizationId,
      organizationProductIds: keys(selectedRowIds),
      shopIds
    } })
      ?.unwrap()
      .then(() => {
        // display message
        setShowDeleteFromShopModal(false)
        toggleAllPageRowsSelected(false)
        toggleAllRowsSelected(false)
      })
      .catch((err) => {
        setStoreError(getErrorMessage(err))
      })
  }

  const handleCloseDeleteFromShopModal = () => {
    setShowDeleteFromShopModal(false)
  }

  const handleCloseViewShopsModal = () => {
    setDeleteOneId(null)
    setShowViewShopsModal(false)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setDeleteOneId(null)
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }  

  const handleDeleteSelected = () => {
    handleCloseDetails()
    setDeleteOneId(null)
    setShowConfirmDelete(false)
    if (deleteOneId) {
      deleteProduct({ productId: deleteOneId })
        ?.unwrap()
        .then(() => {
          setError('')
        })
        .catch((err) => {
          displayError(err);
        })
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelectedIds({ body: { organizationProductIds: keys(selectedRowIds) } })
        ?.unwrap()
        .then(() => {
          setError('')
        })
        .catch((err) => {
          displayError(err);
        })
    }
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadProducts(activeOrganizationId)
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (organizationId) {
      setActiveOrganizationId(organizationId);
      loadProducts(organizationId);
    } else {
      getOrganizations()
        ?.unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })
    }
  }, [])

  useEffect(() => {
    if (productsData) {
      setTotalPages(productsData.pagination.pages)
      setTotalRows(productsData.pagination.total)
      if (pageIndex >= productsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [productsData])

  useEffect(() => {
    if (organizationsData && organizationsData.organizations.length > 0) {
      setActiveOrganizationId(organizationsData.organizations[0].id);
      loadProducts(organizationsData.organizations[0].id);
    }
  }, [organizationsData])

  useEffect(() => {
    if (productsData) {
      loadProducts(activeOrganizationId)
    }
  }, [pageIndex, pageSize])

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <EntityDashboardHeadline>
          <SectionName variant="headingM" bold>Manage Organisation Products</SectionName>
          <HeaderButtonWrap>
            {productsData && productsData.products.length > 0 &&
            <AddButton
              type="button"
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.SMALL}
              onClick={() => toggleAllPageRowsSelected(true)}
              isFullWidth={false}
            >
              Select Page
            </AddButton>            
            }
            {false && productsData && productsData.products.length > 0 &&
              <AddButton
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                onClick={() => toggleAllRowsSelected(true)}
                isFullWidth={false}
              >
                Select All
              </AddButton>
            }
            {productsData && productsData.products.length > 0 &&
              <AddButton
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                onClick={() => toggleAllRowsSelected(false)}
                isFullWidth={false}
              >
                Deselect All
              </AddButton>
            }  
            {productsData &&
              <AddButton
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                leftIcon={<Add />}
                onClick={handleShowAddNewModal}
                isFullWidth={false}
              >
                Add Product
              </AddButton>
            }
            {!(deletingItems || isEmpty(selectedRowIds)) && (
              <>
                <AddButton
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  leftIcon={<Add />}
                  onClick={handleShowAddToStoreModal}
                  isFullWidth={false}
                >
                  Add To Store
                </AddButton>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  leftIcon={<DeleteOutline />}
                  onClick={handleShowDeleteFromShopModal}
                  isFullWidth={false}
                  fillColor="#f44336"
                >
                  Delete from Store
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  leftIcon={<DeleteOutline />}
                  onClick={() => { setShowConfirmDelete(true) }}
                  isFullWidth={false}
                  fillColor="#f44336"
                >
                  Delete Selected
                </Button>
              </>
            )}
          </HeaderButtonWrap>
        </EntityDashboardHeadline>
        { isLoading && <LoadingSpinner />}
        {error && 
          <ErrorMessageTopWrap>
            <Typography variant="textS" color="red">{error}</Typography>
          </ErrorMessageTopWrap>
        }            
        {productsData && (
          <>
            <Toolbar>
              {!organizationId && organizationList && (
                <SelectOneInput
                  options={organizationList}
                  setSelected={handleChangeSelectedOrganization}
                  selected={activeOrganizationId}
                />
              )}                      
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search products by name"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>               
            </Toolbar>  
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowProductDetails}
              activeItemId={activeProductId}
            />
          </>
        )}
      </DashboardInfoWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <ProductDetails
          {...{ activeProductId }}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }
      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            productsData?.products,
            'name'
          )}
          entityType="Product"
          handleDelete={handleDeleteSelected}
        />
      )}
      {showAddNewModal && 
      <AddOrganizationProductModal
        isOpen={showAddNewModal}
        onClose={handleCloseAddNewModal}
        organizationId={activeOrganizationId}
        showOrganization={organizationId == null}
      />
      }
      {showEditModal && 
      <AddOrganizationProductModal
        isOpen={showEditModal}
        onClose={handleCloseEditModal}
        editProductId={deleteOneId}
        showOrganization={false}
      />
      } 
      {showAddtoStoreModal &&
      <AddToStoresModal
        title="Add Products To Stores"
        isOpen={showAddtoStoreModal}
        onSubmit={handleSubmitAddToStoreModal}
        onClose={handleCloseAddToStoreModal}
        organizationId={activeOrganizationId}
        error={storeError}
      />
      }
      {showDeleteFromShopModal &&
      <AddToStoresModal
        title="Delete Products From Stores"
        isOpen={showDeleteFromShopModal}
        onSubmit={handleSubmitDeleteFromShopModal}
        onClose={handleCloseDeleteFromShopModal}
        organizationId={activeOrganizationId}
        error={storeError}
      />
      }
      {showViewShopsModal && deleteOneId &&
      <ViewOrganizationProductStoresModal
        organizationId={activeOrganizationId}
        productId={deleteOneId}
        onClose={handleCloseViewShopsModal}
      />
      }
    </ManageEntityDashboardWrap>
  )
}

export default ManageOrganizationProducts

ManageOrganizationProducts.propTypes = {}
