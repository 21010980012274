import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import {
  ButtonWrap,
  ConfirmButton,
  Description,
  Headline,
  ModalHeader,
  ModalWrap,
  ErrorWrap
} from './styles'
import Button from '../UI/Buttons/Button/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'

const ToggleStatusModal = ({
  handleToggleStatus,
  isOpen,
  onClose,
  entityType,
  entityName,
  isCurrentlyActive,
  error
}) => (
  <Modal {...{ isOpen }}>
    <ModalWrap>
      <ModalHeader>
        <Headline bold>
          Are you Sure want {isCurrentlyActive ? 'Inactivate' : 'Activate'} this{' '}
          {entityType}
          {entityName}?
        </Headline>
        <Description>
          {isCurrentlyActive ? 'Inactivate' : 'Activate'} {entityName}{' '}
          {entityType}?
        </Description>
        {error && 
          <ErrorWrap>
            {error}
          </ErrorWrap>          
        }        
      </ModalHeader>
      <ButtonWrap>
        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          size={BUTTONS_SIZES.MED}
          onClick={onClose}
        >
          Cancel
        </Button>
        <ConfirmButton size={BUTTONS_SIZES.MED} onClick={handleToggleStatus}>
          {isCurrentlyActive ? 'Inactivate' : 'Activate'}
        </ConfirmButton>
      </ButtonWrap>
    </ModalWrap>
  </Modal>
)

export default ToggleStatusModal

ToggleStatusModal.defaultProps = {
  error: ""
}

ToggleStatusModal.propTypes = {
  handleToggleStatus: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  isCurrentlyActive: PropTypes.bool.isRequired,
  error: PropTypes.string,
}
