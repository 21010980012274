import React from 'react'
import { keys } from 'lodash/object'
import { capitalize } from 'lodash/string'
import PropTypes from 'prop-types'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsPanelTopSection
} from '../../../global/styles'
import EntityPicture from '../../../components/EntityPicture'
import Badge from '../../../components/UI/Badge'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import { getHourAMPM } from '../../../utils/helpers'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Button from '../../../components/UI/Buttons/Button'
import { useGetContactQuery } from '../../../api/api.generated'
import {
  ButtonWrap,
  DayItem,
  DayLabel,
  ScheduleWrap,
  ShopDetailsWrap,
  ShopInfoBlock,
  TagsWrap
} from './styles'

const ShopDetails = ({ activeShopId, onSendMessage }) => {
  const { data: shopData, isLoading } = useGetContactQuery(activeShopId)
  
  const handleSendMessage = () => {
    onSendMessage(activeShopId)
  }
  
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {shopData && (
        <>
          <DetailsPanelTopSection>
            <EntityPicture
              size={USER_AVATAR_SIZES.LARGE}
              image={shopData.logotype}
              entityName={shopData.shopName}
            />
            <AsideDetailsTitle align="center">{shopData.shopName}</AsideDetailsTitle>
          </DetailsPanelTopSection>
          <ShopDetailsWrap>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Location</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.displayAddress}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            { shopData.description &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Description</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.description}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { shopData.email &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Email</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.email}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { shopData.website &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Website</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.website}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { shopData.phone &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Phone</AsideDetailsLabel>
              <AsideDetailsDescription>
                +{shopData.phone}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { shopData.instagram &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Instagram</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.instagram}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            { shopData.twitter &&
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Twitter</AsideDetailsLabel>
              <AsideDetailsDescription>
                {shopData.twitter}
              </AsideDetailsDescription>
            </ShopInfoBlock>
            }
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Categories</AsideDetailsLabel>
              <TagsWrap>
                {shopData.tags.map((category) => (
                  <Badge
                    key={category}
                    variant={BADGE_VARIANTS.LIGHT}
                    sie={BADGE_SIZES.SMALL}
                    label={category}
                  />
                ))}
              </TagsWrap>
            </ShopInfoBlock>
            <ShopInfoBlock>
              <AsideDetailsLabel align="left">Hours Operational</AsideDetailsLabel>
              <ScheduleWrap>
                {keys(shopData.schedule).map((day) => (
                  <DayItem key={day}>
                    <DayLabel variant="textXS">{capitalize(day)}</DayLabel>
                    <Badge
                      variant={BADGE_VARIANTS.GREEN}
                      label={
                        shopData.schedule[day].isOpen
                          ? `${getHourAMPM(
                              shopData.schedule[day].open
                            ).toUpperCase()} - ${getHourAMPM(
                              shopData.schedule[day].close
                            ).toUpperCase()}`
                          : 'Closed'
                      }
                    />
                  </DayItem>
                ))}
              </ScheduleWrap>
            </ShopInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.MED}
                onClick={handleSendMessage}
              >
                Message
              </Button>
            </ButtonWrap>
          </ShopDetailsWrap>
        </>
      )}
    </>
  )
}

export default ShopDetails

ShopDetails.defaultProps = {}

ShopDetails.propTypes = {
  activeShopId: PropTypes.string.isRequired,
  onSendMessage: PropTypes.func.isRequired,
}
