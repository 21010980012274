import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { keys } from 'lodash/object'
import { IconWrap } from './styles'
import { ORGANIZATION_ICONS } from '../../../assets/OrganizationIcon/OrganizationIcon'

const SelectMarker = ({ icon, name }) => {
  const [field, meta] = useField({ name })
  return (
    <IconWrap htmlFor={icon} isActive={icon === meta.value}>
      {React.cloneElement(ORGANIZATION_ICONS[icon], {
        size: 30,
        color: '#A0A7B5'
      })}
      <input {...field} value={icon} type="radio" id={icon} hidden />
    </IconWrap>
  )
}

export default SelectMarker

SelectMarker.propTypes = {
  icon: PropTypes.oneOf(keys(ORGANIZATION_ICONS)).isRequired,
  name: PropTypes.string.isRequired
}
