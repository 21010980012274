import { api as apiSlice} from './api.generated'

const shopTagsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShopTag: build.query({
      query: (tagId) => ({ url: `/shop/tags/${tagId}` }),
      providesTags: ['ShopTag']
    }),    
    putShopTag: build.mutation({
      query: ({ tagId, data }) => ({
        url: `/shop/tags/${tagId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopTags', 'ShopTag', 'ShopDetails']
    })
  }),

  overrideExisting: true
})

export const {
  useGetShopTagQuery,
  usePutShopTagMutation
} = shopTagsApi
