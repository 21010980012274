import React from 'react'
import { useParams } from 'react-router-dom'
import ShopDonations from '../../shop/ShopDonations'

const ManageShopDonations = () => {
  const { shopId } = useParams()

  return (
    <ShopDonations shopId={shopId}/>
  )
}

export default ManageShopDonations

ManageShopDonations.propTypes = {}
