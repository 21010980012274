import React, { useEffect, useMemo, useState } from 'react'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable
} from 'react-table'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import { Search } from '@styled-icons/material'
import {
  useLazyGetUsersQuery,
  usePostDeleteUsersMutation
} from '../../../api/api.generated'
import { INPUT_SIZES, BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import { getMetrics, tableColumns } from '../helpers/customersHelpers'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import MetricItem from '../../../components/MetricItem/MetricItem'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button/Button'
import TableCheckboxCell from '../../../components/TableCheckboxCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableDeleteCell from '../../../components/TableDeleteCell'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import { Toolbar } from '../../shops/ManageShops/styles'
import CustomerDetails from '../CustomerDetails'
import CustomersTableRow from '../CustomersTableRow'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  ButtonWrap
} from '../../../global/styles'
import { MetricsItem } from './styles'

const ManageCustomers = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [metrics, setMetrics] = useState([])
  const [activeCustomerId, setActiveCustomerId] = useState(null)
  const [error, setError] = useState('')
  const [getUsers, { data: customersData, isLoading }] = useLazyGetUsersQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteUsersMutation()
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch] = useState('')

  const handleShowCustomerDetails = (customerId) => {
    if (customerId !== activeCustomerId) {
      setShowDetails(true)
      setActiveCustomerId(customerId)
    } else {
      setShowDetails(false)
      setActiveCustomerId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveCustomerId(null)
  }

  const columns = useMemo(() => tableColumns, [])
  const data = useMemo(
    () =>
      customersData
        ? customersData?.users?.map((customer) => CustomersTableRow(customer))
        : [],
    [customersData]
  )
  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.customerId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,      
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
  
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableDeleteCell
              cell={cell}
              handleShowConfirmModal={setShowConfirmDelete}
              handleSetIdToDelete={setDeleteOneId}
            />
          )
        }
      ])
    }
  )

  const loadUsers = () => {
    setError('')
    getUsers({ page: pageIndex, limit: pageSize, search })
      ?.unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleDeleteSelected = () => {
    handleCloseDetails()
    setDeleteOneId(null)
    setShowConfirmDelete(false)
    if (deleteOneId) {
      deleteSelectedIds({ body: { usersIdsToDelete: [deleteOneId] } })
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelectedIds({ body: { usersIdsToDelete: keys(selectedRowIds) } })
    }
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadUsers()
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    loadUsers();
  }, [])

  useEffect(() => {
    if (customersData) {
      setTotalPages(customersData.pagination.pages)
      setTotalRows(customersData.pagination.total)
      if (pageIndex >= customersData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [customersData])

  useEffect(() => {
    if (customersData) {
      setMetrics(getMetrics(customersData.dashboardInfo))
    }
  }, [customersData])

  useEffect(() => {
    if (customersData) {
      loadUsers()
    }
  }, [pageIndex, pageSize]);

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Manage Customers"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }
        {customersData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={metric.onClick}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <Toolbar>
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search user by name, email or phone number"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>              
            </Toolbar>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows               
              }}
              handleShowDetails={handleShowCustomerDetails}
              activeItemId={activeCustomerId}
            />
          </>
        )}
      </DashboardInfoWrap>
      {showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <CustomerDetails
          {...{ activeCustomerId }}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }
      {customersData && (!isEmpty(selectedRowIds) || deleteOneId) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            customersData?.users,
            'userName'
          )}
          entityType="Customer"
          handleDelete={handleDeleteSelected}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManageCustomers

ManageCustomers.propTypes = {}
