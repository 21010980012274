/*eslint-disable*/
import React, { useRef } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ButtonWrap, Headline, ModalBody } from './styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button/Button'
import { Editor } from '@tinymce/tinymce-react'
import { useGetEmailTemplatesQuery } from '../../../api/api.generated'

const ManageEmailModal = ({
  isOpen,
  handleCloseModal,
  handleSaveTemplate,
  isLoading,
  templateId
}) => {
  const editorRef = useRef(null)
  const { selectedTemplate } = useGetEmailTemplatesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      selectedTemplate: data?.templates?.find(
        (template) => template?.id === templateId
      )
    })
  })

  return (
    <Modal {...{ isOpen }}>
      <ModalBody>
        <Formik
          initialValues={{
            event: selectedTemplate?.event,
            intended: selectedTemplate?.intended,
            subject: selectedTemplate?.subject,
            message: selectedTemplate?.message
          }}
          validationSchema={Yup.object({
            subject: Yup.string().required('Subject is required')
          })}
          onSubmit={(values) => {
            handleSaveTemplate(values, templateId)
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <Headline>Edit Template</Headline>
              <TextInput
                label="Template Name"
                placeholder="Enter template name"
                inputName="event"
                disabled
                size={INPUT_SIZES.SMALL}
              />
              <TextInput
                label="Intended for"
                inputName="intended"
                disabled
                size={INPUT_SIZES.SMALL}
              />
              <TextInput
                label="Subject"
                placeholder="Enter subject email"
                inputName="subject"
                size={INPUT_SIZES.SMALL}
              />
              <Editor
                apiKey="s7masfo7wg3nwzpvekj1hx89jex7i5npvhxddk6zb83deiam"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={selectedTemplate?.message}
                onEditorChange={(value) => {
                  setFieldValue('message', value)
                }}
                init={{
                  height: 200,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={handleCloseModal}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isLoading}
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  type="submit"
                >
                  Save Changes
                </Button>
              </ButtonWrap>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default ManageEmailModal

ManageEmailModal.defaultProps = {
  templateId: ''
}
ManageEmailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveTemplate: PropTypes.func.isRequired,
  templateId: PropTypes.any
}
