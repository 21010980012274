import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopQuestions from '../ShopQuestions'

const ShopProfileQuestions = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopQuestions shopId={shopId} />
  )
}

export default ShopProfileQuestions

ShopProfileQuestions.propTypes = {}
