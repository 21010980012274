import React from 'react'
import { TableText } from '../../../global/styles'
import { formatDate, truncateString } from '../../../utils/helpers'

const DonationTableRow = (donation) => ({
  id: donation.id,
  date: (
    <TableText>{formatDate(donation.date)}</TableText>
  ),  
  amount: (
    <TableText>${donation.amount}</TableText>
  ),  
  note: (
    <TableText>{truncateString(donation.note, 50)}</TableText>
  ),
  shop: (
    <TableText>{donation.shopName}</TableText>
  ),  
  user: (
    <TableText>{donation.userName}</TableText>
  ),  
  forSearch: `${donation.note}`
})

export default DonationTableRow

DonationTableRow.propTypes = {}
