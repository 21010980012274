import styled from 'styled-components'
import { Form } from 'formik'
import { VStack } from '../../global/styles'

export const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`
export const TitleWrap = styled(VStack)`
  padding: 32px 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const ActionButtonsSection = styled.div`
  display: flex;
  padding: 0 32px 32px 32px;
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > :first-child {
    margin-right: 12px;
  }  
`

