import React from 'react'
import PropTypes from 'prop-types'
import { AuthContainer, AuthFormContentWrap } from './styles'
import AuthScreensAsideGraphics from '../../../components/AuthScreensAsideGraphics'

const AuthHoc = ({ children }) => (
  <AuthContainer>
    <AuthFormContentWrap jusitify="flex-start">
      {/* auth forms goes here as children */}
      {children}
    </AuthFormContentWrap>
    <AuthScreensAsideGraphics />
  </AuthContainer>
)

export default AuthHoc

AuthHoc.propTypes = {
  children: PropTypes.node.isRequired
}
