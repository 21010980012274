import styled from 'styled-components/macro'

export const MainNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;
  justify-content: flex-start;
`
export const MainMenu = styled.div`
  width: 213px;
  min-width: 213px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0 11px 0;
  flex-grow: 0;
  background: #2d6ce4;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 2;
  max-height: 100%;
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  min-height: 100%;
`

export const LogoutItem = styled.div`
  margin-top: auto;
  width: 100%;
`
export const LogoWrap = styled.div`
  display: flex;
  padding: 36px 0 23px 0;
  justify-content: center;
`
