import React, { useEffect, useMemo, useState } from 'react'
import {
  useTable,
  useRowSelect,
  usePagination,
  useGlobalFilter
} from 'react-table'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Search } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import ROUTES from '../../../const/routes'
import {
  selectOrganizationId,
  selectCitiesSelectOptions,
  selectCurrentCityId
} from '../../../app/selectors'
import {
  useGetCitiesQuery,
  useLazyGetShopsQuery,
  usePostDeleteShopsMutation,
  usePostDeactivateShopsMutation
} from '../../../api/api.generated'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import MetricItem from '../../../components/MetricItem'
import LoadingSpinner from '../../../components/LoadingSpinner'
import TableCheckboxCell from '../../../components/TableCheckboxCell'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import TableButtonCell from '../../../components/TableButtonCell'
import Button from '../../../components/UI/Buttons/Button'
import ShopDetails from '../ShopDetails'
import ShopDashboardHeader from '../ShopDashboardHeader'
import ShopTableRow from '../ShopTableRow'
import EditShopModal from '../EditShopModal'
import EditShopTagModal from '../EditShopTagModal'
import AddShopModal from '../AddShopModal'
import { setSelectedCityId } from '../shopSlice'
import { getMetrics, tableColumns } from '../helpers/shopsHelpers'
import { ErrorMessageTopWrap } from '../../../global/styles'
import { 
  MetricsItem, 
  ShopsDashboardWrap, 
  ShopsWrap, 
  Toolbar,
  ButtonWrap
} from './styles'

const ManageShops = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const organizationId = useSelector(selectOrganizationId)
  const citiesSelectOptions = useSelector(selectCitiesSelectOptions)
  const currentCityId = useSelector(selectCurrentCityId)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState('')
  const [deactivateError, setDeactivateError] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [metrcis, setMetrics] = useState([])
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showConfirmDeactivate, setShowConfirmDeactivate] = useState(false)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [activeShopId, setActiveShopId] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditTagModal, setShowEditTagModal] = useState(false)
  const [getCityShops, { data: shopsTableData, isLoading }] = useLazyGetShopsQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteShopsMutation()
  const [deactivateSelectedIds, { isLoading: deactivatingItems }] = usePostDeactivateShopsMutation()
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState('');

  useGetCitiesQuery()
  
  const handleShowShopDetails = (shopId) => {
    if (shopId !== activeShopId) {
      setShowDetails(true)
      setActiveShopId(shopId)
    } else {
      setShowDetails(false)
      setActiveShopId(null)
    }
  }

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      shopsTableData
        ? shopsTableData?.shops?.map((shopItem) => ShopTableRow(shopItem))
        : [],
    [shopsTableData]
  )

  const handleShowShop = (shopId) => {
    history.push(generatePath(ROUTES.SHOP, { shopId }))
  }

  const handleShowDashboard = (shopId) => {
    history.push(generatePath(ROUTES.SHOP_DASHBOARD, { shopId }))
  }

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.shopId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,    
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'buttons',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleShowDetails={handleShowShop}
              handleShowDashboard={handleShowDashboard}
              canEdit={false}
              showView
              showDashboard
              showDelete={false}
            />
          )
        }        
      ])
    }
  )

  const handleClosePanel = () => {
    setShowDetails(false)
  }
  
  const handleAfterDeleteCleanUp = () => {
    handleClosePanel()
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeactivateError('');
  }
  
  const handleDeleteSelected = () => {
    if (!isEmpty(selectedRowIds)) {
      setDeactivateError('');
      setActiveShopId(null)
      setShowDetails(false)
      deleteSelectedIds({ body: { shopsIdsToDelete: keys(selectedRowIds) } })
        ?.unwrap()
        .then(() => {
          handleAfterDeleteCleanUp()
        })
        .catch((err) => {
          setDeactivateError(getErrorMessage(err));
        })        
    }
  }

  const handleAfterDeactivateCleanUp = () => {
    handleClosePanel()
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDeactivate(false)
    setDeactivateError('');
  }

  const handleDeactivateSelected = () => {
    if (!isEmpty(selectedRowIds)) {
      setDeactivateError('');
      setActiveShopId(null)
      setShowDetails(false)
      deactivateSelectedIds({ body: { shopsIds: keys(selectedRowIds) } })
        ?.unwrap()
        .then(() => {
          handleAfterDeactivateCleanUp()
        })
        .catch((err) => {
          setDeactivateError(getErrorMessage(err));
        })
    }
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  const handleCloseEditTagModal = () => {
    setShowEditTagModal(false)
  }

  const handleChangeSelectedCity = (value) => {
    dispatch(setSelectedCityId(value))
    handleClosePanel()
  }
  
  const handleShowAddNewModal = () => {
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
  }

  const loadShops = () => {
    setError('')
    getCityShops({ cityId: currentCityId, search, page: pageIndex, limit: pageSize })
      ?.unwrap()
      .then(() => {
        setIsSuccess(true)
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
  }

  const handleSearch = () => {
    if (currentCityId) {
      if (pageIndex === 0) {
        loadShops()
      } else {
        gotoPage(0)
      }
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (shopsTableData) {
      setMetrics(getMetrics(shopsTableData?.dashboardInfo))
      setTotalPages(shopsTableData.pagination.pages)
      setTotalRows(shopsTableData.pagination.total)
      if (pageIndex >= shopsTableData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [shopsTableData])
  
  useEffect(() => {
    handleSearch()
  }, [currentCityId])

  useEffect(() => {
    loadShops()
  }, [pageIndex, pageSize]);

  return (
    <ShopsDashboardWrap>
      <ShopsWrap>
        <ShopDashboardHeader
          headline="Manage Stores"
          handleDelete={() => { 
            setShowConfirmDelete(true)  
            setDeactivateError("")
          }}
          handleEdit={() => { setShowEditModal(true)  }}
          handleEditTag={() => { setShowEditTagModal(true)  }}
          handleDeactivate={() => { 
            setShowConfirmDeactivate(true)
            setDeactivateError("")
          }}
          handleAddNew={handleShowAddNewModal}
          noActions={deletingItems || deactivatingItems || isEmpty(selectedRowIds)}
          showAdd={isSuccess}
          showEditTag={isSuccess}
        />
        {isLoading && <LoadingSpinner />}
        {error &&
          <ErrorMessageTopWrap>
            <Typography variant="textS" color="red">{error}</Typography>
          </ErrorMessageTopWrap>
        }          
        {shopsTableData && (
          <>
            <MetricsItem>
              {metrcis.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <Toolbar>
              {citiesSelectOptions && (
                <SelectOneInput
                  options={citiesSelectOptions}
                  setSelected={handleChangeSelectedCity}
                  selected={currentCityId}
                />
              )}
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  // setGlobalFilter(e.target.value)
                  setSearch(e.target.value)
                }}
                placeholder="Search store by name or address"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                isWhite
                leftIcon={<Search />}
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>           
            </Toolbar>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows 
              }}
              handleShowDetails={handleShowShopDetails}
              activeItemId={activeShopId}
            />
          </>
        )}
      </ShopsWrap>

      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleClosePanel}>
        <ShopDetails
          {...{ activeShopId }}
          afterDeleteCleanUp={handleAfterDeleteCleanUp}
        />
      </DetailsAsidePanel>
      }

      {showAddNewModal && 
      <AddShopModal
        isOpen={showAddNewModal}
        onClose={handleCloseAddNewModal}
        organizationId={organizationId}
      />
      }
      
      {shopsTableData && !isEmpty(selectedRowIds) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={() => {
            setShowConfirmDelete(false)
          }}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            null,
            shopsTableData?.shops,
            'shopName'
          )}
          entityType="Store"
          handleDelete={handleDeleteSelected}
          error={deactivateError}
        />
      )}
      
      {showConfirmDeactivate && !isEmpty(selectedRowIds) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDeactivate}
          onClose={() => {
            setShowConfirmDeactivate(false)
          }}          
          entityName=""
          entityType=""
          handleDelete={handleDeactivateSelected}
          headline="Deactivate"
          description="Are you sure you want to deactivate store(s)?"
          error={deactivateError}
          isLoading={deactivatingItems}
        />
      )}

      {showEditModal && 
      <EditShopModal
        isOpen={showEditModal}
        handleCloseModal={handleCloseEditModal}
        shopIds={keys(selectedRowIds)}
      />
      }
      {showEditTagModal && 
      <EditShopTagModal
        isOpen={showEditTagModal}
        handleCloseModal={handleCloseEditTagModal}
        shopIds={keys(selectedRowIds)}
      />
      }      
    </ShopsDashboardWrap>
  )
}

export default ManageShops

ManageShops.propTypes = {}
