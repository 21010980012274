import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const ContentWrap = styled(VStack)`
  width: 100%;
  padding: 32px;
`

export const HeadlineWrap = styled(HStack)`
  margin-bottom: 32px;
  justify-content: space-between;
  width: 100%;
  height: 44px;
`
