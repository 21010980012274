import React from 'react'
import { PeopleAlt, Unpublished } from '@styled-icons/material'

export const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber'
  },
  {
    Header: 'Email address',
    accessor: 'email'
  },
  {
    Header: 'Store',
    accessor: 'shopName'
  },
  {
    Header: 'Organisation',
    accessor: 'organizationName'
  },
  {
    Header: 'Role',
    accessor: 'roleName'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }
]

export const getMetrics = (data) => [
  {
    description: '',
    icon: <PeopleAlt />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Active',
    numberAndIconColor: 'greenBase',
    onClick: () => {},
    value: data?.activeUsers ? `${data.activeUsers}` : '0'
  },
  {
    description: '',
    icon: <Unpublished />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Non-active',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: data?.inactiveUsers ? `${data.inactiveUsers}` : '0'
  }
]
