import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopHolidays from '../ShopHolidays'

const ShopProfileHolidays = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopHolidays shopId={shopId} />
  )
}

export default ShopProfileHolidays

ShopProfileHolidays.propTypes = {}
