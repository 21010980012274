import React, { useMemo } from 'react'
import { values } from 'lodash/object'
import PropTypes from 'prop-types'
import { random } from 'lodash/number'
import { Status, UserAvatarWrap, UserInitials } from './styles'
import { USER_AVATAR_SIZES } from '../../../const/UIvariants'
import { globalTheme } from '../../../global/theme'

const UserAvatar = ({ size, userName, isOnline }) => {
  const getBgColor = useMemo(() => random(0, 5), [])
  const getUserInitials = (initUserName) =>
    initUserName
      ?.split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase()

  return (
    <UserAvatarWrap {...{ size }} bgVariant={getBgColor}>
      <UserInitials variant="textXS" color={globalTheme.colors.white}>
        {getUserInitials(userName)}
      </UserInitials>
      {isOnline && <Status {...{ size }} />}
    </UserAvatarWrap>
  )
}

export default UserAvatar

UserAvatar.defaultProps = {
  size: USER_AVATAR_SIZES.SMALL,
  isOnline: false
}

UserAvatar.propTypes = {
  size: PropTypes.oneOf(values(USER_AVATAR_SIZES)),
  userName: PropTypes.string.isRequired,
  isOnline: PropTypes.bool
}
