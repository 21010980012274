import React, { useEffect, useMemo, useState } from 'react'
import { useTable, useRowSelect, usePagination, useGlobalFilter } from 'react-table'
import Modal from 'react-modal'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import PropTypes from 'prop-types'
import { Search } from '@styled-icons/material'
import {
  INPUT_SIZES,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../const/UIvariants'
import { 
  TableDarkText,
  Toolbar,
  SearchButtonWrap
} from '../../global/styles'
import LoadingSpinner from '../LoadingSpinner'
import TableCheckboxCell from '../TableCheckboxCell'
import PaginatedEntityTable from '../EntityTable/PaginatedEntityTable'
import CustomTextInput from '../UI/Inputs/TextInput/CustomTextInput'
import Typography from '../UI/Typography'
import Button from '../UI/Buttons/Button'
import TableImageLabelCell from '../TableImageLabelCell'
import { 
  ModalBody,
  Headline,
  InputsWrapper,
  ErrorWrap,
  ButtonWrap,
} from './styles'

const ViewStoresModal = ({ title, shopsTableData, isLoading, error, onLoad, onDelete, onClose }) => {
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch] = useState('')

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'shopName'
    },
    {
      Header: 'Location',
      accessor: 'location'
    },
    {
      Header: 'forSearch',
      accessor: 'forSearch'
    }
  ]

  const ShopTableRow = (shopItem) => ({
    shopId: shopItem.id,
    shopName: (
      <TableImageLabelCell
        image={shopItem.logotype}
        bgColor={shopItem.avatarBackground}
        label={shopItem.shopName}
        isRounded
      />
    ),
    location: <TableDarkText>{shopItem.displayAddress}</TableDarkText>,
    forSearch: `${shopItem.displayAddress} ${shopItem.shopName}`
  })

  const columns = React.useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      shopsTableData
        ? shopsTableData?.shops?.map((shopItem) => ShopTableRow(shopItem))
        : [],
    [shopsTableData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.shopId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'last',
        }
      ])
    }
  )
 
  const loadStores = () => {
    onLoad(pageIndex, pageSize, search)
  }

  const handleDelete = () => {
    if (!isEmpty(selectedRowIds)) {
      onDelete(keys(selectedRowIds))
    }
  } 

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadStores()
    } else {
      gotoPage(0)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    loadStores();
  }, [])

  useEffect(() => {
    if (shopsTableData) {
      setTotalPages(shopsTableData.pagination.pages)
      setTotalRows(shopsTableData.pagination.total)
      if (pageIndex >= shopsTableData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [shopsTableData])

  useEffect(() => {
    if (shopsTableData) {
      loadStores()
    }
  }, [pageIndex, pageSize])

  return (
    <Modal isOpen>
      <ModalBody>
          <Headline>{title}</Headline>
          {isLoading && <LoadingSpinner />}
          {shopsTableData && (
            <>
              <Toolbar>
                <CustomTextInput
                  handleKeyPress={handleKeyPress}
                  handleChange={(e) => {
                    setSearch(e.target.value)
                  }}
                  placeholder="Search store by name or address"
                  inputName="search"
                  size={INPUT_SIZES.SMALL}
                  value={search}
                  leftIcon={<Search />}
                  isWhite={false}
                />
                <SearchButtonWrap>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.SMALL}
                    onClick={() => handleSearch()}
                  >
                    Search
                  </Button>
                </SearchButtonWrap>               
              </Toolbar>
              <InputsWrapper>
                <PaginatedEntityTable
                  {...{
                    getTableProps,
                    headerGroups,
                    getTableBodyProps,
                    page,
                    prepareRow,
                    rows: data,
                    pageOptions,
                    pageIndex,
                    gotoPage,
                    totalRows
                  }}
                />
              </InputsWrapper>
            </>
          )}
          {error && 
            <ErrorWrap>
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            </ErrorWrap>          
          }
          <ButtonWrap>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              onClick={handleDelete}
              type="button"
              disabled={isEmpty(selectedRowIds)}
            >
              Delete
            </Button>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={BUTTONS_SIZES.MED}
              onClick={onClose}
              type="button"
            >
              Cancel
            </Button>
          </ButtonWrap>
      </ModalBody>
    </Modal>
  )
}

export default ViewStoresModal

ViewStoresModal.defaultProps = {
  shopsTableData: null
}

ViewStoresModal.propTypes = {
  title: PropTypes.string.isRequired,
  shopsTableData: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
