import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Lock,
  RemoveRedEye,
  Verified,
  VisibilityOff
} from '@styled-icons/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { AuthFormWrap, AuthWelcomeMessage } from '../../../global/styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import { BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import OphopLogo from '../../../assets/icons/OphopLogo'
import { SuccessHeading, SuccessText, TextWrap } from './styles'
import ROUTES from '../../../const/routes'
import { setWelcomeMessage } from '../authSlice'
import { useCreateNewPasswordMutation } from '../../../api/api.generated'

const CreateNewPassword = ({ theme }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [resetToken, setResetToken] = useState('')
  const [createNewPassword] = useCreateNewPasswordMutation()
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    setResetToken(params.get('token'))
    setEmail(params.get('email'))
  }, [])
  const submitNewPassword = (values) => {
  
    createNewPassword({
      password: values.password,
      token: resetToken,
      email
    })?.unwrap()
    .then(() => {
      dispatch(setWelcomeMessage('Password Changed'))
      setIsSuccess(true)
    })
    .catch(() => {
      dispatch(setWelcomeMessage('Something went wrong'))
    })
  }

  const [passwordInputType, setPasswordInputType] = useState('password')
  const handleShowPassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text')
      return
    }
    setPasswordInputType('password')
  }
  useEffect(() => {
    dispatch(setWelcomeMessage('Forgot password for'))
  }, [])


  const handleFinishCreateAccount = () => {
    history.push(ROUTES.LOGIN)
  }
  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
        email,
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, 'Password must contain at least 8 characters')
          .required('Required'),
        confirmPassword: Yup.string()
          .required('Please confirm your password')
          .when('password', {
            is: (password) => password && password.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password')],
              "Password doesn't match"
            )
          })
      })}
      onSubmit={submitNewPassword}
    >
      <AuthFormWrap>
        {isSuccess ? (
          <>
            <Verified color={theme.colors.greenBase} size={120} />
            <TextWrap>
              <OphopLogo width={168} height={43} fill={theme.colors.skyBase} />
              <SuccessHeading variant="headingXL">
                Your Password has been change, Enjoy your day!
              </SuccessHeading>
              <SuccessText variant="textL">
                Your Password has been change, Enjoy your day!
              </SuccessText>
            </TextWrap>
            <Button
              isLoading={false}
              size={BUTTONS_SIZES.LARGE}
              type="button"
              onClick={handleFinishCreateAccount}
            >
              Login to account
            </Button>
          </>
        ) : (
          <>
            <OphopLogo fill={theme.colors.skyBase} />
            <AuthWelcomeMessage
              variant="textM"
              color={theme.colors.skyNeutral_1}
            >
              Create your new password
            </AuthWelcomeMessage>
            <TextInput
              type={passwordInputType}
              placeholder="Enter your password"
              leftIcon={<Lock />}
              rightIcon={
                passwordInputType === 'password' ? (
                  <RemoveRedEye />
                ) : (
                  <VisibilityOff />
                )
              }
              rightIconClick={handleShowPassword}
              size={INPUT_SIZES.LARGE}
              inputName="password"
              label="Password"
            />
            <TextInput
              type="password"
              placeholder="Confirm your password"
              leftIcon={<Lock />}
              size={INPUT_SIZES.LARGE}
              inputName="confirmPassword"
              label="Confirm Password"
            />
            <Button isLoading={false} size={BUTTONS_SIZES.LARGE} type="submit">
              Change Password
            </Button>
          </>
        )}
      </AuthFormWrap>
    </Formik>
  )
}

export default withTheme(CreateNewPassword)

CreateNewPassword.propTypes = {
  theme: PropTypes.object.isRequired
}
