import React from 'react'
import PropTypes from 'prop-types'
import { TagIconWrap, TagNameWrap } from './styles'
import { TableDarkText } from '../../global/styles'
import { TAG_ICONS } from '../../const/app'

const TableTagCell = ({ icon, label, bgColor }) => (
  <TagNameWrap>
    <TagIconWrap backgroundColor={bgColor}>
      {React.cloneElement(TAG_ICONS[icon], {
        width: 18,
        height: 18,
        color: '#ffffff'
      })}
    </TagIconWrap>
    <TableDarkText>{label}</TableDarkText>
  </TagNameWrap>
)

export default TableTagCell

TableTagCell.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired
}
