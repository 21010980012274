import React from 'react'
import PropTypes from 'prop-types'
import { WeekDay } from './styles'

import { HStack } from '../../global/styles'
import { WEEK_DAYS } from '../../const/app'

const ShopTableOpenDays = ({ shopSchedule }) => (
  <HStack justify="flex-start" alignItems="center">
    {WEEK_DAYS.map((day) => (
      <WeekDay key={day} isOpen={shopSchedule[day].isOpen}>
        {day[0].toUpperCase()}
      </WeekDay>
    ))}
  </HStack>
)

export default ShopTableOpenDays

ShopTableOpenDays.propTypes = {
  shopSchedule: PropTypes.object.isRequired
}
