import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const DashboardStatisticsWrap = styled(VStack)`
  width: 100%;
  margin-bottom: 20px;
`
export const MetricsWrap = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`
export const MetricItem = styled(VStack)`
  min-width: 31%;
  margin-right: 2%;
  height: 135px;
  padding: 16px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 6px 16px -4px rgba(15, 18, 20, 0.1);
  &:nth-child(3n) {
    margin-right: 0;
  }
`
export const MetricLabel = styled(Typography)`
  margin-bottom: 9px;
`
export const IconWrap = styled(VStack)`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  background-color: ${({ bgColor }) => bgColor};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`
