import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  BadgeWrap,
  ButtonWrap,
  PostDetailsHeader,
  PostDetailsWrap
} from './styles'
import {
  useGetOrganizationHolidayQuery,
  useDeleteOrganizationHolidayMutation
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import {
  BADGE_SIZES,
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock
} from '../../../global/styles'
import { formatDate } from '../../../utils/helpers'
import Badge from '../../../components/UI/Badge'
import Button from '../../../components/UI/Buttons/Button/Button'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import Typography from '../../../components/UI/Typography'

const HolidayDetails = ({ activeHolidayId, closePanel }) => {
  const [error, setError] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const { data: holidayData, isLoading, error: isHolidayError } = useGetOrganizationHolidayQuery(activeHolidayId)
  const [deleteHoliday, { isLoading: deletingHoliday }] = useDeleteOrganizationHolidayMutation()

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const toggleConfirmationModal = () => {
    setShowConfirmDelete((prev) => !prev)
  }

  const handleDeleteHoliday = () => {
    deleteHoliday({ holidayId: activeHolidayId })
      ?.unwrap()
      .then(() => {
        closePanel()
      })
      .catch((err) => {
        toggleConfirmationModal()
        displayError(err);
      })      
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {isHolidayError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {holidayData && (
        <>
          <PostDetailsHeader>
            <AsideDetailsTitle align="center">
              {holidayData.name}
            </AsideDetailsTitle>
          </PostDetailsHeader>

          <PostDetailsWrap>
            <AsideDetailsTitle>Details</AsideDetailsTitle>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
              Organisation
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {holidayData.organizationName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">From</AsideDetailsLabel>
              <BadgeWrap>
                <Badge
                  size={BADGE_SIZES.SMALL}
                  variant={BADGE_VARIANTS.PINK}
                  label={formatDate(holidayData.dateFrom)}
                />
              </BadgeWrap>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">To</AsideDetailsLabel>
              <BadgeWrap>
                <Badge
                  size={BADGE_SIZES.SMALL}
                  variant={BADGE_VARIANTS.PINK}
                  label={formatDate(holidayData.dateTo)}
                />
              </BadgeWrap>
            </DetailsInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={toggleConfirmationModal}
                isLoading={deletingHoliday}
                isFullWidth
              >
                Delete
              </Button>
            </ButtonWrap>
            {error && 
              <Typography variant="textS" color="red">
              {error}
              </Typography>
            }            
          </PostDetailsWrap>
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleConfirmationModal}
            entityName={holidayData.name}
            entityType="Name"
            handleDelete={handleDeleteHoliday}
          />
        </>
      )}
    </>
  )
}

export default HolidayDetails

HolidayDetails.defaultProps = {
  activeHolidayId: null
}

HolidayDetails.propTypes = {
  activeHolidayId: PropTypes.number,
  closePanel: PropTypes.func.isRequired
}
