import React from 'react'
import PropTypes from 'prop-types'
import {
  DonationDetailsWrap
} from './styles'
import {
  useGetDonationQuery
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock
} from '../../../global/styles'
import { formatDate } from '../../../utils/helpers'
import Typography from '../../../components/UI/Typography'

const DonationDetails = ({ activeDonationId }) => {
  const { data: donationData, isLoading, error: isDonationError } = useGetDonationQuery(activeDonationId)

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {isDonationError && 
        <Typography variant="textS" color="red">Network Error</Typography>
      }        
      {donationData && (
        <>
          <DonationDetailsWrap>
            <AsideDetailsTitle>Donation Details</AsideDetailsTitle>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
              Date
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {formatDate(donationData.date)}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Store
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {donationData.shopName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                User
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {donationData.userName}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Amount
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                ${donationData.amount}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Note
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {donationData.note}
              </AsideDetailsDescription>
            </DetailsInfoBlock>

          </DonationDetailsWrap>
        </>
      )}
    </>
  )
}

export default DonationDetails
DonationDetails.defaultProps = {
  activeDonationId: null
}
DonationDetails.propTypes = {
  activeDonationId: PropTypes.number
}
