import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const ShopAboutSection = styled(VStack)`
  align-self: stretch;
  flex-wrap: wrap;
  padding: 8px;
  margin-bottom: 16px;
`
export const DayItem = styled(VStack)`
  padding-right: 11px;
  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
    padding-left: 14px;
  }
`
export const DayLabel = styled(Typography)`
  margin-bottom: 8px;
`

export const ShopInfoDataWrap = styled(HStack)`
  flex-wrap: wrap;
  flex-grow: 2;
  width: 100%;
  margin-top: 4px;
`

export const AboutThisStore = styled(VStack)`
  margin-top: 16px;
`
export const ContactItem = styled(HStack)`
  align-items: center;
  margin-right: 26px;
`
export const ContactLabel = styled(Typography)`
  margin-left: 9px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  font-weight: 500;
`
export const CategoryWrap = styled.div`
  margin-right: 12px;
`
export const ShopDescription = styled(Typography)`
  margin: 20px 0 40px 0;
`
