import { api as apiSlice} from './api.generated'

const messagesApi = apiSlice.injectEndpoints({
  tagTypes: ['Messages', 'Rooms'],
  endpoints: (build) => ({
    /*
    createNewRoom: build.query({
      query: ({ userId }) => ({
        url: `/chat/room/${userId}/user `,
        method: 'get'
      }),
      providesTags: ['Rooms']
    }),
    */
    getRoomMessages: build.query({
      query: ({ roomId, messageId }) => ({
        url: `shop/chat/room/${roomId}/messages?id=${messageId}`,
        method: 'get'
      }),
      providesTags: ['Messages']
    }),
    getRooms: build.query({
      query: () => ({
        url: `/shop/chat/rooms`,
        method: 'get'
      }),
      providesTags: ['Rooms']
    }),
    postNewMessage: build.mutation({
      query: ({ roomId, data }) => ({
        url: `shop/chat/room/${roomId}/message`,
        method: 'post',
        data
      })
    }),
    deleteRoom: build.mutation({
      query: (roomId) => ({
        url: `shop/chat/room/${roomId}`,
        method: 'delete'
      }),
      invalidatesTags: ['Rooms']
    })
  })
})

export const {
  // useLazyCreateNewRoomQuery,
  useGetRoomMessagesQuery,
  useLazyGetRoomMessagesQuery,
  useGetRoomsQuery,
  usePostNewMessageMutation,
  useDeleteRoomMutation
} = messagesApi
