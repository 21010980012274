import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const AddShopVolunteers = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  padding: 8px;
  border-radius: 4px;
`
export const LabelWrap = styled(HStack)`
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
  max-width: 190px;
`
export const IconWrap = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.green4};
  width: 54px;
  min-width: 54px;
  border-radius: 4px;
  height: 53px;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`
export const VolunteersList = styled(HStack)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-top: 24px;
`
export const MessageWrap = styled(VStack)`
  margin-top: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
  padding: 10px;
  border-radius: 4px;
`
export const IsEmptyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  text-align: center;
  align-self: center;
`
