import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ShopLogoImage = styled.img`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  border-radius: 100px;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.earthBase : theme.colors.skyNeutral3};
`
export const EmptyShopLogo = styled(ShopLogoImage).attrs({ as: 'div' })`
  display: flex;
  justify-content: center;
  align-items: center;
`
