import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { TabWrap, TrailingLine, Wrapper } from './styles'
import Tab from '../Tab'

const TabsWrap = ({ tabsOptions, path }) => {
  const location = useLocation()
  return (
    <Wrapper>
      {tabsOptions.map((tabItem) => (
        <TabWrap key={tabItem.destination}>
          <Tab
            key={tabItem.destination}
            destination={tabItem.destination}
            isActive={`${path}${tabItem.destination}` === location.pathname}
            label={tabItem.label}
            path={path}
          />
        </TabWrap>
      ))}
      <TrailingLine />
    </Wrapper>
  )
}

export default TabsWrap

TabsWrap.propTypes = {
  path: PropTypes.string.isRequired,
  tabsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      destination: PropTypes.string
    })
  ).isRequired
}
