import styled from 'styled-components'
import Typography from '../../../components/UI/Typography'
import { HStack, VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const Headline = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  margin-bottom: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const ModalBody = styled(VStack)`
  align-items: stretch;
  width: 100%;
  justify-content: flex-start;
  padding: 32px;
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }
`
export const ErrorWrap = styled(HStack)`
  padding: 0;
  max-width: 50vw;
  align-items: stretch;
`
export const InputRow = styled(HStack)`
  padding: 0;
  margin: 0; 
  & > :first-child {
    margin-right: 12px;
  }
`
export const SelectRow = styled(HStack)`
  padding: 0;
  margin: 0 0 20px 0; 
  & > :first-child {
    margin-right: 12px;
  }
`
