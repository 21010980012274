import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ContentWrap = styled(VStack)`
  width: 100%;
  padding: 32px;
  overflow: scroll; ;
`
export const HeadlineWrap = styled(HStack)`
  margin-bottom: 32px;
  justify-content: space-between;
  width: 100%;
  height: 44px;
`
export const PostsWrap = styled(HStack)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-top: 24px;
`
export const LatestPostsHeadline = styled(HStack)`
  justify-content: space-between;
  width: 100%;
`
export const LatestPosts = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 24px;
  border-radius: 12px;
  width: 100%;
  transition: height 5s ease-in;
`
export const ManagePostsLink = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
export const ButtonWrap = styled(HStack)`
  & > :nth-child(2) {
    margin-left: 12px;
  }
`