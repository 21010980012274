import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePagination, useRowSelect, useTable } from 'react-table'
import { CheckCircle, Pending } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import {
  useLazyGetSupportTicketsQuery,
  usePostDeleteSupportTicketsMutation
} from '../../../api/api.generated'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import Typography from '../../../components/UI/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import MetricItem from '../../../components/MetricItem/MetricItem'
import TableCheckboxCell from '../../../components/TableCheckboxCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableDeleteCell from '../../../components/TableDeleteCell'
import SupportTicketsTableRow from '../SupportTicketsTableRow'
import TicketDetails from '../TicketDetails'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  Toolbar
} from '../../../global/styles'
import { MetricsItem } from './styles'

const tableColumns = [
  {
    Header: 'Sender Name',
    accessor: 'senderName'
  },
  {
    Header: 'Issue Topic',
    accessor: 'issueTopic'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Status',
    accessor: 'status'
  }
]

const ManageSupportTickets = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [error, setError] = useState('')
  const [metrics, setMetrics] = useState([])
  const [activeTicketId, setActiveTicketId] = useState(null)
  const [getTickets, { data: ticketsData, isLoading }] = useLazyGetSupportTicketsQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteSupportTicketsMutation()
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const getMetrics = useCallback(
    (data) => [
      {
        description: 'Report is complete on investigation',
        icon: <CheckCircle />,
        iconBgColor: 'green3',
        isActive: false,
        metricName: 'Resolved Tickets',
        numberAndIconColor: 'greenBase',
        onClick: () => {},
        value: data?.countResolved
      },
      {
        description: 'Report under investigation',
        icon: <Pending />,
        iconBgColor: 'skyNeutral3',
        isActive: false,
        metricName: 'Pending Tickets',
        numberAndIconColor: 'skyNeutral_1',
        onClick: () => {},
        value: data?.countPending
      }
    ],
    []
  )

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      ticketsData
        ? ticketsData?.tickets?.map((ticket) => SupportTicketsTableRow(ticket))
        : [],
    [ticketsData]
  )

  const handleShowTicketDetails = (ticketId) => {
    if (ticketId !== activeTicketId) {
      setShowDetails(true)
      setActiveTicketId(ticketId)
    } else {
      setShowDetails(false)
      setActiveTicketId(null)
    }
  }
  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveTicketId(null)
  }

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.ticketId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableDeleteCell
              cell={cell}
              handleShowConfirmModal={setShowConfirmDelete}
              handleSetIdToDelete={setDeleteOneId}
            />
          )
        }
      ])
    }
  )

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleDeleteSelected = () => {
    handleCloseConfirmationModal()
    handleCloseDetails()
    if (deleteOneId) {
      setDeleteOneId(null)
      deleteSelectedIds({ body: { supportTicketsIdsToDelete: [deleteOneId] } })
      return
    }
    if (!isEmpty(selectedRowIds)) {
      setDeleteOneId(null)
      deleteSelectedIds({
        body: { supportTicketsIdsToDelete: keys(selectedRowIds) }
      })
    }
  }

  const loadTickets = () => {
    setError('')
    getTickets({ page: pageIndex, limit: pageSize })
      ?.unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadTickets();
  }, [])

  useEffect(() => {
    if (ticketsData) {
      setTotalPages(ticketsData.pagination.pages)
      setTotalRows(ticketsData.pagination.total)
      if (pageIndex >= ticketsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [ticketsData])

  useEffect(() => {
    if (ticketsData) {
      loadTickets()
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (ticketsData) {
      setMetrics(getMetrics(ticketsData))
    }
  }, [ticketsData])

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Support"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }        
        {ticketsData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={() => {}}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowTicketDetails}
              activeItemId={activeTicketId}
            />
          </>
        )}
      </DashboardInfoWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <TicketDetails
          {...{ activeTicketId }}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }

      {ticketsData && (!isEmpty(selectedRowIds) || deleteOneId) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            ticketsData?.tickets,
            'topic'
          )}
          entityType="Support Ticket"
          handleDelete={handleDeleteSelected}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManageSupportTickets

ManageSupportTickets.propTypes = {}
