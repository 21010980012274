import { api } from './api.generated'

const ordersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getShopOrders: build.query({
      query: ({ search, page, limit, timeFrame, from, to }) => ({
        url: `/shop/orders/${timeFrame}?page=${page}&limit=${limit}&search=${search}&from=${from}&to=${to}`,
        method: 'get'
      }),
      providesTags: ['ShopOrders']
    }),
    getShopOrder: build.query({
      query: (orderId) => ({ url: `/shop/orders/${orderId}/show` }),
      providesTags: ['ShopOrder']
    }),
    putShopOrder: build.mutation({
      query: ({ orderId, data }) => ({
        url: `/shop/orders/${orderId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopOrders', 'ShopOrder']
    }),
  })
})

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetShopOrdersQuery,
  useLazyGetShopOrdersQuery,
  useGetShopOrderQuery,
  usePutShopOrderMutation
} = ordersApi
