import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const IconWrap = styled.label`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  margin-right: 22px;
  border: 2px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.skyNeutral : 'transparent'};
`
