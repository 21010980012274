import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'
import { truncateString } from '../../../utils/helpers'

const SupportTicketsTableRow = (ticket) => ({
  ticketId: ticket.id,
  senderName: (
    <TableImageLabelCell
      bgColor={ticket.avatarBackground}
      image={ticket.senderImage}
      label={ticket.senderName}
      isRounded
    />
  ),
  issueTopic: <TableText>{ticket.topic}</TableText>,
  description: <TableText>{truncateString(ticket.description, 30)}</TableText>,
  status: (
    <Badge
      label={ticket.isResolved ? 'Resolved' : 'Pending'}
      variant={ticket.isResolved ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
    />
  )
})

export default SupportTicketsTableRow

SupportTicketsTableRow.propTypes = {}
