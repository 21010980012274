import React from 'react'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableText } from '../../../global/styles'

const QuestionTableRow = (question) => ({
  id: question.id,
  shop: <TableText>{question.shopName}</TableText>,
  question: <TableText>{question.question}</TableText>,
  answer: <TableText>{question.answer}</TableText>,
  isOrganizationQuestion:
    question.isOrganizationQuestion
      ? <Badge label="Yes" variant={BADGE_VARIANTS.GREEN} size={BADGE_SIZES.SMALL}/>
      : '',
  canEdit: !question.isOrganizationQuestion,
  forSearch: `${question.question} ${question.answer}`
})

export default QuestionTableRow

QuestionTableRow.propTypes = {}
