import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import {
  BlueBgSlice,
  BottomHead,
  ContentWrap,
  OrangeBgSlice,
  TopHead,
  WelcomeMessage,
  Wrapper
} from './styles'
import OphopLogo from '../../assets/icons/OphopLogo'
import HoppaHead from '../../assets/icons/HoppaHead'

const AuthScreensAsideGraphics = ({ theme }) => {
  const { welcomeMessage } = useSelector((state) => state.auth)
  return (
    <Wrapper>
      <ContentWrap>
        <WelcomeMessage color={theme.colors.white} variant="headingXL">
          {welcomeMessage}
        </WelcomeMessage>
        <OphopLogo fill={theme.colors.white} width={381} height={97} />
      </ContentWrap>
      <BottomHead>
        <HoppaHead />
      </BottomHead>
      <TopHead>
        <HoppaHead />
      </TopHead>
      <BlueBgSlice />
      <OrangeBgSlice />
    </Wrapper>
  )
}

export default withTheme(AuthScreensAsideGraphics)
AuthScreensAsideGraphics.defaultProps = {}
AuthScreensAsideGraphics.propTypes = { theme: PropTypes.object.isRequired }
