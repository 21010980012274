import { BUTTONS_SIZES } from '../const/UIvariants'

export const setLightness = (colorInHsl, lightness) => {
  const parsedColor = colorInHsl.split(',')
  parsedColor[2] = `${lightness ? `${lightness}%` : parsedColor[2]}`
  return parsedColor.join(',')
}

export const iconButtonSize = (size) => {
  const iconSize =
    size === BUTTONS_SIZES.LARGE ? 32 : size === BUTTONS_SIZES.MED ? 22 : 18
  return {
    width: iconSize,
    height: iconSize
  }
}
