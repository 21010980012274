import styled from 'styled-components'
import { Card, VStack } from '../../../global/styles'

export const ShopWizardWrap = styled(Card)`
  padding: 24px 20px;
  flex-grow: 0;
  height: 100%;
  margin-left: auto;
  overflow: scroll;
  align-self: flex-start;
  display: flex;
  width: 336px;
  flex-direction: column;
  min-width: 336px;
`

export const ListWrap = styled(VStack)`
  width: 100%;
  margin-top: 24px;
`
export const ListOfRequirements = styled(VStack)`
  border-top: 2px solid ${({ theme }) => theme.colors.skyNeutral4};
  padding-top: 24px;
`
export const ShopProfileTutorialWrap = styled(VStack)``
export const TopSection = styled(VStack)`
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 36px;
  & > * {
    margin-bottom: 8px;
  }
`
export const IconWrap = styled(VStack)`
  width: 112px;
  height: 112px;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 112px;
  border: 2px solid ${({ theme }) => theme.colors.skyNeutral3};
`

export const test = styled.div``
