import React from 'react'
import { TableText } from '../../../global/styles'
import { truncateString } from '../../../utils/helpers'

const QuestionTableRow = (question) => ({
  id: question.id,
  organizationName: (
    <TableText>{question.organizationName}</TableText>
  ),
  question: (
    <TableText>{question.question}</TableText>
  ),
  answer: (
    <TableText>{truncateString(question.answer, 50)}</TableText>
  ),
  forSearch: `${question.question} ${question.answer}`
})

export default QuestionTableRow

QuestionTableRow.propTypes = {}
