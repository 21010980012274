import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { keys, values } from 'lodash/object'
import {
  ButtonWrap,
  ColorsWrap,
  Headline,
  IconsWrap,
  Label,
  ModalBody
} from './styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import { TAG_COLORS, TAG_ICONS } from '../../../const/app'
import SelectIcon from '../../../components/UI/SelectIcon'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import SelectColor from '../../../components/UI/SelectColor'
import { HStack } from '../../../global/styles'
import { InputError } from '../../../components/UI/Inputs/TextInput/styles'
import Button from '../../../components/UI/Buttons/Button/Button'

const AddTagModal = ({
  isOpen,
  handleCloseAddNewModal,
  handleCreateNewTag,
  addingNewTag
}) => (
  <Modal {...{ isOpen }}>
    <ModalBody>
      <Formik
        initialValues={{
          tagName: '',
          icon: '',
          color: ''
        }}
        validationSchema={Yup.object({
          tagName: Yup.string().required('Name is required'),
          icon: Yup.string().required('Please select icon'),
          color: Yup.string().required('Please select color')
        })}
        onSubmit={handleCreateNewTag}
      >
        {({ errors, touched }) => (
          <Form>
            <Headline>Add Category</Headline>
            <TextInput
              label="Category name"
              placeholder="Enter category name"
              inputName="tagName"
              size={INPUT_SIZES.SMALL}
            />
            <IconsWrap>
              <Label> Select Icon </Label>
              <HStack justify="flex-start">
                {keys(TAG_ICONS).map((icon) => (
                  <SelectIcon icon={icon} key={icon} name="icon" />
                ))}
              </HStack>
              <InputError>{errors && touched.icon ? errors.icon : null}</InputError>
            </IconsWrap>
            <ColorsWrap>
              <Label>Select color</Label>
              <HStack>
                {values(TAG_COLORS).map((color) => (
                  <SelectColor color={color} key={color} name="color" />
                ))}
              </HStack>
              <InputError>{errors && touched.color ? errors.color : null}</InputError>
            </ColorsWrap>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={handleCloseAddNewModal}
                type="button"
              >
                Cancel
              </Button>
              <Button
                isLoading={addingNewTag}
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
                type="submit"
              >
                Create
              </Button>
            </ButtonWrap>
          </Form>
        )}
      </Formik>
    </ModalBody>
  </Modal>
)

export default AddTagModal

AddTagModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  addingNewTag: PropTypes.bool.isRequired,
  handleCloseAddNewModal: PropTypes.func.isRequired,
  handleCreateNewTag: PropTypes.func.isRequired
}
