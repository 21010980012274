import styled from 'styled-components'
import { HStack } from '../../global/styles'
import Button from '../UI/Buttons/Button'
import Typography from '../UI/Typography'

export const EntityDashboardHeadline = styled(HStack)`
  width: 100%;
  margin-bottom: 30px;
  background: none;
  justify-content: space-between;
  align-items: center;
`
export const AddButton = styled(Button).attrs(({ theme }) => ({
  fillColor: theme.colors.green_1
}))`
`
export const SectionName = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_2};
  margin-right: auto;
`
export const ButtonWrap = styled(HStack)`
  justify-content: space-between;
  button {
    margin-left: 10px;
  }
  //flex-basis: 400px;
`
