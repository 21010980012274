import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import {
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationQuestionQuery,
  usePostOrganizationQuestionMutation,
  usePutOrganizationQuestionMutation
} from '../../../api/api.generated'
import { getErrorMessage } from '../../../utils/helpers'
import { ErrorWrap } from '../../../global/styles'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import ShowErrorModal from '../../../components/ShowErrorModal'
import {
  FormWrap,
  Headline,
  ButtonWrap
} from './styles'

const emptyQuestion = {
  organization: '',
  question: '',
  answer: ''
}

const AddOrganizationQuestionModal = ({ isOpen, onClose, editQuestionId, organizationId, showOrganization }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyQuestion)
  const [error, setError] = useState('')
  const [getOrganizations, { data: organizationsData, error: organizationsError }] = useLazyGetOrganizationsQuery()
  const [getOrganizationQuestion, { data: shopQuestion, error: productError }] = useLazyGetOrganizationQuestionQuery()
  const [postOrganizationQuestion] = usePostOrganizationQuestionMutation()
  const [updateOrganizatioQuestion] = usePutOrganizationQuestionMutation()

  const title = (isNull(editQuestionId)) ? 'Add FAQ' : 'Edit FAQ';

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  useEffect(() => {
    if (showOrganization) {
      getOrganizations()
      ?.unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
    }
    if (!isNull(editQuestionId)) {
      getOrganizationQuestion(editQuestionId)
    } else {
      setInitialData({
        organization: organizationId,
        question: '',
        answer: '',
      })      
      if (!showOrganization) {
        setIsSuccess(true)
      }
    }
  }, [])

  useEffect(() => {
    if (shopQuestion && (organizationsData || !showOrganization)) {
      setInitialData({
        organization: shopQuestion.organizationId,
        question: shopQuestion.question,
        answer: shopQuestion.answer
      })
      setIsSuccess(true)
    }
    if ((isNull(editQuestionId)) && (organizationsData)) {
      setIsSuccess(true)
    }
  }, [shopQuestion, organizationsData])

  const handleSubmitForm = (values) => {
    setError('')
    if (editQuestionId) {
      updateOrganizatioQuestion({
        organizationQuestionId: editQuestionId,
        body: { 
          organization: values.organization,
          question: values.question,
          answer: values.answer
        }
      })
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    } else {
      postOrganizationQuestion({ 
        body: {
          organization: values.organization,
          question: values.question,
          answer: values.answer
        }
      })
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !organizationsError && !productError && 
        <LoadingSpinner />
      } 
      {(organizationsError || productError) && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }         
      {isSuccess && (
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={Yup.object({
              organization: Yup.string().required('Organization is required'),
              question: Yup.string().max(250, 'Question is too long').required('Question is required'),
              answer: Yup.string().max(5000, 'Answer is too long').required('Answer is required')
            })}
            onSubmit={(values) => {
              handleSubmitForm(values, editQuestionId)
            }}
          >
            <Form>
              <Headline>{ title }</Headline>
              { showOrganization &&
              <FormSelectOneInput
                inputName="organization"
                label="Organisation"
                options={organizationList}
                size="100%"
                value={initialData.organization}
                isGrey
              />
              }
              <TextInput
                size={INPUT_SIZES.SMALL}
                inputName="question"
                label="Question"
                placeholder="Enter question"
              />
              <TextAreaInput
                inputName="answer"
                label="Answer"
                rows={14}
                placeholder="Enter answer"
                labelMarginBottom={0}
              />
              {error &&
                <ErrorWrap>
                  <Typography variant="textS" color="red">
                  {error}
                  </Typography>
                </ErrorWrap>
              }
              <ButtonWrap>
                <Button
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </Form>
          </Formik>
        </FormWrap>
      )}
    </Modal>
  )
}

export default withTheme(AddOrganizationQuestionModal)

AddOrganizationQuestionModal.defaultProps = {
  editQuestionId: null,
  organizationId: null
}

AddOrganizationQuestionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editQuestionId: PropTypes.number,
  organizationId: PropTypes.string,
  showOrganization: PropTypes.bool.isRequired
}
