import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../authSlice'
import { api as apiSlice } from '../../../api/api.generated'

const Logout = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logout())
    dispatch(apiSlice.util.resetApiState())
  }, [])
  return null
}

export default Logout

Logout.propTypes = {}
