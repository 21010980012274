import styled from 'styled-components'
import { BUTTONS_SIZES, BUTTON_VARIANTS } from '../../../../const/UIvariants'
import { iconButtonSize, setLightness } from '../../../../utils/styles-utils'

export const StyledButton = styled.button`
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  cursor: pointer;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  transition: background-color 0.2s ease-in-out;
  background-color: ${({ theme, variant, fillColor }) =>
    variant === BUTTON_VARIANTS.PRIMARY
      ? fillColor || theme.colors.skyBase
      : variant === BUTTON_VARIANTS.SECONDARY
      ? theme.colors.skyNeutral4
      : 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  // width: 100%;
  // max-width: 48%;
  border-radius: 8px;
  overflow: hidden;
  flex-basis: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
  padding: ${({ size }) =>
    size === BUTTONS_SIZES.LARGE
      ? '0 20px '
      : size === BUTTONS_SIZES.MED
      ? '0 11px'
      : '0 6px'};
  color: ${({ theme, variant, fillColor }) =>
    variant === BUTTON_VARIANTS.PRIMARY
      ? theme.colors.white
      : variant === BUTTON_VARIANTS.SECONDARY
      ? theme.colors.skyCreator(12, 51)
      : variant === BUTTON_VARIANTS.OUTLINED
      ? theme.colors.skyBase
      : fillColor || 'transparent'};
  border: ${({ variant, theme }) =>
    variant === BUTTON_VARIANTS.OUTLINED
      ? `2px solid ${theme.colors.skyCreator(78, 89)}`
      : 'none'};
  word-wrap: normal;

  p,
  h6,
  h5 {
    white-space: nowrap;
    margin: ${({ size }) =>
      size === BUTTONS_SIZES.LARGE
        ? ' 0 8px '
        : size === BUTTONS_SIZES.MED
        ? ' 0 13px'
        : ' 0 12px'};
    color: ${({ theme, variant, fillColor }) =>
      variant === BUTTON_VARIANTS.PRIMARY
        ? theme.colors.white
        : variant === BUTTON_VARIANTS.SECONDARY
        ? theme.colors.skyCreator(12, 51)
        : variant === BUTTON_VARIANTS.OUTLINED
        ? theme.colors.skyBase
        : fillColor || 'transparent'};
  }

  &:only-of-type {
    flex-basis: 100%;
    width: 100%;
  }

  &:hover {
    animation: ${({ hasError }) => (hasError ? 'shake' : '')} 0.82s
      cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    background-color: ${({ theme, variant, fillColor }) =>
      variant === BUTTON_VARIANTS.PRIMARY
        ? (fillColor && setLightness(fillColor, 40)) ||
          theme.colors.skyCreator(67, 41)
        : variant === BUTTON_VARIANTS.OUTLINED
        ? theme.colors.skyBase4
        : ''};
    border: ${({ variant, theme }) =>
      variant === BUTTON_VARIANTS.OUTLINED
        ? `2px solid ${theme.colors.skyBase2}`
        : 'none'};
    p,
    h6,
    h5 {
      color: ${({ theme, variant, fillColor }) =>
        variant === BUTTON_VARIANTS.PRIMARY
          ? theme.colors.white
          : variant === BUTTON_VARIANTS.SECONDARY
          ? theme.colors.skyCreator(12, 51)
          : variant === BUTTON_VARIANTS.OUTLINED
          ? theme.colors.skyBase
          : fillColor || 'transparent'};
    }
  }

  &:active {
    background-color: ${({ theme, variant, fillColor }) =>
      variant === BUTTON_VARIANTS.PRIMARY
        ? (fillColor && setLightness(fillColor, 30)) ||
          theme.colors.skyCreator(66, 29)
        : variant === BUTTON_VARIANTS.OUTLINED
        ? theme.colors.skyBase3
        : theme.colors.skyCreator(20, 75)};
    color: ${({ theme, variant }) =>
      variant === BUTTON_VARIANTS.OUTLINED ? theme.colors.skyBase_2 : 'white'};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.skyNeutral4};
  }
`
export const LeftIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => iconButtonSize(size).width}px;
  height: ${({ size }) => iconButtonSize(size).height}px;
  margin-left: ${({ size }) =>
    size === BUTTONS_SIZES.LARGE
      ? '12px '
      : size === BUTTONS_SIZES.MED
      ? '15px'
      : '6px'};
`
export const RightIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => iconButtonSize(size).width}px;
  height: ${({ size }) => iconButtonSize(size).height}px;
  margin-right: ${({ size }) =>
    size === BUTTONS_SIZES.LARGE
      ? '12px '
      : size === BUTTONS_SIZES.MED
      ? '15px'
      : '6px'};
`
