import styled from 'styled-components/macro'
import Typography from '../../../components/UI/Typography'
import { HStack, VStack } from '../../../global/styles'

export const FormWrap = styled(VStack)`
  align-items: stretch;
  width: 100%;
  justify-content: flex-start;
  padding: 32px;
`
export const Headline = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  margin-bottom: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const DateWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  & > * :first-child {
    margin-right: 20px;
  }
  input {
    min-width: 164px;
  }
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }  
`  
