import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableText } from '../../../global/styles'
import { formatDate } from '../../../utils/helpers'

const HolidayTableRow = (holiday) => ({
  id: holiday.id,
  name: (
    <TableText>{holiday.name}</TableText>
  ),  
  organizationName: (
    <TableText>{holiday.organizationName}</TableText>
  ),  
  dateFrom: (
    <Badge
      variant={ BADGE_VARIANTS.PINK }
      label={formatDate(holiday.dateFrom)}
    />
  ),
  dateTo: (
    <Badge
      variant={ BADGE_VARIANTS.PINK }
      label={formatDate(holiday.dateTo)}
    />
  ),
  forSearch: `${holiday.name} ${holiday.dateFrom} ${holiday.dateTo}`
})

export default HolidayTableRow

HolidayTableRow.propTypes = {}
