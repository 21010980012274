import React from 'react'
import { Star } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { RatingWrap } from './styles'
import { globalTheme } from '../../../global/theme'

const Rating = ({ rating }) => (
  <RatingWrap>
    {Array.from({ length: 5 }).map((_, index) => (
      <Star
        size={16}
        width={16}
        height={16}
        key={`${index.toString() + rating.toString()}`}
        color={
          rating >= index + 1
            ? globalTheme.colors.yellowBase
            : globalTheme.colors.yellowNeutral
        }
      />
    ))}
  </RatingWrap>
)

export default Rating

Rating.propTypes = {
  rating: PropTypes.number.isRequired
}
