import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const CustomerDetailsWrap = styled(VStack)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
  & > :first-child {
    margin-right: 12px;
  }
`
