import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PusherProvider } from 'react-pusher-hoc'
import ROUTES from './const/routes'
import Login from './features/auth/Login'
import { AppWrap } from './global/styles'
import ForgotPassword from './features/auth/ForgotPassword'
import CreateNewPassword from './features/auth/CreateNewPassword'
import FallbackRoute from './components/FallbackRoute'
import AuthHoc from './features/auth/AuthHOC'
import PrivateRoute from './components/PrivateRoute'
import NavigationHoc from './features/navigation/NavigationHoc'
import Logout from './features/auth/Logout'
import Dashboard from './features/dashboard/Dashboard'
import ManageOrganizations from './features/organizations/ManageOrganizations'
import ManageShops from './features/shops/ManageShops'
import ManageShop from './features/shops/ManageShop'
import ManageShopProducts from './features/shops/ManageShopProducts'
import ManageShopPosts from './features/shops/ManageShopPosts'
import ManageShopDonations from './features/shops/ManageShopDonations'
import ManageShopVolunteers from './features/shops/ManageShopVolunteers'
import ManageShopGallery from './features/shops/ManageShopGallery'
import ManageShopVideos from './features/shops/ManageShopVideos'
import ManageShopQuestions from './features/shops/ManageShopQuestions'
import ManageShopHolidays from './features/shops/ManageShopHolidays'
import ManageOrders from './features/orders/ManageOrders'
import ManageStoreOrders from './features/storeOrders/ManageStoreOrders'
import ManageOrderProducts from './features/orderProducts/ManageOrderProducts'
import ManageBankTransfers from './features/bankTransfers/ManageBankTransfers'
import ManageTransferOrders from './features/transferOrders/ManageTransferOrders'
import './config/modal'
import ManageDonations from './features/donations/ManageDonations'
import ManageOrganizationProducts from './features/organizationProducts/ManageOrganizationProducts'
import ManageOrganizationPosts from './features/organizationPosts/ManageOrganizationPosts'
import ManageOrganizationQuestions from './features/organizationQuestions/ManageOrganizationQuestions'
import ManageOrganizationHolidays from './features/organizationHolidays/ManageOrganizationHolidays'
import ManageProducts from './features/products/ManageProducts'
import ManagePosts from './features/posts/ManagePosts'
import ManageQuestions from './features/questions/ManageQuestions'
import ManageHolidays from './features/holidays/ManageHolidays'
import ManageTags from './features/tags/ManageTags'
import ManageEmailTemplates from './features/emailTemplates/ManageEmailTemplates'
import ManageSupportTickets from './features/supportTickets/ManageSupportTickets'
import ManageManagers from './features/managers/ManageManagers'
import ManageCustomers from './features/customers/ManageCustomers'
import Settings from './features/settings/settings/Settings'
import DashboardHoc from './features/dashboard/DashboardHoc'
import OrganizationDashboard from './features/dashboard/OrganizationDashboard'
import ManageShopDashboard from './features/dashboard/ManageShopDashboard'
import AboutShop from './features/shop/AboutShop'
import StripeConnect from './features/stripe/StripeConnect'
import ShopPageHocAdmin from './features/shop/ShopPageHocAdmin'
import ShopPageHoc from './features/shop/ShopPageHoc'
import ShopProfileProducts from './features/shop/ShopProfileProducts'
import ShopProfilePosts from './features/shop/ShopProfilePosts'
import ShopProfileDonations from './features/shop/ShopProfileDonations'
import ShopProfileVolunteers from './features/shop/ShopProfileVolunteers'
import ShopProfileGallery from './features/shop/ShopProfileGallery'
import ShopProfileVideos from './features/shop/ShopProfileVideos'
import ShopProfileQuestions from './features/shop/ShopProfileQuestions'
import ShopProfileHolidays from './features/shop/ShopProfileHolidays'
import ManageContacts from './features/contacts/ManageContacts'
import ManageShopOrders from './features/shopOrders/ManageOrders'
import ManageShopOrderProducts from './features/shopOrderProducts/ManageOrderProducts'
import ManageStoreReports from './features/storeReports/ManageStoreReports'
import MessagesHoc from './features/messages/MessagesHoc'
import ChangePassword from './features/settings/shopSettings'
import { setEditMode } from './features/shop/shopSlice'
import { selectIsEditingShop } from './app/selectors'
import { usePusher } from './config/pusher'
// import CreateAccount from './features/auth/CreateAccount'

const transfersAdminColumns = [
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Store',
    accessor: 'shopName'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
]

const transfersStoreColumns = [
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Total',
    accessor: 'total'
  }
]


const shopRoutes = [
  {
    key: 'ShopProfile',
    name: 'Store Profile ',
    route: ROUTES.SHOP_PROFILE,
    Component: <AboutShop />
  },
  {
    key: 'ShopProducts',
    name: 'Store Products',
    route: ROUTES.SHOP_PROFILE_PRODUCTS,
    Component: <ShopProfileProducts />
  },
  {
    key: 'ShopPosts',
    name: 'Store Posts',
    route: ROUTES.SHOP_PROFILE_POSTS,
    Component: <ShopProfilePosts />
  },
  {
    key: 'ShopDonations',
    name: 'Store Donations',
    route: ROUTES.SHOP_PROFILE_DONATIONS,
    Component: <ShopProfileDonations />
  },
  {
    key: 'ShopVolunteers',
    name: 'Store Volunteers',
    route: ROUTES.SHOP_PROFILE_VOLUNTEERS,
    Component: <ShopProfileVolunteers />
  },
  {
    key: 'ShopQuestions',
    name: 'Store Questions',
    route: ROUTES.SHOP_PROFILE_QUESTIONS,
    Component: <ShopProfileQuestions />
  },
  {
    key: 'ShopGallery',
    name: 'Store Gallery',
    route: ROUTES.SHOP_PROFILE_GALLERY,
    Component: <ShopProfileGallery />
  },
  {
    key: 'ShopVideos',
    name: 'Store Videos',
    route: ROUTES.SHOP_PROFILE_VIDEOS,
    Component: <ShopProfileVideos />
  },
  {
    key: 'ShopHolidays',
    name: 'Store Holidays',
    route: ROUTES.SHOP_PROFILE_HOLIDAYS,
    Component: <ShopProfileHolidays />
  },
]
const shopAdminRoutes = [
  {
    key: 'AdminShopProfile',
    name: 'Store Profile',
    route: ROUTES.SHOP,
    Component: <ManageShop />
  },
  {
    key: 'AdminShopProducts',
    name: 'Store products',
    route: ROUTES.SHOP_PRODUCTS,
    Component: <ManageShopProducts />
  },
  {
    key: 'AdminShopPosts',
    name: 'Store posts',
    route: ROUTES.SHOP_POSTS,
    Component: <ManageShopPosts />
  },
  {
    key: 'AdminShopDonations',
    name: 'Store Donations',
    route: ROUTES.SHOP_DONATIONS,
    Component: <ManageShopDonations />
  },
  {
    key: 'AdminShopVolunteers',
    name: 'Store Volunteers',
    route: ROUTES.SHOP_VOLUNTEERS,
    Component: <ManageShopVolunteers />
  },
  {
    key: 'AdminShopQuestions',
    name: 'Store Questions',
    route: ROUTES.SHOP_QUESTIONS,
    Component: <ManageShopQuestions />
  },
  {
    key: 'AdminShopGallery',
    name: 'Store Gallery',
    route: ROUTES.SHOP_GALLERY,
    Component: <ManageShopGallery />
  },
  {
    key: 'AdminShopVideos',
    name: 'Store Videos',
    route: ROUTES.SHOP_VIDEOS,
    Component: <ManageShopVideos />
  },
  {
    key: 'AdminShopHolidays',
    name: 'Store Holidays',
    route: ROUTES.SHOP_HOLIDAYS,
    Component: <ManageShopHolidays />
  }
]
const publicRoutes = [
  {
    key: 'Login',
    name: 'Login',
    route: ROUTES.LOGIN,
    Component: <Login />,
    tokenRequired: false
  },
  /*
  {
    name: 'Create Account',
    route: ROUTES.CREATE_ACCOUNT,
    Component: <CreateAccount />,
    tokenRequired: false
  },
  */
  {
    key: 'ForgotPassword',
    name: 'Forgot password',
    route: ROUTES.FORGOT_PASSWORD,
    Component: <ForgotPassword />,
    tokenRequired: false
  },
  {
    key: 'CreateNewPassword',
    name: 'Create New Password',
    route: ROUTES.CREATE_NEW_PASSWORD,
    Component: <CreateNewPassword />,
    tokenRequired: false
  }
]
const appRoutes = [
  // storeAdmin
  {
    key: 'Dashboard',
    name: 'Dashboard',
    route: ROUTES.DASHBOARD,
    Component: <DashboardHoc />,
    role: 'storeAdmin',
    tokenRequired: true
  },
  {
    key: 'Messages',
    name: 'Messages',
    route: ROUTES.MESSAGES,
    Component: <MessagesHoc />,
    role: 'storeAdmin',
    tokenRequired: true
  },
  {
    key: 'Contacts',
    name: 'Contacts',
    route: ROUTES.CONTACTS,
    Component: <ManageContacts />,
    role: 'storeAdmin',
    tokenRequired: true
  },
  {
    key: 'Settings',
    name: 'Settings',
    route: ROUTES.SETTINGS,
    Component: <ChangePassword />,
    role: 'storeAdmin',
    tokenRequired: true
  },
  {
    key: 'OrderHistory',
    name: 'Order History',
    route: ROUTES.STORE_ORDER_HISTORY,
    Component: <ManageShopOrders />,
    role: 'storeAdmin',
    tokenRequired: true
  },
  {
    key: 'OrderProducts',
    name: 'Order Products',
    route: ROUTES.STORE_ORDER_PRODUCTS,
    Component: <ManageShopOrderProducts />,
    role: 'storeAdmin',
    tokenRequired: true
  }, 
  {
    key: 'BankTransfers',
    name: 'Bank Transfers',
    route: ROUTES.SHOP_TRANSFERS,
    Component: <ManageBankTransfers showStore={false} tableColumns={transfersStoreColumns}/>,
    role: 'storeAdmin',
    tokenRequired: true
  },
  {
    key: 'BankTransferOrders',
    name: 'Transfer Orders',
    route: ROUTES.TRANSFER_ORDERS,
    Component: <ManageTransferOrders showStore={false}/>,
    role: 'storeAdmin',
    tokenRequired: true
  },  
  {
    key: 'Logout',
    name: 'Logout',
    route: ROUTES.LOGOUT,
    Component: <Logout />,
    role: 'storeAdmin',
    tokenRequired: true
  },

  // organizationAdmin
  {
    key: 'OrganizationAdminDashboard',
    name: 'Dashboard',
    route: ROUTES.DASHBOARD,
    Component: <OrganizationDashboard />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminOrganizationProducts',
    name: 'Organisation Products',
    route: ROUTES.ORGANIZATION_PRODUCTS,
    Component: <ManageOrganizationProducts />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminOrganizationPosts',
    name: 'Organisation Posts',
    route: ROUTES.ORGANIZATION_POSTS,
    Component: <ManageOrganizationPosts />,
    role: 'organisationAdmin',
    tokenRequired: true
  },  
  {
    key: 'OrganizationAdminOrganizationQuestions',
    name: 'Organisation FAQ',
    route: ROUTES.ORGANIZATION_QUESTIONS,
    Component: <ManageOrganizationQuestions />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminOrganizationHolidays',
    name: 'Organisation Holidays',
    route: ROUTES.ORGANIZATION_HOLIDAYS,
    Component: <ManageOrganizationHolidays />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminShops',
    name: 'Shops',
    route: ROUTES.SHOPS,
    Component: <ManageShops />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminProducts',
    name: 'Products',
    route: ROUTES.PRODUCTS,
    Component: <ManageProducts />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminPosts',
    name: 'Posts',
    route: ROUTES.POSTS,
    Component: <ManagePosts />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminQuestions',
    name: 'FAQ',
    route: ROUTES.QUESTIONS,
    Component: <ManageQuestions />,
    role: 'organisationAdmin',
    tokenRequired: true
  },  
  {
    key: 'OrganizationAdminHolidays',
    name: 'Holidays',
    route: ROUTES.HOLIDAYS,
    Component: <ManageHolidays />,
    role: 'organisationAdmin',
    tokenRequired: true
  },    
  {
    key: 'OrganizationAdminManagers',
    name: 'Managers',
    route: ROUTES.MANAGERS,
    Component: <ManageManagers />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminDonations',
    name: 'Donations',
    route: ROUTES.DONATIONS,
    Component: <ManageDonations />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminStoreOrders',
    name: 'Store Orders',
    route: ROUTES.STORE_ORDERS,
    Component: <ManageStoreOrders />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminOrdersProducts',
    name: 'Order Products',
    route: ROUTES.STORE_ORDER_PRODUCTS,
    Component: <ManageOrderProducts />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminBankTransfers',
    name: 'Bank Transfers',
    route: ROUTES.BANK_TRANSFERS,
    Component: <ManageBankTransfers showStore tableColumns={transfersAdminColumns}/>,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminTransferOrders',
    name: 'Transfer Orders',
    route: ROUTES.TRANSFER_ORDERS,
    Component: <ManageTransferOrders showStore/>,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminMessages',
    name: 'Messages',
    route: ROUTES.MESSAGES,
    Component: <MessagesHoc />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminLogout',
    name: 'Logout',
    route: ROUTES.LOGOUT,
    Component: <Logout />,
    role: 'organisationAdmin',
    tokenRequired: true
  },
  {
    key: 'OrganizationAdminShopDashboard',
    name: 'Dashboard',
    route: ROUTES.SHOP_DASHBOARD,
    Component: <ManageShopDashboard />,
    role: 'organisationAdmin',
    tokenRequired: true
  },

  // superAdmin
  {
    key: 'SuperAdminDashboard',
    name: 'Dashboard',
    route: ROUTES.DASHBOARD,
    Component: <Dashboard />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrganizations',
    name: 'Organisation',
    route: ROUTES.ORGANIZATIONS,
    Component: <ManageOrganizations />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrganizationProducts',
    name: 'Organisation Products',
    route: ROUTES.ORGANIZATION_PRODUCTS,
    Component: <ManageOrganizationProducts />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrganizationPosts',
    name: 'Organisation Posts',
    route: ROUTES.ORGANIZATION_POSTS,
    Component: <ManageOrganizationPosts />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrganizationQuestions',
    name: 'Organisation FAQ',
    route: ROUTES.ORGANIZATION_QUESTIONS,
    Component: <ManageOrganizationQuestions />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrganizationHolidays',
    name: 'Organisation Holidays',
    route: ROUTES.ORGANIZATION_HOLIDAYS,
    Component: <ManageOrganizationHolidays />,
    role: 'superAdmin',
    tokenRequired: true
  },  
  {
    key: 'SuperAdminShops',
    name: 'Shops',
    route: ROUTES.SHOPS,
    Component: <ManageShops />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminProducts',
    name: 'Products',
    route: ROUTES.PRODUCTS,
    Component: <ManageProducts />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminPosts',
    name: 'Posts',
    route: ROUTES.POSTS,
    Component: <ManagePosts />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminQuestions',
    name: 'FAQ',
    route: ROUTES.QUESTIONS,
    Component: <ManageQuestions />,
    role: 'superAdmin',
    tokenRequired: true
  },  
  {
    key: 'SuperAdminHolidays',
    name: 'Holidays',
    route: ROUTES.HOLIDAYS,
    Component: <ManageHolidays />,
    role: 'superAdmin',
    tokenRequired: true
  },  
  {
    key: 'SuperAdminManagers',
    name: 'Managers',
    route: ROUTES.MANAGERS,
    Component: <ManageManagers />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminCustomers',
    name: 'Customers',
    route: ROUTES.CUSTOMERS,
    Component: <ManageCustomers />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminDonations',
    name: 'Donations',
    route: ROUTES.DONATIONS,
    Component: <ManageDonations />,

    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrders',
    name: 'Orders',
    route: ROUTES.ORDERS,
    Component: <ManageOrders />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminStoreOrders',
    name: 'Store Orders',
    route: ROUTES.STORE_ORDERS,
    Component: <ManageStoreOrders />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminOrdersProducts',
    name: 'Order Products',
    route: ROUTES.STORE_ORDER_PRODUCTS,
    Component: <ManageOrderProducts />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminBankTransfers',
    name: 'Bank Transfers',
    route: ROUTES.BANK_TRANSFERS,
    Component: <ManageBankTransfers showStore tableColumns={transfersAdminColumns}/>,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminTransferOrders',
    name: 'Transfer Orders',
    route: ROUTES.TRANSFER_ORDERS,
    Component: <ManageTransferOrders showStore/>,
    role: 'superAdmin',
    tokenRequired: true
  },  
  {
    key: 'SuperAdminTags',
    name: 'Categories',
    route: ROUTES.TAGS,
    Component: <ManageTags />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminEmailTemplates',
    name: 'Email Templates',
    route: ROUTES.TEMPLATES,
    Component: <ManageEmailTemplates />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminSupport',
    name: 'Support',
    route: ROUTES.SUPPORT,
    Component: <ManageSupportTickets />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminShopReports',
    name: 'Store Reports',
    route: ROUTES.SHOP_REPORTS,
    Component: <ManageStoreReports />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminSettings',
    name: 'Settings',
    route: ROUTES.SETTINGS,
    Component: <Settings />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminLogout',
    name: 'Logout',
    route: ROUTES.LOGOUT,
    Component: <Logout />,
    role: 'superAdmin',
    tokenRequired: true
  },
  {
    key: 'SuperAdminShopDashboard',
    name: 'Dashboard',
    route: ROUTES.SHOP_DASHBOARD,
    Component: <ManageShopDashboard />,
    role: 'superAdmin',
    tokenRequired: true
  }  
]

function App() {
  const { role } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { pusherClient } = usePusher()
  const isEditingShop = useSelector(selectIsEditingShop)

  useEffect(() => {
    if (isEditingShop) {
      dispatch(setEditMode(false))
    }
  }, [])
  // eslint-disable-next-line no-console
  return (
    <PusherProvider value={pusherClient}>
      <AppWrap>
        <Switch>
          {/* AUTH SCREENS - no token required to access */}
          <Route path={ROUTES.CREATE_NEW_PASSWORD}>
            <AuthHoc>
              <CreateNewPassword />
            </AuthHoc>
          </Route>
          <Route path="/auth/">
            <AuthHoc>
              <Switch>
                {publicRoutes.map((navItem) => (
                  <Route key={navItem.key} path={navItem.route}>
                    {navItem.Component}
                  </Route>
                ))}
                {/* Redirect to login if url not found */}
                <FallbackRoute fallbackPath={ROUTES.LOGIN} />
              </Switch>
            </AuthHoc>
          </Route>
          {/* MAIN SCREENS - requires token to access */}
          <PrivateRoute path="/main/" fallbackRoute={ROUTES.DASHBOARD}>
            <NavigationHoc>
              <Switch>
                {appRoutes.filter(navItem => navItem.role === role).map((navItem) => (
                  <Route key={navItem.key} path={navItem.route}>
                    {navItem.Component}
                  </Route>
                ))}
                <Route path={ROUTES.STRIPE_CONNECT}>
                  <StripeConnect />
                </Route>
                <Route path={ROUTES.SHOP_PROFILE}>
                  <ShopPageHoc>
                    <Switch>
                      {shopRoutes.map((navItem) => (
                        <Route exact key={navItem.key} path={navItem.route}>
                          {navItem.Component}
                        </Route>
                      ))}
                      {/* Redirect to about shop for any /shop/... url that is not found */}
                      <FallbackRoute fallbackPath={ROUTES.SHOP_PROFILE} />
                    </Switch>
                  </ShopPageHoc>
                </Route>
                <Route path={ROUTES.SHOP}>
                  <ShopPageHocAdmin>
                    <Switch>
                      {shopAdminRoutes.map((navItem) => (
                        <Route exact key={navItem.key} path={navItem.route}>
                          {navItem.Component}
                        </Route>
                      ))}
                      {/* Redirect to about shop for any /shop/... url that is not found */}
                      <FallbackRoute fallbackPath={ROUTES.SHOP} />
                    </Switch>
                  </ShopPageHocAdmin>
                </Route>
                {/* Redirect to dashboard for any /main/... URL that is not found */}
                <FallbackRoute fallbackPath={ROUTES.DASHBOARD} />
              </Switch>
            </NavigationHoc>
          </PrivateRoute>
          {/* Redirect to dashboard for any /... URL that is not found */}
          <FallbackRoute fallbackPath={ROUTES.DASHBOARD} />
        </Switch>
      </AppWrap>
    </PusherProvider>
  )
}

export default App
