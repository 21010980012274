import styled from 'styled-components'
import Typography from '../UI/Typography'
import { HStack, VStack } from '../../global/styles'
import Button from '../UI/Buttons/Button'

export const Headline = styled(Typography).attrs({
  variant: 'headingL',
  bold: true
})`
  margin-bottom: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`

export const Description = styled(Typography).attrs({
  variant: 'textM'
})`
  text-align: center;
  color: ${({ theme }) => theme.colors.skyNeutral};
`

export const ModalHeader = styled(VStack)`
  width: 400px;
  align-items: center;
  justify-content: flex-start;
`
export const ConfirmButton = styled(Button).attrs(({ theme }) => ({
  fillColor: theme.colors.yellowBase
}))``

export const ButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }
`
export const ModalWrap = styled(VStack)`
  padding: 24px;
`
export const ErrorWrap = styled(Typography).attrs({
  variant: 'textS'
})`
  text-align: center;
  color: red;
`