/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import { MessagesContainer } from './styles'
import RoomsList from '../RoomsList'
import ChatContainer from '../ChatContainer'
import withPusher from 'react-pusher-hoc'
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary'
import { ChatWrapper } from '../ChatContainer/styles'
// import WrappedChatContainer from '../WrappedChatContainer'

const MessagesHoc = () => {
  const [selectedRoomId, setSelectedRoomId] = useState(null)
  const [receiverName, setReceiverName] = useState('')
  const [receiverAvatar, setReceiverAvatar] = useState(null)
  const [preferredPronouns, setPreferredPronouns] = useState("")
  
  const [mapEventsToProps, setMapEventsToProps] = useState({
    mapPropsToValues: () => ({ items: [] }),
    events: {}
  })

  const onDeleteRoom = (roomId) => {
    setSelectedRoomId(null)
  }
  
  useEffect(() => {
    if (selectedRoomId) {
      setMapEventsToProps({
        mapPropsToValues: (props) => ({
          items: []
        }),
        events: {
          [`private-chat-${selectedRoomId}.App\\Events\\NewChatMessage`]: (
            item
          ) => {
            return {
              items: [item]
            }
          }
        }
      })
    }
  }, [selectedRoomId])
  const WrappedChatContainer = withPusher(mapEventsToProps)(ChatContainer)

  return (
    <MessagesContainer>
      <ErrorBoundary>
        <RoomsList
          {...{
            selectedRoomId,
            setSelectedRoomId,
            setReceiverName,
            setReceiverAvatar,
            setPreferredPronouns
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <ChatWrapper>
          <WrappedChatContainer
            roomId={selectedRoomId}
            preferredPronouns={preferredPronouns}
            {...{ receiverName, receiverAvatar }}
            onDeleteRoom={onDeleteRoom}
          />
        </ChatWrapper>
      </ErrorBoundary>
    </MessagesContainer>
  )
}

export default MessagesHoc

MessagesHoc.propTypes = {}
