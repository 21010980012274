/*eslint-disable*/
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// import { REST_API_KEY, REST_TOKEN } from '../const/hereApi'

const baseQuery = fetchBaseQuery({
  baseUrl: ' https://geocode.search.hereapi.com/v1/',
  prepareHeaders: (headers, { getState }) => {
    headers.set('Authorization', `Bearer ${getState().auth.hereWeGoToken}`)
    return headers
  }
})

const hereWegoApiSlice = createApi({
  reducerPath: 'hereApi',
  baseQuery,
  endpoints: (build) => ({
    getAddressSuggestions: build.query({
      query: ({ query }) => ({
        url: `/geocode?q=${query}&in=countryCode%3AAUS`,
        method: 'GET'
      })
    })
  }),
  tagTypes: [
    'ShopDetails',
    'Categories',
    'ShopProducts',
    'ShopPosts',
    'ShopDonations',
    'ShopGallery',
    'ShopDashboard',
    'Messages',
    'Rooms'
  ]
})
export default hereWegoApiSlice

export const { useGetAddressSuggestionsQuery } = hereWegoApiSlice
