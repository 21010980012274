import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const ContentWrap = styled(VStack)`
  padding: 32px;
`
export const ActionButtonsSection = styled.div`
  display: flex;
  padding: 16px 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 368px;
`

export const Headline = styled(Typography)`
  margin-bottom: 47px;
`
