import styled from 'styled-components'
import { HStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const MessagesContainer = styled(HStack)`
  width: 100%;
  height: 100vh;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
`
