import React from 'react'
import PropTypes from 'prop-types'
import { CheckBox, CheckBoxOutlineBlank } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { omit } from 'lodash/object'
import { StyledLabel } from './styles'

const TableCheckboxCell = ({ row, cell, theme }) => (
  <StyledLabel htmlFor={cell.row.id} style={{ padding: '12px' }}>
    {row.getToggleRowSelectedProps().checked ? (
      <CheckBox size={24} color={theme.colors.skyBase} />
    ) : (
      <CheckBoxOutlineBlank size={24} color={theme.colors.skyNeutral2} />
    )}
    <input
      type="checkbox"
      id={cell.row.id}
      {...omit(row.getToggleRowSelectedProps(), 'indeterminate')}
      hidden
    />
  </StyledLabel>
)

export default withTheme(TableCheckboxCell)

TableCheckboxCell.propTypes = {
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired
}
