import React from 'react'
import { TableText } from '../../../global/styles'
import { truncateString } from '../../../utils/helpers'

const StoreReportTableRow = (report) => ({
  reportId: report.id,
  storeName: <TableText>{report.storeName}</TableText>,
  userName: <TableText>{report.userName}</TableText>,
  reasonName: <TableText>{report.reasonName}</TableText>,
  description: <TableText>{truncateString(report.description, 30)}</TableText>
})

export default StoreReportTableRow

StoreReportTableRow.propTypes = {}
