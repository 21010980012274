import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

// eslint-disable-next-line import/prefer-default-export
export const MessageWrap = styled(HStack)`
  margin-bottom: 26px;
  max-width: 50%;
`
export const MessageTextWrap = styled(VStack)`
  min-height: 18px;
  border-radius: 16px;
  padding: 16px 24px;
  margin-left: ${({ isOwnMessage }) => (isOwnMessage ? 0 : '20px')};
  position: relative;
  background-color: ${({ isOwnMessage, theme }) =>
    isOwnMessage ? theme.colors.skyBase4 : theme.colors.greenCreator(14, 97)};
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    ${({ theme, isOwnMessage }) =>
      isOwnMessage
        ? `
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-left:18px solid ${theme.colors.skyBase4};
        right:-16px;
        `
        : `
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-right:18px solid ${theme.colors.greenCreator(14, 97)};
        left:-18px;
        top:18px;
    `}
  }
`
export const MessageText = styled(Typography)`
  word-wrap: normal;
  word-break: break-all;
  overflow-wrap: anywhere;
  max-width: 100%;
  color: ${({ isOwnMessage, theme }) =>
    isOwnMessage ? theme.colors.skyBase : theme.colors.skyNeutral};
`
export const TimeStamp = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral2};
  margin-left: auto;
`
export const TimeAndStatusWrap = styled(HStack)`
`
export const ChatBubbleContainer = styled(HStack)`
  width: 100%;
  max-width: 100%;
`

export const UserAvatarWrap = styled(VStack)`
  justify-content: center;
  align-items: center;
  margin-top: 18px;
`

export const MessageImage = styled.img`
  max-width: 100%;
`
