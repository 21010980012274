import Pusher from 'pusher-js'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { BASE_URL } from '../const/api'

export const pusherEvents = {
  eventName: 'eventName'
}

// const pusherClient = new Pusher(
//   '30a1c66506b7a0db358e',
//   {
//     cluster: "ap4",
//     authEndpoint: `https://staging.ophop.com.au/api/v1/broadcasting/auth`,
//     auth: { headers: { Authorization: "Bearer 220|kJjFU5VcmOf9JQcFVomZk1JKUiMHbPojFGcyWcnQ" } },
//   }
// )

export const usePusher = () => {
  const token = useSelector((state) => state.auth.token)
  const [pusherClient, setPusherClient] = useState(null)
  useEffect(() => {
    if (token) {
      setPusherClient(
        new Pusher('3a6e8a2b4fa47e629a65', {
          cluster: 'ap4',
          authEndpoint: `${BASE_URL}/broadcasting/auth`,
          auth: { headers: { Authorization: `Bearer ${token}` } }
        })
      )
    }
  }, [token])

  return { pusherClient }
}

// export default pusherClient
