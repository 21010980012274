import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../config/axios'

export const api = createApi({
  // reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    // shop
    'ShopDetails',
    'Categories',
    'ShopProducts',
    'ShopProduct',
    'ShopPosts',
    'ShopPost',
    'ShopHolidays',
    'ShopHoliday',
    'ShopDonations',
    'ShopDonation',
    'ShopGallery',
    'ShopVolunteers',
    'ShopVolunteer',
    'ShopQuestions',
    'ShopQuestion',
    'ShopVideos',
    'ShopDashboard',
    'ShopOrders',
    'ShopOrder',
    'ShopTags',
    'ShopTag',
    'Messages',
    'Rooms',
    'Contacts',
    'Contact',

    // super admin
    'Settings', 
    'Organizations',
    'Organization',
    'OrganizationProducts',
    'OrganizationProduct',
    'OrganizationProductStores',
    'OrganizationPosts',
    'OrganizationPost',
    'OrganizationPostStores',
    'OrganizationQuestions',
    'OrganizationQuestion',
    'OrganizationQuestionStores',
    'OrganizationHolidays',
    'OrganizationHoliday',
    'OrganizationHolidayStores',
    'OrganizationShops',
    'Donations',
    'Donation',
    'Shops',
    'Shop',
    'Managers',
    'Manager',
    'Customers',
    'Customer',
    'Cities',
    'StoreReports',
    'StoreReport',
    'Tags',
    'Tickets',
    'Ticket',
    'Templates',
    'Orders',
    'Order',
    'StoreOrders',
    'StoreOrder',
    'Transfers',
    'Transfer',
    'OrganizationDashboard'
  ],
  endpoints: (build) => ({
    // shop
    shopSettings: build.mutation({
      query: ({ userId, data }) => ({
        url: `/shop/settings/${userId}`,
        method: 'put',
        data
      })
    }),
    getHereToken: build.query({
      query: () => ({
        url: `/hereToken`,
        method: 'GET'
      })
    }),
    getContacts: build.query({
      query: ({ search, page, limit }) => ({ url: `/shop/contacts?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Contacts']
    }),
    getContact: build.query({
      query: (contactId) => ({ url: `/shop/contacts/${contactId}` }),
      providesTags: ['Contact']
    }),    
    postChatMessageToShop: build.mutation({
      query: (queryArg) => ({
        url: `/shop/chat/shop/${queryArg.shopId}/message`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    deleteChatMessage: build.mutation({
      query: (messageId) => ({
        url: `/shop/chat/message/${messageId}`,
        method: 'delete'
      }),
      invalidatesTags: ['Messages', 'Rooms']
    }),

    // super admin
    postLogin: build.mutation({
      query: (queryArg) => ({
        url: `/admin/login`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postSetNewPassword: build.mutation({
      query: (queryArg) => ({
        url: `/setNewPassword`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postForgotPasswordRequest: build.mutation({
      query: (queryArg) => ({
        url: `/forgotPasswordRequest`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getDashboardData: build.query({
      query: ({ timeFrame, from, to }) => ({ url: `/admin/dashboard/${timeFrame}?from=${from}&to=${to}` })
    }),

    getOrganizations: build.query({
      query: () => ({ url: `/admin/organizations/all` }),
      providesTags: ['Organizations']
    }),
    getSearchOrganizations: build.query({
      query: ({ search, page, limit }) => ({ url: `/admin/organizations?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Organizations']
    }),
    getOrganization: build.query({
      query: (organizationId) => ({ url: `/admin/organizations/${organizationId}` }),
      providesTags: ['Organization']
    }),
    postOrganization: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organizations`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Organizations']
    }),
    patchOrganization: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organizations/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Organizations', 'Organization']
    }),    
    postDeleteOrganizations: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organizations/delete`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Organizations', 'Organization']
    }),

    /*
    getOrganizationProducts: build.query({
      query: () => ({ url: `/admin/organization-products` }),
      providesTags: ['OrganizationProducts']
    }),
    */
    getOrganizationProducts: build.query({
      query: ({ organizationId, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/products?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationProducts']
    }),
    getOrganizationProduct: build.query({
      query: (productId) => ({ url: `/admin/organization-products/${productId}` }),
      providesTags: ['OrganizationProduct']
    }),
    postOrganizationProduct: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-products`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationProducts']
    }),
    putOrganizationProduct: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-products/${queryArg.organizationProductId}`,
        method: 'put',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationProducts', 'OrganizationProduct', 'ShopProducts'],
    }),
    deleteOrganizationProduct: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-products/${queryArg.productId}`,
        method: 'delete'
      }),
      invalidatesTags: ['OrganizationProducts']
    }),    
    postDeleteOrganizationProducts: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-products/delete`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationProducts']
    }),
    postOrganizationProductsToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-products/shops`,
        method: 'post',
        body: queryArg.body
      })
    }),
    deleteOrganizationProductsToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-products/shops/delete`,
        method: 'delete',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationProductStores', 'ShopProducts']
    }),
    getOrganizationProductStores: build.query({
      query: ({ productId, search, page, limit }) => ({ url: `/admin/organization-products/${productId}/stores?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationProductStores']
    }),

    getOrganizationPost: build.query({
      query: (postId) => ({ url: `/admin/organization-posts/${postId}` }),
      providesTags: ['OrganizationPost']
    }),
    getOrganizationPosts: build.query({
      query: ({ organizationId, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/posts?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationPosts']
    }),
    postOrganizationPost: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-posts`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationPosts']
    }),
    putOrganizationPost: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-posts/${queryArg.organizationPostId}`,
        method: 'put',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationPosts', 'OrganizationPost'],
    }),
    deleteOrganizationPost: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-posts/${queryArg.postId}`,
        method: 'delete'
      }),
      invalidatesTags: ['OrganizationPosts']
    }),    
    postDeleteOrganizationPosts: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-posts/delete`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationPosts']
    }),
    postOrganizationPostsToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-posts/shops`,
        method: 'post',
        body: queryArg.body
      }),
    }),
    deleteOrganizationPostsToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-posts/shops/delete`,
        method: 'delete',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationPostStores', 'ShopPosts']
    }),
    getOrganizationPostStores: build.query({
      query: ({ postId, search, page, limit }) => ({ url: `/admin/organization-posts/${postId}/stores?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationPostStores']
    }),

    getOrganizationQuestions: build.query({
      query: ({ organizationId, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/questions?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationQuestions']
    }),
    getOrganizationQuestion: build.query({
      query: (questionId) => ({ url: `/admin/organization-questions/${questionId}` }),
      providesTags: ['OrganizationQuestion']
    }),
    postOrganizationQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-questions`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationQuestions']
    }),
    putOrganizationQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-questions/${queryArg.organizationQuestionId}`,
        method: 'put',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationQuestions', 'OrganizationQuestion'],
    }),
    deleteOrganizationQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-questions/${queryArg.questionId}`,
        method: 'delete'
      }),
      invalidatesTags: ['OrganizationQuestions']
    }),
    postDeleteOrganizationQuestions: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-questions/delete`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationQuestions']
    }),
    postOrganizationQuestionsToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-questions/shops`,
        method: 'post',
        body: queryArg.body
      })
    }),
    deleteOrganizationQuestionsToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-questions/shops/delete`,
        method: 'delete',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationQuestionStores', 'ShopQuestions']
    }),
    getOrganizationQuestionStores: build.query({
      query: ({ questionId, search, page, limit }) => ({ url: `/admin/organization-questions/${questionId}/stores?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationQuestionStores']
    }),

    getOrganizationHolidays: build.query({
      query: ({ organizationId, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/holidays?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationHolidays']
    }),
    getOrganizationHoliday: build.query({
      query: (holidayId) => ({ url: `/admin/organization-holidays/${holidayId}` }),
      providesTags: ['OrganizationHoliday']
    }),
    postOrganizationHoliday: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-holidays`,
        method: 'post',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationHolidays']
    }),
    putOrganizationHoliday: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-holidays/${queryArg.organizationHolidayId}`,
        method: 'put',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationHolidays', 'OrganizationHoliday'],
    }),
    deleteOrganizationHoliday: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-holidays/${queryArg.holidayId}`,
        method: 'delete'
      }),
      invalidatesTags: ['OrganizationHolidays']
    }),
    postDeleteOrganizationHolidays: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-holidays/delete`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationHolidays']
    }),
    postOrganizationHolidaysToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-holidays/shops`,
        method: 'post',
        body: queryArg.body
      })
    }),
    deleteOrganizationHolidaysToShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organization-holidays/shops/delete`,
        method: 'delete',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationHolidayStores', 'ShopHolidays']
    }),
    getOrganizationHolidayStores: build.query({
      query: ({ holidayId, search, page, limit }) => ({ url: `/admin/organization-holidays/${holidayId}/stores?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['OrganizationHolidayStores']
    }),

    getDonations: build.query({
      query: ({ organizationId, timeFrame, from, to, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/donations?timeframe=${timeFrame}&from=${from}&to=${to}&page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Donations']
    }),
    getDonation: build.query({
      query: (donationId) => ({ url: `/admin/donations/${donationId}` }),
      providesTags: ['Donation']
    }),

  /*
    getOrganizationUsers: build.query({
      query: (queryArg) => ({ url: `/admin/organizations/${queryArg.organizationId}/users` }),
      providesTags: ['OrganizationUsers']
    }),
    postOrganizationUsers: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organizations/${queryArg.organizationId}/users`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['OrganizationUsers']
    }),
    postDeleteOrganizationUsers: build.mutation({
      query: (queryArg) => ({
        url: `/admin/organizations/${queryArg.organizationId}/users/${queryArg.userId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: ['OrganizationUsers']
    }),
    */
    getManagers: build.query({
      query: ({ search, page, limit }) => ({ url: `/admin/managers?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Managers']
    }),
    getManager: build.query({
      query: (userId) => ({ url: `/admin/managers/${userId}` }),
      providesTags: ['Manager']
    }),
    postManager: build.mutation({
      query: (queryArg) => ({
        url: `/admin/managers`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Managers']
    }),
    putManager: build.mutation({
      query: (queryArg) => ({
        url: `/admin/managers/${queryArg.userId}`,
        method: 'put',
        body: queryArg.body
      }),
      invalidatesTags: ['Managers', 'Manager']
    }),    
    postDeleteManagers: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteUsers`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Managers']
    }),
    patchManagersByUserId: build.mutation({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Managers']
    }),
    getUsers: build.query({
      query: ({ search, page, limit }) => ({ url: `/admin/users?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Customers']
    }),
    getUser: build.query({
      query: (userId) => ({ url: `/admin/users/${userId}` }),
      providesTags: ['Customer']
    }),    
    /*
    getUserList: build.query({
      query: () => ({ url: `/admin/users/all` })
    }),
    */
    postResetUserPasswordByUserId: build.mutation({
      query: (queryArg) => ({
        url: `/resetUserPassword/${queryArg.userId}`,
        method: 'POST'
      })
    }),
    patchUsersByUserId: build.mutation({
      query: (queryArg) => ({
        url: `/admin/users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Customers', 'Customer']      
    }),
    postDeleteUsers: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteUsers`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Customers']
    }),
    getAllShops: build.query({
      query: () => ({ url: `/admin/shops/all` }),
      providesTags: ['Shops']
    }),
    getShops: build.query({
      query: ({cityId, search, page, limit} ) => ({ url: `/admin/cities/${cityId}/shops?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Shops']
    }),
    getShop: build.query({
      query: (shopId) => ({ url: `/admin/shops/${shopId}` }),
      providesTags: ['Shop']
    }),
    postShop: build.mutation({
      query: (queryArg) => ({
        url: `/admin/shops`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Shops', 'Cities']
    }),
    patchShopsByShopId: build.mutation({
      query: (queryArg) => ({
        url: `/admin/shops/${queryArg.shopId}`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Shops']
    }),
    postDeleteShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteShops`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Shops']
    }),
    postDeactivateShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/shops/deactivate`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Shops']
    }),
    patchShops: build.mutation({
      query: (queryArg) => ({
        url: `/admin/shops`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Shops']
    }),
    getOrganizationShops: build.query({
      query: (organizationId) => ({ url: `/admin/organizations/${organizationId}/shops` }),
      providesTags: ['OrganizationShops']
    }),
    getTags: build.query({
      query: () => ({ url: `/admin/tags` }),
      providesTags: ['Tags']
    }),
    postDeleteTags: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteTags`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Tags']
    }),
    postTag: build.mutation({
      query: (queryArg) => ({
        url: `/admin/tags`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Tags']
    }),
    getEmailTemplates: build.query({
      query: () => ({ url: `/admin/email-templates` }),
      providesTags: ['Templates']
    }),
    postEmailTemplate: build.mutation({
      query: (queryArg) => ({
        url: `/admin/emailTemplate`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    patchEmailTemplateByTemplateId: build.mutation({
      query: (queryArg) => ({
        url: `/admin/email-templates/${queryArg.templateId}`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Templates']
    }),
    postDeleteEmailTemplates: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteEmailTemplates`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getSupportTickets: build.query({
      query: ({page, limit}) => ({ url: `/admin/support-tickets?page=${page}&limit=${limit}` }),
      providesTags: ['Tickets']
    }),
    getSupportTicket: build.query({
      query: (id) => ({ url: `/admin/support-tickets/${id}` }),
      providesTags: ['Ticket']
    }),
    patchSupportTicketByTicketId: build.mutation({
      query: (queryArg) => ({
        url: `/admin/support-tickets/${queryArg.ticketId}`,
        method: 'PATCH',
        body: queryArg.body
      }),
      invalidatesTags: ['Tickets', 'Ticket']
    }),
    postDeleteSupportTickets: build.mutation({
      query: (queryArg) => ({
        url: `/admin/delete-support-tickets`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Tickets']
    }),
    postReplyTicket: build.mutation({
      query: ({ body, ticketId }) => ({
        url: `admin/reply-ticket/${ticketId}`,
        method: 'POST',
        body
      })
    }),
    getStoreReports: build.query({
      query: ({ page, limit }) => ({ url: `/admin/store-reports?page=${page}&limit=${limit}` }),
      providesTags: ['StoreReports']
    }),
    getStoreReport: build.query({
      query: (id) => ({ url: `/admin/store-reports/${id}` }),
      providesTags: ['StoreReport']
    }),
    postDeleteStoreReports: build.mutation({
      query: (queryArg) => ({
        url: `/admin/delete-store-reports`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['StoreReports']
    }),
    getSettings: build.query({
      query: () => ({ url: `/admin/settings` }),
      providesTags: ['Settings']
    }),
    postSettings: build.mutation({
      query: (queryArg) => ({
        url: `/admin/settings`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['Settings']
    }),
    getOrders: build.query({
      query: ({ timeFrame, from, to, search, page, limit }) => ({ url: `/admin/orders?timeframe=${timeFrame}&from=${from}&to=${to}&page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Orders']
    }),
    getOrder: build.query({
      query: (id) => ({ url: `/admin/orders/${id}/show` }),
      providesTags: ['Order']
    }),    
    getStoreOrders: build.query({
      query: ({ timeFrame, from, to, search, page, limit }) => ({ url: `/admin/store-orders?timeframe=${timeFrame}&from=${from}&to=${to}&page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['StoreOrders']
    }),
    getStoreOrder: build.query({
      query: (id) => ({ url: `/admin/store-orders/${id}/show` }),
      providesTags: ['StoreOrder']
    }),    
    getStoreOrderProducts: build.query({
      query: (queryArg) => ({ url: `/admin/store-orders/${queryArg.orderId}/products` })
    }),
    getTransfers: build.query({
      query: ({ timeFrame, from, to, search, page, limit }) => ({ url: `/admin/transfers?timeframe=${timeFrame}&from=${from}&to=${to}&page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['Transfers']
    }),
    getTransfer: build.query({
      query: (id) => ({ url: `/admin/transfers/${id}/show` }),
      providesTags: ['Transfer']
    }),    
    getTransferOrders: build.query({
      query: ({ transferId, page, limit }) => ({ url: `/admin/transfers/${transferId}/orders?page=${page}&limit=${limit}` })
    }),

    forgotPassword: build.mutation({
      query: (data) => ({
        url: '/admin/forgot-password',
        method: 'post',
        body: data
      })
    }),
    createNewPassword: build.mutation({
      query: (data) => ({
        url: '/admin/reset-password',
        method: 'post',
        body: data
      })
    }),
    getCities: build.query({
      query: () => ({
        url: 'admin/cities',
        method:'get',
      }),
      providesTags: ['Cities']
    })    
  })
})
export const {
  // shop
  useShopSettingsMutation, 
  useGetHereTokenQuery,
  useGetContactsQuery,
  useLazyGetContactsQuery,
  useGetContactQuery,
  usePostChatMessageToShopMutation,
  useDeleteChatMessageMutation,

  // super admin
  usePostLoginMutation,
  usePostSetNewPasswordMutation,
  usePostForgotPasswordRequestMutation,
  useGetDashboardDataQuery,
  useLazyGetDashboardDataQuery,

  useGetSearchOrganizationsQuery,
  useLazyGetSearchOrganizationsQuery,
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
  usePostOrganizationMutation,
  usePatchOrganizationMutation,
  usePostDeleteOrganizationsMutation,

  useGetOrganizationProductQuery,
  useLazyGetOrganizationProductQuery,
  useGetOrganizationProductsQuery,
  useLazyGetOrganizationProductsQuery,
  usePostOrganizationProductMutation,
  usePutOrganizationProductMutation,
  useDeleteOrganizationProductMutation,
  usePostDeleteOrganizationProductsMutation,
  usePostOrganizationProductsToShopsMutation,
  useDeleteOrganizationProductsToShopsMutation,
  useGetOrganizationProductStoresQuery,
  useLazyGetOrganizationProductStoresQuery,

  useGetOrganizationPostsQuery,
  useLazyGetOrganizationPostsQuery,
  useGetOrganizationPostQuery,
  useLazyGetOrganizationPostQuery,
  usePostOrganizationPostMutation,
  usePutOrganizationPostMutation,
  useDeleteOrganizationPostMutation,
  usePostOrganizationPostsToShopsMutation,
  usePostDeleteOrganizationPostsMutation,
  useDeleteOrganizationPostsToShopsMutation,
  useGetOrganizationPostStoresQuery,
  useLazyGetOrganizationPostStoresQuery,

  useGetOrganizationQuestionsQuery,
  useLazyGetOrganizationQuestionsQuery,
  useGetOrganizationQuestionQuery,
  useLazyGetOrganizationQuestionQuery,
  usePostOrganizationQuestionMutation,
  usePutOrganizationQuestionMutation,
  useDeleteOrganizationQuestionMutation,
  usePostOrganizationQuestionsToShopsMutation,
  usePostDeleteOrganizationQuestionsMutation,
  useDeleteOrganizationQuestionsToShopsMutation,
  useGetOrganizationQuestionStoresQuery,
  useLazyGetOrganizationQuestionStoresQuery,

  useGetOrganizationHolidaysQuery,
  useLazyGetOrganizationHolidaysQuery,
  useGetOrganizationHolidayQuery,
  useLazyGetOrganizationHolidayQuery,
  usePostOrganizationHolidayMutation,
  usePutOrganizationHolidayMutation,
  useDeleteOrganizationHolidayMutation,
  usePostOrganizationHolidaysToShopsMutation,
  usePostDeleteOrganizationHolidaysMutation,
  useDeleteOrganizationHolidaysToShopsMutation,
  useGetOrganizationHolidayStoresQuery,
  useLazyGetOrganizationHolidayStoresQuery,

  useGetDonationsQuery,
  useLazyGetDonationsQuery,
  useGetDonationQuery,

  useGetOrganizationUsersQuery,
  usePostOrganizationUsersMutation,  
  usePostDeleteOrganizationUsersMutation,  
  useGetManagerQuery,
  useLazyGetManagerQuery,
  useGetManagersQuery,
  useLazyGetManagersQuery,
  usePostManagerMutation,
  usePutManagerMutation,
  usePostDeleteManagersMutation,
  usePatchManagersByUserIdMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserQuery,
  // useGetUserListQuery,
  usePostResetUserPasswordByUserIdMutation,
  usePatchUsersByUserIdMutation,
  usePostDeleteUsersMutation,
  useGetAllShopsQuery,
  useGetShopsQuery,
  useLazyGetShopsQuery,
  useGetShopQuery,
  usePostShopMutation,
  usePatchShopsByShopIdMutation,
  usePatchShopsMutation,
  usePostDeleteShopsMutation,

  useGetOrganizationShopsQuery,
  usePostDeactivateShopsMutation,
  useGetTagsQuery,
  usePostDeleteTagsMutation,
  usePostTagMutation,
  useGetEmailTemplatesQuery,
  usePostEmailTemplateMutation,
  usePatchEmailTemplateByTemplateIdMutation,
  usePostDeleteEmailTemplatesMutation,
  useGetSupportTicketsQuery,
  useLazyGetSupportTicketsQuery,
  useGetSupportTicketQuery,
  usePatchSupportTicketByTicketIdMutation,
  usePostDeleteSupportTicketsMutation,
  usePostReplyTicketMutation,
  useGetStoreReportsQuery,
  useLazyGetStoreReportsQuery,
  useGetStoreReportQuery,
  usePostDeleteStoreReportsMutation,  
  useGetSettingsQuery,
  usePostSettingsMutation,
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useGetOrderQuery,
  useGetStoreOrdersQuery,
  useLazyGetStoreOrdersQuery,
  useGetStoreOrderQuery,
  useGetStoreOrderProductsQuery,
  useGetTransfersQuery,
  useLazyGetTransfersQuery,
  useGetTransferQuery,
  useGetTransferOrdersQuery,
  useLazyGetTransferOrdersQuery,

  useForgotPasswordMutation,
  useCreateNewPasswordMutation,
  useGetCitiesQuery
} = api
