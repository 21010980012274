import React from 'react'
import {
  BADGE_SIZES,
  BADGE_VARIANTS
} from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { HStack, TableDarkText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'

const ProductTableRow = (product) => ({
  productId: product.id,
  productName: (
    <TableImageLabelCell
      image={product.productImage}
      label={product.productName}
      isRounded={false}
    />
  ),
  shop: <TableDarkText>{product.shopName}</TableDarkText>,
  price: <TableDarkText>${product.productPrice}</TableDarkText>,
  shippingCost: <TableDarkText>${product.shippingCost}</TableDarkText>,
  isShippingEnabled: (
    <Badge
      key="isShippingEnabled"
      variant={product.isShippingEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
      size={BADGE_SIZES.SMALL}
      label={product.isShippingEnabled ? "Yes" : "No"}
    />
  ),
  isCollectEnabled: (
    <Badge
      key="isCollectEnabled"
      variant={product.isCollectEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
      size={BADGE_SIZES.SMALL}
      label={product.isCollectEnabled ? "Yes" : "No"}
    />
  ),  
  quantity: <TableDarkText>{product.quantity}</TableDarkText>,
  productTags: (
    <HStack>
      {product.tags.map((tag) => (
        <Badge
          key={tag}
          label={tag}
          variant={BADGE_VARIANTS.LIGHT}
          size={BADGE_SIZES.SMALL}
        />
      ))}
    </HStack>
  ),
  isOrganizationProduct: 
    product.isOrganizationProduct 
      ? <Badge label="Yes" variant={BADGE_VARIANTS.GREEN} size={BADGE_SIZES.SMALL}/>
      : '',
  status: (
    <Badge
      label={product.isActive ? 'Active' : 'Inactive'}
      variant={product.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
    />
  ),
  forSearch: `${product.productName}`
})

export default ProductTableRow

ProductTableRow.propTypes = {}
