import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'

export const PanelWrap = styled(VStack)`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  transition-delay: 0s;
  transition: flex-basis 0.3s ease-in-out;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${({ isOpen }) => (isOpen ? '336px' : 0)};
  box-shadow: inset 1px 0 0 rgba(95, 110, 130, 0.15);
  background: ${({ theme }) => theme.colors.white};
`
export const CloseIconWrap = styled(HStack)`
  align-items: center;
  margin-top: 45px;
  padding-right: 29px;
  justify-content: flex-end;
  width: 100%;
  color: ${({ theme }) => theme.colors.skyBase};
`
export const PanelContentWrap = styled(VStack)`
  padding: 40px 19px;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  position: relative;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.1s ease-in-out;
  transition-delay: ${({ isOpen }) => (isOpen ? 0.3 : 0)}s;
`
