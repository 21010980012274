import React from 'react'
import { Flag, Store, Unpublished } from '@styled-icons/material'

export const getMetrics = (data) => [
  {
    icon: <Store />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Order #',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: data.code
  },
  {
    icon: <Flag />,
    iconBgColor: 'pinkBase3',
    isActive: false,
    metricName: 'Date',
    numberAndIconColor: 'pinkBase',
    onClick: () => {},
    value: data.createdAt
  },
  {
    icon: <Unpublished />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Total',
    numberAndIconColor: 'greenBase',
    onClick: () => {},
    value: `$${data.total}`
  },
]

export const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Shipping Cost',
    accessor: 'shippingCost'
  }
]
