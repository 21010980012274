import { createSlice } from '@reduxjs/toolkit'
// import { USER_ROLE } from '../../const/app'
import { api } from '../../api/api.generated'

const authInitState = {
  token: null,
  userId: null,
  role: null,
  phone: null,
  timezone: null,
  email: '',
  welcomeMessage: 'Welcome to',
  hereWeGoToken: null,
  shopId: null,
  organizationId: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitState,
  reducers: {
    logout: () => authInitState,
    setWelcomeMessage: (state, { payload }) => {
      state.welcomeMessage = payload
    }
  },
  extraReducers: (builder) => {
    builder
      /*
      .addMatcher(
        api?.endpoints?.createAccount?.matchFulfilled,
        (state, { payload, meta }) => {
          state.token = payload?.token ?? ''
          state.userId = payload?.storeAdmin?.id ?? ''
          state.email = meta.arg.originalArgs.email
        }
      )
      .addMatcher(
        api?.endpoints?.login?.matchFulfilled,
        (state, { payload, meta }) => {
          state.token = payload.token
          state.userId = payload.userId
          state.email = meta.arg.originalArgs.email
        }
      )
      .addMatcher(
        api?.endpoints?.shopSettings?.matchFulfilled,
        (state, { meta }) => {
          state.email = meta?.arg?.originalArgs?.data?.email ?? state.email
        }
      )
      */
      .addMatcher(
        api?.endpoints?.getHereToken?.matchFulfilled,
        (state, { payload }) => {
          state.hereWeGoToken = payload.token
        }
      )
      .addMatcher(
        api?.endpoints?.postLogin?.matchFulfilled,
        (state, { payload, meta }) => {
          state.token = payload.token
          state.userId = payload.user_id
          state.role = payload.role
          state.shopId = payload.shop_id
          state.organizationId = payload.organization_id
          state.email = meta.arg.originalArgs.body.email
        }
      )
  }
})
export const { logout, setWelcomeMessage } = authSlice.actions
export default authSlice.reducer
