import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopVideos from '../ShopVideos'

const ShopProfileVideos = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopVideos shopId={shopId} />
  )
}

export default ShopProfileVideos

ShopProfileVideos.propTypes = {}
