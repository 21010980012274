import React from 'react'
import PropTypes from 'prop-types'
import { Done } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { WIZARD_LIST_ITEM_VARIANTS } from '../../const/UIvariants'
import { ListItemWrap, Tick } from './styles'
import Typography from '../UI/Typography'

const WizardListItem = ({ variant, position, text, isCompleted, theme }) => (
  <ListItemWrap>
    <Tick {...{ isCompleted }}>
      {variant === WIZARD_LIST_ITEM_VARIANTS.NUMBER && (
        <Typography
          variant="headingS"
          align="center"
          color={theme.colors.white}
        >
          {position.toString()}
        </Typography>
      )}
      {variant === WIZARD_LIST_ITEM_VARIANTS.CHECK &&
        (isCompleted ? (
          <Done size={20} color={theme.colors.white} />
        ) : (
          <Done size={20} color={theme.colors.skyNeutral2} />
        ))}
    </Tick>
    <Typography variant="textS" color={theme.colors.skyNeutral_1} align="left">
      {text}
    </Typography>
  </ListItemWrap>
)

export default withTheme(WizardListItem)

WizardListItem.defaultProps = {
  isCompleted: false
}
WizardListItem.propTypes = {
  variant: PropTypes.oneOf(Object.values(WIZARD_LIST_ITEM_VARIANTS)).isRequired,
  position: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool,
  theme: PropTypes.object.isRequired
}
