/*eslint-disable*/
import React from 'react'
import { CloseIconWrap, PanelContentWrap, PanelWrap } from './styles'
import { Close } from '@styled-icons/material'
import IconButton from '../UI/Buttons/IconButton'
import ErrorBoundary from '../../features/errorBoundaries/ErrorBoundary'

const DetailsAsidePanel = ({ children, isOpen = true, closePanel }) => {
  return (
    <PanelWrap {...{ isOpen }}>
      <CloseIconWrap>
        <IconButton onClick={closePanel}>
          <Close size={14} />
        </IconButton>
      </CloseIconWrap>
      <PanelContentWrap {...{ isOpen }}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </PanelContentWrap>
    </PanelWrap>
  )
}

export default DetailsAsidePanel

DetailsAsidePanel.propTypes = {}
