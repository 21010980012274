import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const RoomItemWrap = styled(HStack)`
  width: 100%;
  cursor: pointer;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.skyNeutral4 : 'transparent'};
`
export const RoomMessageWrap = styled(VStack)`
  margin-left: 15px;
  overflow: hidden;
  width: 100%;
`
export const TimeStampWrap = styled(HStack)`
  margin-top: 8px;
  width: 100%;
  justify-content: space-between;
`
export const ContactNameWrap = styled(HStack)`
`
export const Pronouns = styled(Typography).attrs({
  variant: 'textXS',
  bold: true
})`
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.skyNeutral};
`
