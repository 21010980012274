import { api as apiSlice } from './api.generated'

const dashboardApi = apiSlice.injectEndpoints({
  tagTypes: ['ShopDashboard', 'OrganizationDashboard'],
  endpoints: (build) => ({
    getShopDashboardDetails: build.query({
      query: ({ shopId, timeFrame, from, to }) => ({
        url: `/shop/${shopId}/dashboard/${timeFrame}?from=${from}&to=${to}`,
        method: 'get'
      }),
      providesTags: ['ShopDashboard']
    }),
    getOrganizationDashboard: build.query({
      query: ({ timeFrame, from, to }) => ({
        url: `/admin/organizations/dashboard/${timeFrame}?from=${from}&to=${to}`,
        method: 'get'
      }),
      providesTags: ['OrganizationDashboard']
    })
  })
})

// eslint-disable-next-line import/prefer-default-export
export const { 
  useGetShopDashboardDetailsQuery,
  useLazyGetShopDashboardDetailsQuery,
  useGetOrganizationDashboardQuery,
  useLazyGetOrganizationDashboardQuery  
} = dashboardApi
