import { api as apiSlice} from './api.generated'

const shopQuestionsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationShopQuestions: build.query({
      query: ({ organizationId, search, page, limit }) => ({ url: `/admin/organizations/${organizationId}/shop-questions?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['ShopQuestions']
    }),
    getShopQuestions: build.query({
      query: ({ shopId, page, limit }) => ({
        url: `/shop/${shopId}/questions?page=${page}&limit=${limit}`,
        method: 'get'
      }),
      providesTags: ['ShopQuestions']
    }),
    getShopQuestion: build.query({
      query: (quesionId) => ({ url: `/shop/questions/${quesionId}` }),
      providesTags: ['ShopQuestion']
    }),    
    postShopQuestion: build.mutation({
      query: (data) => ({
        url: `/shop/questions`,
        method: 'post',
        data
      }),
      invalidatesTags: ['ShopQuestions']
      /*
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopQuestions', undefined, (draft) => {
            draft.unshift(data)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      */
    }),
    putShopQuestion: build.mutation({
      query: ({ questionId, data }) => ({
        url: `/shop/questions/${questionId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopQuestions', 'ShopQuestion']
      /*
      async onQueryStarted({ questionId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopQuestions', undefined, (draft) => {
            const index = findIndex(draft, (item) => item.id === questionId)
            draft.splice(index, 1, data)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      */
    }),
    deleteShopQuestion: build.mutation({
      query: (questionId) => ({
        url: `/shop/questions/${questionId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopQuestions']
      /*
      async onQueryStarted(questionId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopQuestions', undefined, (draft) => {
            const index = findIndex(draft, (item) => item.id === questionId)
            draft.splice(index, 1)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
      */
    }),
    postDeleteShopQuestions: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deleteQuestions`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopQuestions']
    })
  }),

  overrideExisting: true
})

export const {
  useGetOrganizationShopQuestionsQuery,
  useLazyGetOrganizationShopQuestionsQuery,
  useGetShopQuestionsQuery,
  useLazyGetShopQuestionsQuery,
  useGetShopQuestionQuery,
  useLazyGetShopQuestionQuery,
  usePostShopQuestionMutation,
  usePutShopQuestionMutation,
  useDeleteShopQuestionMutation,
  usePostDeleteShopQuestionsMutation
} = shopQuestionsApi
