import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { formatDate } from '../../utils/helpers'
import Typography from '../UI/Typography'
import EditDeleteMenu from '../EditDeleteMenu/EditDeleteMenu'
import {
  QuestionWrap,
  QuestionDetailsWrap,
  QuestionTitleWrap
} from './styles'

const ShopHolidayItem = ({
  theme,
  id,
  name,
  dateFrom,
  dateTo,
  handleEdit,
  handleDelete,
  canEdit,
  canDelete  
}) => (
  <QuestionWrap>
    <QuestionDetailsWrap>
      <QuestionTitleWrap>
        <Typography variant="textXS" color={theme.colors.skyNeutral1}>
          {name}
        </Typography>        
        {canEdit && handleEdit && handleDelete && (
          <EditDeleteMenu
            onEdit={() => {
              handleEdit(id)
            }}
            onDelete={() => {
              handleDelete(id)
            }}
          />
        )}
        {!canEdit && canDelete && handleDelete && (
          <EditDeleteMenu
            onDelete={() => {
              handleDelete(id)
            }}
          />
        )}        
      </QuestionTitleWrap>
      <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
      From: {formatDate(dateFrom)}
      </Typography>
      <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
      To: {formatDate(dateTo)}
      </Typography>
    </QuestionDetailsWrap>
  </QuestionWrap>
)

export default withTheme(ShopHolidayItem)

ShopHolidayItem.defaultProps = {
  handleEdit: null,
  handleDelete: null,
  canEdit: true,
  canDelete: true,  
}

ShopHolidayItem.propTypes = {
  theme: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
}
