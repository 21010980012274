import { apiV1 } from '../config/axios'

export const setToken = (token) => {
  apiV1.defaults.headers.Authorization = `Bearer ${token}`
}

export const removeToken = (axios) => {
  // eslint-disable-next-line no-param-reassign
  delete axios.defaults.headers.Authorization
}
