import { api as apiSlice} from './api.generated'

const generalApi = apiSlice.injectEndpoints({
  tagTypes: ['Categories'],
  endpoints: (build) => ({
    getCategories: build.query({
      query: () => ({
        url: '/tags',
        method: 'get'
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue.tags
      },
      providesTags: ['Categories']
    })
  })
})

// eslint-disable-next-line import/prefer-default-export
export const { useGetCategoriesQuery } = generalApi
