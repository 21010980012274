import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { keys } from 'lodash/object'
import { IconWrap } from './styles'
import { TAG_ICONS } from '../../../const/app'

const SelectIcon = ({ icon, name }) => {
  const [field, meta] = useField({ name })
  return (
    <IconWrap htmlFor={icon} isActive={icon === meta.value}>
      {React.cloneElement(TAG_ICONS[icon], {
        size: 24,
        color: '#A0A7B5'
      })}
      <input {...field} value={icon} type="radio" id={icon} hidden />
    </IconWrap>
  )
}

export default SelectIcon

SelectIcon.propTypes = {
  icon: PropTypes.oneOf(keys(TAG_ICONS)).isRequired,
  name: PropTypes.string.isRequired
}
