import { api as apiSlice} from './api.generated'

const shopPostsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationShopPosts: build.query({
      query: ({organizationId, search, page, limit}) => ({ url: `/admin/organizations/${organizationId}/shop-posts?page=${page}&limit=${limit}&search=${search}` }),
      providesTags: ['ShopPosts']
    }),
    getShopPosts: build.query({
      query: ({shopId, page, limit}) => ({
        url: `/shop/${shopId}/posts?page=${page}&limit=${limit}`,
        method: 'get'
      }),
      providesTags: ['ShopPosts']
    }),
    getShopPost: build.query({
      query: (postId) => ({ url: `/shop/posts/${postId}` }),
      providesTags: ['ShopPost']
    }),    
    postShopPost: build.mutation({
      query: (data) => ({
        url: `/shop/posts`,
        method: 'post',
        data
      }),
      invalidatesTags: ['ShopPosts', 'ShopDashboard']
      /*
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopPosts', undefined, (draft) => {
            draft.unshift(data)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      */
    }),
    putShopPost: build.mutation({
      query: ({ postId, data }) => ({
        url: `/shop/posts/${postId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopPosts', 'ShopDashboard', 'ShopPost'],
      /*
      async onQueryStarted({ postId, data }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopPosts', undefined, (draft) => {
            const index = findIndex(draft, (post) => post.id === postId)
            draft.splice(index, 1, data)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
      */
    }),
    deleteShopPost: build.mutation({
      query: (postId) => ({
        url: `/shop/posts/${postId}`,
        method: 'delete'
      }),
      invalidatesTags: ['ShopPosts', 'ShopDashboard'],
      /*
      async onQueryStarted(postId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData('getShopPosts', undefined, (draft) => {
            const index = findIndex(draft, (post) => post.id === postId)
            draft.splice(index, 1)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
      */
    }),
    postDeletePosts: build.mutation({
      query: (queryArg) => ({
        url: `/admin/deletePosts`,
        method: 'POST',
        body: queryArg.body
      }),
      invalidatesTags: ['ShopPosts'],
    }),
  }),

  overrideExisting: true
})

export const {
  useGetOrganizationShopPostsQuery,
  useLazyGetOrganizationShopPostsQuery,
  useGetShopPostsQuery,
  useLazyGetShopPostsQuery,
  useGetShopPostQuery,
  useLazyGetShopPostQuery,
  usePostShopPostMutation,
  usePutShopPostMutation,
  useDeleteShopPostMutation,
  usePostDeletePostsMutation
} = shopPostsApi
