import styled from 'styled-components'
import Select from 'react-select'

// eslint-disable-next-line import/prefer-default-export
export const StyledSelect = styled(Select)`
  width: ${({ size }) => size};
  .rs__control {
    width: ${({ size }) => size};
    height: 40px;
    min-height: 44px;
    background-color: ${({ theme, isGrey }) => (isGrey ? theme.colors.skyNeutral4 : theme.colors.white ) };
    color: ${({ theme }) => theme.colors.skyNeutral_1};
    outline: none;
    border: none;
    display: flex;
    border-radius: 4px;
    transition: padding-top 0.2s ease-in-out;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: none;
    :hover {
      border: none;
      box-shadow: none;
    }
  }
  .rs__control--is-focused.rs__control--menu-is-open {
    outline: none;
    border: none;
    box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
  }
  .rs__indicators {
    align-items: center;
    display: flex;
  }
  .rs__single-value {
  }
  .rs__indicator-separator {
    display: none;
  }
  .rs__value-container {
    padding: 2px 0;
  }
  .rs__menu {
    border-radius: 4px;
    overflow: scroll;
    position: absolute;
  }
  .rs__menu-list {
    z-index: 2000;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .rs__single-value--is-disabled {
  }
  .rs__option--is-selected {
  }
  .rs__multi-value {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.skyNeutral2};
    font-size: 17px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rs__multi-value__remove {
    background-color: inherit;
    :hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.pinkCreator(34, 38)};
      background-color: transparent;
    }
  }
  .rs__multi-value__label {
    color: ${({ theme }) => theme.colors.skyNeutral};
  }
`

export const StyledLabel = styled.label``

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 12px;
  width: 100%;
  & :focus-visible {
    border: none;
  }
`
