import styled from 'styled-components'
import { HStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const ShopNameWrap = styled(HStack)`
  align-items: center;
  & > :first-child {
    margin-right: 20px;
  }
`
