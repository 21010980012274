import React, { useMemo, useState, useEffect } from 'react'
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable
} from 'react-table'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import { Search } from '@styled-icons/material'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import DashboardHeader from '../../../components/DashboardHeader'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  Toolbar,
  ButtonWrap
} from '../../../global/styles'
import {
  useLazyGetSearchOrganizationsQuery,
  usePostOrganizationMutation,
  usePatchOrganizationMutation,
  usePostDeleteOrganizationsMutation
} from '../../../api/api.generated'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import { tableColumns } from '../helpers/customersHelpers'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import TableCheckboxCell from '../../../components/TableCheckboxCell'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import TableButtonCell from '../../../components/TableButtonCell'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import OrganizationsTableRow from '../OrganizationsTableRow'
import AddOrganizationModal from '../AddOrganizationModal'

const ManageOrganizations = () => {
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [activeCustomerId, setActiveCustomerId] = useState(null)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [getSearchOrganizations, { data: organizationsData, isLoading }] = useLazyGetSearchOrganizationsQuery()
  const [addNewOrganization, { isLoading: addingNewOrganization }] = usePostOrganizationMutation()
  const [editOrganization, { isLoading: editingOrganization }] = usePatchOrganizationMutation()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeleteOrganizationsMutation()
  const [addNewError, setAddNewError] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState('');

  const handleShowCustomerDetails = (customerId) => {
    if (customerId !== activeCustomerId) {
      setActiveCustomerId(customerId)
    } else {
      setActiveCustomerId(null)
    }
  }

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => OrganizationsTableRow(organization))
        : [],
    [organizationsData]
  )
  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
  
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleSetIdToDelete={setDeleteOneId}
              handleShowEditModal={setShowEditModal}
              handleShowConfirmModal={setShowConfirmDelete}
              canEdit
              showView={false}
            />
          )
        }
      ])
    }
  )

  const handleShowAddNewModal = () => {
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
    setAddNewError('');
  }

  const handleCreateNew = (values) => {
    addNewOrganization({
      body: {
        name: values.name,
        icon: values.icon
      }
    })
      ?.unwrap()
      .then(() => {
        setAddNewError('');
        handleCloseAddNewModal()
      })
      .catch((err) => {
        setAddNewError(getErrorMessage(err))
      })
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setAddNewError('');
    setDeleteOneId(null)
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleEdit = (values) => {
    editOrganization({
      id: deleteOneId,
      body: {
        name: values.name,
        icon: values.icon
      }
    })
      ?.unwrap()
      .then(() => {
        setAddNewError('');
        handleCloseEditModal()
      })
      .catch((err) => {
        setAddNewError(getErrorMessage(err))
      })      
  }

  const deleteSelected = (usersIdsToDelete) => {
    deleteSelectedIds({ body: {ids: usersIdsToDelete } })
      ?.unwrap()
      .then(() => {
        setDeleteError('');
      })
      .catch((err) => {
        setDeleteError(getErrorMessage(err))
      })
  }

  const handleDeleteSelected = () => {
    setDeleteOneId(null)
    setShowConfirmDelete(false)
    if (deleteOneId) {
      deleteSelected([deleteOneId])
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelected(keys(selectedRowIds))
    }
  }

  const loadOrganizations = () => {
    setDeleteError('')
    getSearchOrganizations({ page: pageIndex, limit: pageSize, search })?.unwrap()
      .catch((err) => {
        setDeleteError(getErrorMessage(err))
      })
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadOrganizations()
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    loadOrganizations()
  }, [])

  useEffect(() => {
    if (organizationsData) {
      setTotalPages(organizationsData.pagination.pages)
      setTotalRows(organizationsData.pagination.total)
      if (pageIndex >= organizationsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [organizationsData])

  useEffect(() => {
    if (organizationsData) {
      loadOrganizations()
    }
  }, [pageIndex, pageSize]);

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Manage Organisations"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
          addNewLabel="Add Organisation"
          handleAddNew={handleShowAddNewModal}
        />
        {isLoading && <LoadingSpinner />}
        {organizationsData && (
          <>
            <Toolbar>
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search organisation by name"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>              
            </Toolbar>
            {deleteError && 
              <Toolbar>
                <Typography variant="textS" color="red">
                {deleteError}
                </Typography>
              </Toolbar>          
            }
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              pageName="organisations"
              handleShowDetails={handleShowCustomerDetails}
              activeItemId={activeCustomerId}
            />
          </>
        )}
      </DashboardInfoWrap>

      {showAddNewModal && 
      <AddOrganizationModal
        isOpen={showAddNewModal}
        isCreate
        handleCreateNew={handleCreateNew}
        handleCloseAddNewModal={handleCloseAddNewModal}
        addingNewOrganization={addingNewOrganization}
        error={addNewError}
      />
      }
      {showEditModal && 
      <AddOrganizationModal
        isOpen={showEditModal}
        isCreate={false}
        organizationId={deleteOneId}
        handleCreateNew={handleEdit}
        handleCloseAddNewModal={handleCloseEditModal}
        addingNewOrganization={editingOrganization}
        error={addNewError}
      />
      }
      {organizationsData && (!isEmpty(selectedRowIds) || deleteOneId) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            organizationsData?.organizations,
            'name'
          )}
          entityType="organisation"
          handleDelete={handleDeleteSelected}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManageOrganizations

ManageOrganizations.propTypes = {}
