import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'
import { isEmpty } from 'lodash/lang'
import { findIndex } from 'lodash/array'
import { selectShopId } from '../../../app/selectors'
import { getErrorMessage } from '../../../utils/helpers'
import {
  useLazyGetRoomMessagesQuery,
  usePostNewMessageMutation,
  useDeleteRoomMutation
} from '../../../api/messages'
import { USER_AVATAR_SIZES, BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import UserAvatar from '../../../components/UI/UserAvatar'
import ChatBubble from '../../../components/ChatBubble'
import LoadingSpinner from '../../../components/LoadingSpinner'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import ShowMessageModal from '../../../components/ShowMessageModal'
import ChatInput from '../ChatInput'
import {
  ChatHeadlineWrap,
  Headline,
  HeaderButtonWrap,
  ChatMessagesWrap,
  ShowMoreWrap,
  ChatWrapper,
  ReceiverName,
  SelectedChatUserDetailsWrap,
  ContactNameWrap, 
  Pronouns
} from './styles'

const ChatContainer = ({ roomId, receiverName, items, preferredPronouns, onDeleteRoom }) => {
  const { role } = useSelector((state) => state.auth)
  const shopId = useSelector(selectShopId)
  const [messagesStream, setMessagesStream] = useState([])
  const [firstMessage, setFirstMessage] = useState('')
  const [lastMessage, setLastMessage] = useState('')
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showMoreButton, setShowMoreButton] = useState(false)
  const [error, setError] = useState('')
  const [getRoomMessages, { data: roomMessages, isLoading }] = useLazyGetRoomMessagesQuery()
  const [postNewMessage] = usePostNewMessageMutation()
  const [deleteRoom] = useDeleteRoomMutation()
  const messagesStartRef = useRef(null)
  const messagesEndRef = useRef(null)

  const scrollToTop = () => {
    messagesStartRef.current?.scrollIntoView()
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView()
  }

  const handleSendMessage = (userMessage, imageToSend) => {
    postNewMessage({
      roomId,
      data: { message: userMessage, image: imageToSend }
    })
  }

  const handleDeleteSelected = () => {
    setShowConfirmDelete(false)
    deleteRoom(roomId)
      ?.unwrap()
      .then(() => {
        onDeleteRoom(roomId)
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }
  
  const handleShowMore = () => {
    getRoomMessages({roomId, messageId: firstMessage})
  }

  const onMessageDelete = (messageId) => {
    const arr = messagesStream.filter(obj => obj.id !== messageId)
    setMessagesStream(arr)
  }
  
  const mergeArraysBottom = (original, changes) => {
    if (original.length > 0) {
      const arr = cloneDeep(original)
      const arrInsert = [];
      changes.forEach(element => {
        const index = findIndex(arr, {'id' : element.id});
        if (index === -1) {
          arrInsert.push(element)
        } else {
          arr[index] = element
        }
      });
      return arrInsert.concat(arr)
    }
    return changes
  }

  useEffect(() => {
    if (roomId) {
      getRoomMessages({roomId, messageId: firstMessage})
    }
  }, [roomId])

  useEffect(() => {
    if (roomMessages) {
      // setMessagesStream(sortBy(roomMessages, (message) => message.timeStamp))
      setShowMoreButton(roomMessages.length === 20)
      setMessagesStream(mergeArraysBottom(messagesStream, roomMessages))
    }
  }, [roomMessages])

  useEffect(() => {
    if (messagesStream.length) {
      if (lastMessage !== messagesStream[messagesStream.length - 1].id) {
        scrollToBottom()
      } else if (firstMessage !== messagesStream[0].id) {
        scrollToTop()
      }
      setFirstMessage(messagesStream[0].id)
      setLastMessage(messagesStream[messagesStream.length - 1].id)
    }
  }, [messagesStream])

  useEffect(() => {
    if (!isEmpty(items)) {
      setMessagesStream((prev) => [...prev, items[0].chatMessage])
    }
  }, [items])

  if (!roomId) {
    return (
      <ChatWrapper alignItems="center" justify="center">
        <p>Please select a room</p>
      </ChatWrapper>
    )
  }

  return (
    <>
      <ChatHeadlineWrap>
        <UserAvatar
          size={USER_AVATAR_SIZES.LARGE}
          userName={receiverName}
          // isOnline
        />
        <Headline>
          <SelectedChatUserDetailsWrap>
            <ContactNameWrap>
              <ReceiverName>{receiverName}</ReceiverName>
              {preferredPronouns !== '' &&
              <Pronouns>({preferredPronouns})</Pronouns>
              }
            </ContactNameWrap>  
            {/* <Typography variant="textXS" color={theme.colors.greenBase}> */}
            {/*  Online */}
            {/* </Typography> */}
          </SelectedChatUserDetailsWrap>
          <HeaderButtonWrap>
            <Button
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              type="button"
              onClick={() => { setShowConfirmDelete(true) }}              
            >
              Delete
            </Button>
          </HeaderButtonWrap>
        </Headline>     
      </ChatHeadlineWrap>

      <ChatMessagesWrap>
        {isLoading && <LoadingSpinner />}
        <div ref={messagesStartRef} />
        {!isLoading && showMoreButton &&
        <ShowMoreWrap>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            type="button"
            onClick={() => { handleShowMore() }}              
          >
            Show More
          </Button>
        </ShowMoreWrap>
        }
        {messagesStream && messagesStream.map((message) => (
          <ChatBubble
            key={message.id}
            id={message.id}
            messageText={message.messageText}
            messageImage={message.image}
            author={message.authorName}
            isOwnMessage={(role === 'organisationAdmin' && message.userId === null) || (role === 'storeAdmin' && message.shopId === shopId) }
            timeStamp={message.timeStamp}
            onDelete={onMessageDelete}
          />
        ))}
        <div ref={messagesEndRef} />
      </ChatMessagesWrap>
      <ChatInput {...{ handleSendMessage }} />

      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={() => { setShowConfirmDelete(false) }}              
          headline="Delete room"
          description="Are you sure you want to delete this room?"
          handleDelete={handleDeleteSelected}
        />
      )}
      {error && 
        <ShowMessageModal
          title="Delete room"
          message={error}
          onClose={() => { setError("") }}              
        />
      }
    </>
  )
}
export default ChatContainer
ChatContainer.defaultProps = {
  preferredPronouns: '',
  roomId: null,
  receiverName: '',
  items: []
}

ChatContainer.propTypes = {
  preferredPronouns: PropTypes.string,
  roomId: PropTypes.string,
  items: PropTypes.array,
  receiverName: PropTypes.string,
  onDeleteRoom: PropTypes.func.isRequired
}
