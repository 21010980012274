/*eslint-disable*/
import { ExpandMore, RemoveCircle } from '@styled-icons/material'
import { globalTheme } from '../../../../global/theme'
import React from 'react'

export const CustomRemoveCircle = ({ innerProps }) => (
  <RemoveCircle {...innerProps} size={23} color={globalTheme.colors.pinkBase} />
)
export const CustomDropDownArrow = ({ innerProps }) => (
  <ExpandMore {...innerProps} size={23} color={globalTheme.colors.skyNeutral} />
)
