import React, { useState } from 'react'
import { useField } from 'formik'
import { Schedule } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { values } from 'lodash/object'
import DatePicker from 'react-datepicker'
import {
  InputBodyWrapper,
  InputError,
  InputWrapper,
  RightSideIcon
} from './styles'
import { INPUT_SIZES } from '../../../../const/UIvariants'
import { globalTheme } from '../../../../global/theme'
import { InputOuterLabel } from '../TextAreaInput/styles'

const TimeInput = ({ inputName, size, disabled, label, minTime, maxTime }) => {
  const [field, meta, helpers] = useField({ name: inputName })
  const { name, value } = field
  const [selected, setSelected] = useState(new Date(meta.value) || new Date())
  const handleTimeChange = (date) => {
    setSelected(date)
    helpers.setValue(date.toString())
  }
  return (
    <InputWrapper {...{ size }} error={meta.touched && meta.error}>
      {label && (
        <InputOuterLabel
          {...{ disabled }}
          htmlFor={name}
          variant="textS"
          component="label"
        >
          {label}
        </InputOuterLabel>
      )}
      <InputBodyWrapper>
        <DatePicker
          id={name}
          selected={selected}
          onChange={handleTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          minTime={minTime}
          maxTime={minTime ? maxTime : undefined}
          placeholderText="Select Time"
          dateFormat="h:mm aa"
        />
        <RightSideIcon>
          <Schedule
            size={22}
            color={
              value
                ? globalTheme.colors.skyNeutral_1
                : globalTheme.colors.skyNeutral2
            }
          />
        </RightSideIcon>
      </InputBodyWrapper>
      {meta.touched && meta.error ? (
        <InputError>{meta.error}</InputError>
      ) : null}
    </InputWrapper>
  )
}

export default TimeInput

TimeInput.defaultProps = {
  disabled: false,
  placeholder: 'Time over',
  size: INPUT_SIZES.LARGE,
  label: null,
  minTime: undefined,
  maxTime: new Date('2021-07-15T23:59:51.0000')
}
TimeInput.propTypes = {
  placeholder: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(values(INPUT_SIZES)),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date)
}
