import styled from 'styled-components'
import Typography from '../../../components/UI/Typography'
import {
  DashboardInfoWrap,
  HStack,
  ManageEntityDashboardWrap
} from '../../../global/styles'

export const ShopsDashboardWrap = styled(ManageEntityDashboardWrap)``
export const ShopsWrap = styled(DashboardInfoWrap)`
  overflow: scroll;
`

export const MetricsItem = styled(HStack)`
  width: 100%;
`

// table styles

export const StyledTypography = styled(Typography)`
  word-wrap: anywhere;
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
export const ShopNameWrap = styled(HStack)`
  & > :first-child {
    margin-right: 20px;
  }
`
export const TableText = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const Toolbar = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  > * {
    margin-right: 10px;
  }
`
export const ButtonWrap = styled(HStack)`
  margin-right: 0;
  > * {
    height: 44px;
  }
`