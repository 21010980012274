import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const StyledIconButton = styled.button`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  margin: 0;
  background: transparent;
  border-radius: 2px;
  border: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.skyNeutral2};
  }
`
