import React from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash/object'
import { BadgeBody, BadgeIcon, BadgeLabel } from './styles'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'

const { SMALL } = BADGE_SIZES
const Badge = ({ icon, label, size, variant, onClick, isActive }) => (
  <BadgeBody {...{ size, variant, onClick, isActive }}>
    {icon && <BadgeIcon>{icon}</BadgeIcon>}
    <BadgeLabel variant={size === SMALL ? 'textXS' : 'textM'} color={variant}>
      {label}
    </BadgeLabel>
  </BadgeBody>
)

export default Badge

Badge.defaultProps = {
  icon: null,
  size: BADGE_SIZES.SMALL,
  variant: BADGE_VARIANTS.LIGHT,
  onClick: () => {},
  isActive: false
}

Badge.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(values(BADGE_SIZES)),
  variant: PropTypes.oneOf(values(BADGE_VARIANTS)),
  onClick: PropTypes.func,
  isActive: PropTypes.bool
}
