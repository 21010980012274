import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsEditingShop, selectShopId } from '../../../app/selectors'
import ShopDashboard from '../ShopDashboard'

const DashboardHoc = () => {
  const isEditing = useSelector(selectIsEditingShop)
  const shopId = useSelector(selectShopId)
  return (
    <ShopDashboard shopId={shopId} isEditing={isEditing} />
  )
}

export default DashboardHoc

DashboardHoc.propTypes = {}
