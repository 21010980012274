import styled from 'styled-components'
import { HStack } from '../../global/styles'

export const PaginatorWrap = styled(HStack)`
  align-items: center;
`
export const PaginatorButton = styled.button`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.skyBase2};
  color: ${({ theme }) => theme.colors.skyBase};
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color, border 0.2s ease-in-out;
  // background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    background: ${({ theme }) => theme.colors.skyBase4};
    border: 1px solid ${({ theme }) => theme.colors.skyBase};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.skyNeutral2};
    border: 1px solid transparent;
  }
`
export const PageButton = styled(PaginatorButton)`
  margin: 0 4px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.skyBase3 : 'transparent'};
    // isActive ? theme.colors.skyBase3 : theme.colors.white};
    
`
