import styled from 'styled-components'
import Typography from '../../../components/UI/Typography'
import { HStack, VStack } from '../../../global/styles'

export const Headline = styled(Typography).attrs({
  variant: 'headingM',
  bold: true
})`
  margin-bottom: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
// export const Label = styled(Typography).attrs(({ theme }) => ({
//   variant: 'textS',
//   color: theme.colors.skyNeutral_1
// }))`
//   text-align: left;
// `
export const ModalBody = styled(VStack)`
  align-items: stretch;
  width: 100%;
  justify-content: flex-start;
  padding: 32px;
`

export const ButtonWrap = styled(HStack)`
  width: 100%;
  margin-top: 24px;
  justify-content: space-between;
  & > :first-child {
    margin-right: 12px;
  }
`
