import styled from 'styled-components'
import { Card, VStack } from '../../../global/styles'

export const SettingsSection = styled(Card)`
  border-radius: 12px;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  padding: 24px 20px;
  &:first-child {
    margin-right: 24px;
  }
`

export const SettingSection = styled(VStack)`
  align-self: stretch;
  flex-wrap: wrap;
  padding: 8px;
  margin-bottom: 16px;
`
