import * as React from 'react'

function HoppaHead() {
  return (
    // paste svg from svrg here, replace fill and stroke with props where needed
    <svg
      width={736}
      height={409}
      viewBox="0 0 736 409"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M284.062 441.494c2.468 2.29 5.524 4.594 9.116 6.887-5.358-4.41-9.116-6.887-9.116-6.887z"
        fill="#CFDDF9"
      />
      <path
        d="M293.178 448.381c-3.592-2.293-6.648-4.597-9.115-6.887 0 0 3.757 2.477 9.115 6.887zm0 0c72.289 46.16 361.467 88.175 429.831-74.314 17.16-40.787-21.647-77.685-65.489-83.425-239.519-31.361-320.956-200.54-413.673-257.59m49.331 415.329c18.901 15.559 57.713 55.186 21.713 95.076M243.847 33.052c-19.169-11.795-38.821-18.798-60.452-18.798-137.128 0-144.634 203.977 22.926 203.977-67.207 75.493-133.053 144.987-197.666 208.55 126.32 54.149 174.74 171.246 197.666 298.554 32.737-71.011 79.691-163.873 169.644-247.354M243.847 33.052c40.216-62.559 161.106 0 145.875 133.276m-53.505 122.13c31.909-25.87 94.859-9.416 107.883 26.822m269.142 73.244c-23.947 19.415-71.191 7.066-80.965-20.13"
        stroke="url(#prefix__paint0_linear)"
        strokeWidth={16}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={367.908}
          y1={8.665}
          x2={367.908}
          y2={725.335}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFDDF9" />
          <stop offset={1} stopColor="#CFDDF9" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HoppaHead

HoppaHead.defaultProps = {
  // width: 346,
  // height: 344,
  // fill: '#fff'
}
HoppaHead.propTypes = {
  // fill: PropTypes.string,
  // width: PropTypes.number,
  // height: PropTypes.number
}
