import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'
import { formatPostDeadline, truncateString } from '../../../utils/helpers'

const PostTableRow = (post) => ({
  id: post.id,
  title: (
    <TableImageLabelCell
      image={post.image}
      label={post.title}
      isRounded={false}
    />
  ),
  organizationName: (
    <TableText>{post.organizationName}</TableText>
  ),  
  description: (
    <TableText>{truncateString(post.description, 20)}</TableText>
  ),
  deadline: (
    <Badge
      variant={
        post.startTime && post.endTime
          ? BADGE_VARIANTS.PINK
          : BADGE_VARIANTS.LIGHT
      }
      label={formatPostDeadline(post.startTime, post.endTime)}
    />
  ),
  forSearch: `${post.title} ${post.description}`
})

export default PostTableRow

PostTableRow.propTypes = {}
