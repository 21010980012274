import { api as apiSlice } from './api.generated'

const shopApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShopDetails: build.query({
      query: (shopId) => ({
        url: `/shop/details/${shopId}`,
        method: 'get'
      }),
      providesTags: ['ShopDetails']
    }),
    putShopDetails: build.mutation({
      query: ({ shopId, data }) => ({
        url: `/shop/details/${shopId}`,
        method: 'put',
        data
      }),
      invalidatesTags: ['ShopDetails']
    }),
    putShopCoverImage: build.mutation({
      query: ({ shopId, image }) => ({
        url: `/shop_cover_image/${shopId}`,
        method: 'put',
        data: { image }
      }),
      invalidatesTags: ['ShopDetails']
    }),
    postShopStripeConnect: build.mutation({
      query: (shopId) => ({
        url: `/shop/stripe/${shopId}/connect`,
        method: 'post'
      }),
      invalidatesTags: ['ShopDetails']
    })
  }),

  overrideExisting: true
})

export const {
  useGetShopDetailsQuery,
  usePutShopDetailsMutation,
  usePutShopCoverImageMutation,
  usePostShopStripeConnectMutation
} = shopApi
