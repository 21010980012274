import React from 'react'
import { DeleteOutline } from '@styled-icons/material'
import PropTypes from 'prop-types'
import IconButton from '../UI/Buttons/IconButton'

const TableDeleteCell = ({
  cell,
  handleShowConfirmModal,
  handleSetIdToDelete
}) => (
  <IconButton
    onClick={(e) => {
      e.stopPropagation()
      handleShowConfirmModal(true)
      handleSetIdToDelete(cell.row.id)
    }}
  >
    <DeleteOutline size={20} color="#EC72A5" />
  </IconButton>
)

TableDeleteCell.propTypes = {
  cell: PropTypes.object.isRequired,
  handleShowConfirmModal: PropTypes.func.isRequired,
  handleSetIdToDelete: PropTypes.func.isRequired
}
export default TableDeleteCell
