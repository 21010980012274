import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import LinkButton from '../../../components/UI/Buttons/LinkButton'

export const stub = styled.div``

export const RegisterWrap = styled(HStack)`
  margin-top: 40px;
`
export const ForgetPassword = styled(LinkButton)`
  margin: -20px 0 0 0;
  text-align: right;
`
export const PasswordInputStack = styled(VStack)`
  margin-bottom: 24px;
`
