import React from 'react'
import PropTypes from 'prop-types'
import { DashboardRecentItemWrap, Description, InfoWrap, Title } from './styles'
import EntityPicture from '../EntityPicture'
import Badge from '../UI/Badge'
import { BADGE_VARIANTS } from '../../const/UIvariants'

const DashboardRecentItem = ({
  image,
  title,
  description,
  onClick,
  isActiveBadge,
  showBadge,
  bgColor
}) => (
  <DashboardRecentItemWrap {...{ onClick }}>
    <EntityPicture {...{ image, bgColor }} entityName={title} />
    <InfoWrap>
      <Title variant="textS">{title}</Title>
      <Description variant="textXS">{description}</Description>
    </InfoWrap>
    {showBadge && (
      <Badge
        label={isActiveBadge ? 'Resolved' : 'Pending'}
        variant={isActiveBadge ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
      />
    )}
  </DashboardRecentItemWrap>
)

export default DashboardRecentItem

DashboardRecentItem.defaultProps = {
  isActiveBadge: false,
  image: null,
  description: null
}

DashboardRecentItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isActiveBadge: PropTypes.bool,
  showBadge: PropTypes.bool.isRequired
}
