import styled from 'styled-components'
import { isEmpty } from 'lodash/lang'
import { VStack } from '../../global/styles'

export const UploadButton = styled.button`
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 20px;
  margin-left: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.colors.skyNeutral2};
  :hover {
    color: ${({ theme }) => theme.colors.skyNeutral_2};
    background-color: ${({ theme }) => theme.colors.skyCreator(20, 90)};
  }
`
export const CoverImage = styled(VStack)`
  cursor: ${({ backgroundImage }) =>
    isEmpty(backgroundImage) ? 'pointer' : 'default'};
  background: ${({ theme }) => theme.colors.skyNeutral3}
    url(${({ backgroundImage }) => backgroundImage}) no-repeat;
  background-size: cover;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 127px;
  height: 127px;
  display: flex;
  padding: 12px 16px;
`
export const SpinnerWrap = styled.div`
  position: absolute;
`
