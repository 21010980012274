import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api/api.generated'
import { logout } from '../auth/authSlice'

const shopInitState = {
  shopName: null,
  shopId: null,
  isEditingShop: false,
  shopLogotype: null,
  shopCoverImage: null
}

const shopSlice = createSlice({
  name: 'shop',
  initialState: shopInitState,
  reducers: {
    clear: () => shopInitState,
    setEditMode: (state, { payload }) => {
      state.isEditingShop = payload
    },
    setShopLogotype: (state, { payload }) => {
      state.shopLogotype = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => shopInitState)
      .addMatcher(
        api?.endpoints?.createAccount?.matchFulfilled,
        (state, { payload }) => {
          state.shopName = payload.storeAdmin.shopName
          state.shopId = payload.shop_id
        }
      )
      .addMatcher(
        api?.endpoints?.login?.matchFulfilled,
        (state, { payload }) => {
          state.shopId = payload.shop_id
        }
      )
  }
})
export const { clear, setEditMode, setShopLogotype } = shopSlice.actions
export default shopSlice.reducer
