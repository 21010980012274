import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ContentWrap = styled(VStack)`
  width: 100%;
  padding: 32px;
  overflow: scroll; ;
`

export const HeadlineWrap = styled(HStack)`
  margin-bottom: 32px;
  justify-content: space-between;
  width: 100%;
  height: 44px;
`

export const MetricsWrap = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`

export const MetricItem = styled(VStack)`
  min-width: 31%;
  margin-right: 2%;
  height: 135px;
  padding: 16px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  box-shadow: 0 6px 16px -4px rgba(15, 18, 20, 0.1);
  &:nth-child(3n) {
    margin-right: 0;
  }
`

export const MetricLabel = styled(Typography)`
  margin-bottom: 9px;
`

export const IconWrap = styled(VStack)`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  background-color: ${({ bgColor }) => bgColor};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`

export const PostsWrap = styled(HStack)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-top: 24px;
`
export const LatestPostsHeadline = styled(HStack)`
  justify-content: space-between;
  width: 100%;
`
export const LatestPosts = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 24px;
  border-radius: 12px;
  margin-top: 24px;
  width: 100%;
  transition: height 5s ease-in;
`
export const ManagePostsLink = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
/*
export const EmptyMetricItem = styled(VStack)`
  min-width: 31%;
  margin-right: 2%;
  height: 135px;
  padding: 16px;
  justify-content: space-between;
  border-radius: 12px;
  &:nth-child(3n) {
    margin-right: 0;
  }
`
*/
export const ButtonWrap = styled(HStack)`
  & > :nth-child(2) {
    margin-left: 12px;
  }
`