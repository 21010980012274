import styled from 'styled-components'
import { VStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const ImageContainer = styled(VStack)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height}px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.skyNeutral4}
    url(${({ bgImage }) => bgImage ?? ''}) no-repeat center;
  background-size: cover;
`
