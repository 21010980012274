import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const ProductDetailsWrap = styled(VStack)`
  width: 100%;
  height: 100%;
`

export const TagsWrap = styled(HStack)`
  flex-wrap: wrap;
  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`

export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
`
