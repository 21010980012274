import { api as apiSlice} from './api.generated'

const orderProductsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShopOrderProducts: build.query({
      query: (queryArg) => ({
        url: `/shop/orders/${queryArg.orderId}/products`,
        method: 'get'
      })
    })
  })
})

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetShopOrderProductsQuery
} = orderProductsApi
