import React from 'react'
import { useParams } from 'react-router-dom'
import ShopAbout from '../../../components/ShopAbout'

const ManageShop = () => {
  const { shopId } = useParams()

  return (
    <ShopAbout shopId={shopId}/>
  )
}

export default ManageShop

ManageShop.propTypes = {}
