import React from 'react'
import { useParams } from 'react-router-dom'
import ShopDashboard from '../ShopDashboard'

const ManageShopDashboard = () => {
  const { shopId } = useParams()
  return (
    <ShopDashboard shopId={shopId} isEditing={false} />
  )
}

export default ManageShopDashboard

ManageShopDashboard.propTypes = {}
