/*eslint-disable*/
import React from 'react'
import { TableDarkText, TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'

const OrderProducsTableRow = (product) => ({
  name: (
    <TableImageLabelCell
      image={product.productImage}
      label={product.productName}
      isRounded={false}
    />
  ),
  price: <TableText>${product.productPrice}</TableText>,
  shippingCost: <TableText>${product.shippingCost}</TableText>
})

export default OrderProducsTableRow

OrderProducsTableRow.propTypes = {}
