import React from 'react'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'
import { formatPostDeadline, truncateString } from '../../../utils/helpers'

const PostTableRow = (post) => ({
  postId: post.id,
  postTitle: (
    <TableImageLabelCell
      image={post.postImage}
      label={post.postTitle}
      isRounded={false}
    />
  ),
  shop: <TableText>{post.shopName}</TableText>,
  description: <TableText>{truncateString(post.postDescription, 20)}</TableText>,
  code: <TableText>{post.code}</TableText>,
  deadline: (
    <Badge
      variant={
        post.startTime && post.endTime
          ? BADGE_VARIANTS.PINK
          : BADGE_VARIANTS.LIGHT
      }
      label={formatPostDeadline(post.startTime, post.endTime)}
    />
  ),
  isOrganizationPost: 
    post.isOrganizationPost 
      ? <Badge label="Yes" variant={BADGE_VARIANTS.GREEN} size={BADGE_SIZES.SMALL}/>
      : '',  
  likeCount: <TableText>{post.likeCount}</TableText>,
  interestedCount: <TableText>{post.interestedCount}</TableText>,
  attendedCount: <TableText>{post.attendedCount}</TableText>,
  canEdit: !post.isOrganizationPost,
  forSearch: `${post.postTitle} ${post.postDescription}`
})

export default PostTableRow

PostTableRow.propTypes = {}
