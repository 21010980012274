import React from 'react'
import { BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableDarkText, TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'

const ShopTableRow = (customer) => ({
  customerName: (
    <TableImageLabelCell
      bgColor={customer.avatarBackground}
      image={customer.userAvatar}
      label={customer.userName}
      isRounded
    />
  ),
  phoneNumber: <TableText>{customer.phoneNumber === null ? '' : '+' }{customer.phoneNumber}</TableText>,
  stateName: <TableText>{customer.stateName}</TableText>,
  customerId: customer.id,
  email: <TableDarkText>{customer.email}</TableDarkText>,
  friendsList: <TableText>{customer.friendsCount} Friends</TableText>,
  favoriteShops: <TableText>{customer.favoriteShops} Stores</TableText>,
  forSearch: `${customer.userName} ${customer.email} ${customer.phoneNumber}`,
  status: (
    <Badge
      label={customer.isActive ? 'Active' : 'Inactive'}
      variant={customer.isActive ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
    />
  )
})

export default ShopTableRow

ShopTableRow.propTypes = {}
