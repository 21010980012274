import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const PostDetailsWrap = styled(VStack)`
  width: 100%;
  height: 100%;
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
`
