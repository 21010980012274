import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ButtonWrap, Headline, ModalBody } from './styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button/Button'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'

const ReplySupportModal = ({
  isOpen,
  handleCloseModal,
  handleSendReply,
  isLoading,
  ticketId
}) => (
  <Modal {...{ isOpen }}>
    <ModalBody>
      <Formik
        initialValues={{
          topic: '',
          description: ''
        }}
        validationSchema={Yup.object({
          topic: Yup.string().required('Subject is required'),
          description: Yup.string().required('Description is required')
        })}
        onSubmit={(values) => handleSendReply(values, ticketId)}
      >
        <Form>
          <Headline>Reply Support</Headline>
          <TextInput
            label="Subject"
            placeholder="Enter subject email"
            inputName="topic"
            size={INPUT_SIZES.SMALL}
          />
          <TextAreaInput
            label="Message"
            inputName="description"
            placeholder="Enter your message"
            rows={10}
          />
          <ButtonWrap>
            <Button
              variant={BUTTON_VARIANTS.SECONDARY}
              size={BUTTONS_SIZES.MED}
              onClick={handleCloseModal}
              type="button"
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.MED}
              type="submit"
            >
              Send
            </Button>
          </ButtonWrap>
        </Form>
      </Formik>
    </ModalBody>
  </Modal>
)

export default ReplySupportModal

ReplySupportModal.defaultProps = {
  ticketId: ''
}
ReplySupportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSendReply: PropTypes.func.isRequired,
  ticketId: PropTypes.any
}
