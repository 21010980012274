import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import {
  TicketDetailsWrap,
  ButtonWrap,
  TicketImage,
  ImagesWrap
} from './styles'
import {
  useGetSupportTicketQuery,
  usePatchSupportTicketByTicketIdMutation,
  usePostReplyTicketMutation
} from '../../../api/api.generated'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import EntityPicture from '../../../components/EntityPicture'
import {
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock,
  DetailsPanelTopSection
} from '../../../global/styles'
import Button from '../../../components/UI/Buttons/Button'
import Badge from '../../../components/UI/Badge'
import { getImageFromServer } from '../../../utils/helpers'
import ReplySupportModal from '../ReplySupportModal'

const TicketDetails = ({ activeTicketId, closePanel }) => {
  const [showReply, setShowReply] = useState(false)
  const { data: ticketData, isLoading } = useGetSupportTicketQuery(activeTicketId)
  const [resolveTicket] = usePatchSupportTicketByTicketIdMutation()
  const [replyToTicket, { isLoading: replying }] = usePostReplyTicketMutation()

  const handleReply = () => {
    setShowReply(true)
  }

  const handleResolve = () => {
    resolveTicket({ body: { resolved: true }, ticketId: activeTicketId })
    closePanel()
  }
  const handleSendReply = (values, ticketId) => {
    replyToTicket({ body: values, ticketId })
      ?.unwrap()
      .then(() => {
        setShowReply(false)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {ticketData && (
        <>
          <DetailsPanelTopSection>
            <EntityPicture
              entityName={ticketData.senderName}
              size={USER_AVATAR_SIZES.LARGE}
              image={ticketData.senderImage}
              bgColor={ticketData.avatarBackground}
            />
            <AsideDetailsTitle align="center">
              {ticketData.senderName}
            </AsideDetailsTitle>
            <AsideDetailsLabel align="center">
              {ticketData.senderEmail}
            </AsideDetailsLabel>
            <AsideDetailsLabel align="center">
              {ticketData.senderPhone === null ? '' : '+' }{ticketData.senderPhone}
            </AsideDetailsLabel>
          </DetailsPanelTopSection>
          <TicketDetailsWrap>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Topic</AsideDetailsLabel>
              <AsideDetailsDescription>
                {ticketData.topic}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">
                Issue Description
              </AsideDetailsLabel>
              <AsideDetailsDescription>
                {ticketData.description}
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            {!isEmpty(ticketData.images) && (
              <DetailsInfoBlock>
                <AsideDetailsLabel align="left">
                  Attached Images
                </AsideDetailsLabel>
                <AsideDetailsDescription>
                  <ImagesWrap>
                    {ticketData.images.map((image) => (
                      <TicketImage
                        onClick={() => {
                          openInNewTab(getImageFromServer(image))
                        }}
                        key={image}
                        bg={getImageFromServer(image)}
                      />
                    ))}
                  </ImagesWrap>
                </AsideDetailsDescription>
              </DetailsInfoBlock>
            )}
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Status</AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                  label={ticketData.isResolved ? 'Resolved' : 'Pemding'}
                  variant={
                    ticketData.isResolved
                      ? BADGE_VARIANTS.GREEN
                      : BADGE_VARIANTS.LIGHT
                  }
                />
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.MED}
                onClick={handleReply}
              >
                Reply
              </Button>
              {!ticketData.isResolved && (
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  fillColor="hsla(119, 49%, 57%, 1)"
                  size={BUTTON_SIZES.MED}
                  onClick={handleResolve}
                >
                  Resolve
                </Button>
              )}
            </ButtonWrap>
          </TicketDetailsWrap>
          <ReplySupportModal
            isOpen={showReply}
            handleCloseModal={() => {
              setShowReply(false)
            }}
            ticketId={activeTicketId}
            isLoading={replying}
            handleSendReply={handleSendReply}
          />
        </>
      )}
    </>
  )
}

export default TicketDetails
TicketDetails.defaultProps = {
  activeTicketId: ''
}

TicketDetails.propTypes = {
  activeTicketId: PropTypes.any,
  closePanel: PropTypes.func.isRequired
}
