import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { StyledTab, TabLabel } from './styles'

const Tab = ({ label, isActive, destination, path }) => {
  const history = useHistory()
  const handleClick = (dest) => {
    history.push(`${path}${dest}`)
  }

  return (
    <StyledTab {...{ isActive }} onClick={() => handleClick(destination)}>
      <TabLabel {...{ isActive }} variant="textXS" bold align="center">
        {label}
      </TabLabel>
    </StyledTab>
  )
}

export default Tab

Tab.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired
}
