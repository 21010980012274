import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import Typography from '../Typography'
import { globalTheme } from '../../../global/theme'

const avatarColors = [
  globalTheme.colors.skyBase,
  globalTheme.colors.greenBase,
  globalTheme.colors.pinkBase,
  globalTheme.colors.earthBase,
  globalTheme.colors.cyanBase,
  globalTheme.colors.yellowBase
]

export const UserAvatarWrap = styled(VStack)`
  position: relative;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${({ bgVariant }) => avatarColors[bgVariant]};
`

export const UserInitials = styled(Typography)``

export const Status = styled.div`
  width: ${({ size }) => Math.round(size / 4)}px;
  height: ${({ size }) => Math.round(size / 4)}px;
  background: ${({ theme }) => theme.colors.greenBase};
  border-radius: 100px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid ${({ theme }) => theme.colors.white};
`
