import styled from 'styled-components'
import { Card, HStack, VStack } from '../../../global/styles'

export const DashboardWrap = styled(VStack)`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  align-items: stretch;
  justify-content: flex-start;
  padding: 24px;
`
export const MetricsWrap = styled(HStack)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`
export const DashboardChart = styled.div`
  width: 100%;
  margin-top: 24px;
  background: white;
  height: 324px;
`
export const RecentWrap = styled(HStack)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`
export const RecentBlock = styled(Card)`
  border-radius: 12px;
  //width: 50%;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  padding: 24px 20px;
  &:first-child {
    margin-right: 24px;
  }
`
export const RecentItemsWrap = styled(VStack)`
  width: 100%;
  align-items: stretch;
`
export const HeadlineWrap = styled(HStack)`
  margin-bottom: 32px;
  justify-content: space-between;
  width: 100%;
  height: 44px;
`
export const ButtonWrap = styled(HStack)`
  & > :nth-child(2) {
    margin-left: 12px;
  }
`