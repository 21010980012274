import React from 'react'
import PropTypes from 'prop-types'
import { Add, DeleteOutline } from '@styled-icons/material'
import {
  AddButton,
  ButtonWrap,
  EntityDashboardHeadline,
  SectionName
} from './styles'
import Button from '../UI/Buttons/Button/Button'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../const/UIvariants'

const DashboardHeader = ({
  headline,
  disableDelete,
  deleteLabel,
  handleDelete,
  addNewLabel,
  handleAddNew,
  showAddButton,
  noActions,
  showDelete,
  handleSelectAll,
  handleSelectPage,
  handleDeselectAll,
  showSelectButton,
  showSelectPageButton
}) => (
  <EntityDashboardHeadline>
    <SectionName variant="headingM" bold>
      {headline}
    </SectionName>
    {!noActions && (
      <ButtonWrap>
        {showSelectPageButton &&
          <AddButton
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            onClick={handleSelectPage}
            isFullWidth={false}
          >
            Select Page
          </AddButton>
        }
        {false && showSelectButton &&
          <AddButton
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            onClick={handleSelectAll}
            isFullWidth={false}
          >
            Select All
          </AddButton>
        }
        {showSelectButton &&
          <AddButton
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            onClick={handleDeselectAll}
            isFullWidth={false}
          >
            Deselect All
          </AddButton>
        }
        {showAddButton && addNewLabel && (
          <AddButton
            type="button"
            variant={BUTTON_VARIANTS.PRIMARY}
            size={BUTTONS_SIZES.SMALL}
            leftIcon={<Add />}
            onClick={handleAddNew}
            isFullWidth={false}
          >
            {addNewLabel}
          </AddButton>
        )}
        {showDelete && !disableDelete && (
        <Button
          type="button"
          variant={BUTTON_VARIANTS.PRIMARY}
          size={BUTTONS_SIZES.SMALL}
          leftIcon={<DeleteOutline />}
          onClick={handleDelete}
          disabled={disableDelete}
          isFullWidth={false}
          fillColor="#f44336"
        >
          {deleteLabel}
        </Button>
        )}
      </ButtonWrap>
    )}
  </EntityDashboardHeadline>
)

export default DashboardHeader

DashboardHeader.defaultProps = {
  deleteLabel: 'Delete Selected',
  handleDelete: () => {},
  addNewLabel: '',
  showDelete: true,
  disableDelete: true,
  noActions: false,
  showAddButton: true,
  handleAddNew: () => {},
  handleSelectPage: () => {},
  handleSelectAll: () => {},
  handleDeselectAll: () => {},
  showSelectButton: false,
  showSelectPageButton: false
}

DashboardHeader.propTypes = {
  showDelete: PropTypes.bool,
  disableDelete: PropTypes.bool,
  noActions: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  deleteLabel: PropTypes.string,
  handleDelete: PropTypes.func,
  addNewLabel: PropTypes.string,
  showAddButton: PropTypes.bool,
  handleAddNew: PropTypes.func,
  handleSelectPage: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleDeselectAll: PropTypes.func,
  showSelectButton: PropTypes.bool,
  showSelectPageButton: PropTypes.bool
}
