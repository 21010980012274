import styled from 'styled-components'
import { VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const TextWrap = styled(VStack)`
  margin-top: 64px;
  margin-bottom: 55px;
`

export const SuccessHeading = styled(Typography)`
  margin: 20px 0;
  text-align: left;
  color: ${({ theme }) => theme.colors.skyNeutral1};
`
export const SuccessText = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
