import React, { useState } from 'react'
import { InsertPhoto } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { isEmpty, isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import ImageUploading from 'react-images-uploading'
import Typography from '../UI/Typography'
import { getImageFromServer, getImageBase64 } from '../../utils/helpers'
import { usePutShopCoverImageMutation } from '../../api/shopApi'
import LoadingSpinner from '../LoadingSpinner'
import { CoverImage, SpinnerWrap, UploadButton } from './styles'

const ShopCoverPhoto = ({ shopId, coverPhoto, theme }) => {
  const [images, setImages] = useState([])
  const [updateCoverImage, { isLoading }] = usePutShopCoverImageMutation()

  const handlePickImage = (imageList) => {
    setImages(imageList)
    updateCoverImage({ shopId, image: getImageBase64({ source: imageList }) })
  }

  return (
    <ImageUploading
      value={images}
      onChange={handlePickImage}
      acceptType={['jpg', 'gif', 'png']}
      maxFileSize={4000000}
      dataURLKey="data_url"
    >
      {({ onImageUpload }) => (
        <CoverImage
          onClick={
            isNull(coverPhoto) && isEmpty(images) ? onImageUpload : undefined
          }
          backgroundImage={
            getImageFromServer(coverPhoto) || getImageBase64({ source: images })
          }
        >
          {isLoading && (
            <SpinnerWrap>
              <LoadingSpinner size={16} />
            </SpinnerWrap>
          )}
          {isNull(coverPhoto) && isEmpty(images) ? (
            <>
              <Typography
                color={theme.colors.skyNeutral_1}
                variant="headingS"
                align="center"
              >
                Click here to add the cover photo
              </Typography>
              <Typography
                color={theme.colors.skyNeutral1}
                variant="headingS"
                align="center"
              >
                Size 827x127, min. 4mb
              </Typography>
            </>
          ) : (
            <UploadButton>
              <InsertPhoto size={20} onClick={onImageUpload} />
            </UploadButton>
          )}
        </CoverImage>
      )}
    </ImageUploading>
  )
}

export default withTheme(ShopCoverPhoto)
ShopCoverPhoto.defaultProps = {
  coverPhoto: null
}
ShopCoverPhoto.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired,
  coverPhoto: PropTypes.string
}
