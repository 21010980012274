import React from 'react'
import dayjs from 'dayjs'
import { TableText } from '../../../global/styles'
import TableTagCell from '../../../components/TableTagCell'

const TagsTableRow = (tag) => ({
  tagId: tag.id,
  tagLabel: (
    <TableTagCell
      label={tag.tagName}
      bgColor={tag.backgroundColor}
      icon={tag.icon}
    />
  ),
  created: <TableText>{dayjs(tag.createdAt).format('DD MMMM YYYY')}</TableText>,
  shopsRelated: <TableText>{tag.shopsRelated} Stores</TableText>,
  productsRelated: <TableText>{tag.productsRelated} Products</TableText>
})

export default TagsTableRow
