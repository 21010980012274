import styled from 'styled-components'
import Typography from '../../Typography'

export const SwitchBody = styled.span`
  display: flex;
  width: 50px;
  height: 24px;
  border-radius: 24px;
  transition: background-color 0.2s ease-in;
  padding: 2px;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.greenCreator(49, 57)
      : theme.colors.skyCreator(13, 87)};
`

export const SwitchKnob = styled.span`
  width: 20px;
  border-radius: 20px;
  height: 20px;
  transition: margin-left 0.2s ease-in-out;
  margin-left: ${({ isActive }) => (isActive ? 26 : 0)}px;
  background-color: ${({ theme }) => theme.colors.white};
`
export const Switch = styled.label`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`

export const SwitchLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_2};
  margin-left: 12px;
`
export const SwitchFalseLabel = styled(Typography)`
  margin-right: 12px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.skyNeutral_2 : theme.colors.skyNeutral2};
`
