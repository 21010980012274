import React from 'react'
import { Flag, Dashboard, Store, Unpublished } from '@styled-icons/material'
import dayjs from 'dayjs'

export const getMetrics = (data) => [
  {
    icon: <Store />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Total Payments',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: data?.total ? `$${data?.total}` : ''
  },
  {
    description: '',
    icon: <Dashboard />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Fees',
    numberAndIconColor: 'cyanBase_1',
    onClick: () => {},
    value: `$${data?.fees}`
  },  
  {
    icon: <Unpublished />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Total Withdrawn',
    numberAndIconColor: 'greenBase',
    onClick: () => {},
    value: data?.paid ? `$${data?.paid}` : ''
  },
  {
    icon: <Flag />,
    iconBgColor: 'pinkBase3',
    isActive: false,
    metricName: 'Current Balance',
    description: `Next Withdraw: ${dayjs(data?.nextWithdrawal).format('DD MMMM YYYY')}`,
    numberAndIconColor: 'pinkBase',
    onClick: () => {},
    value: data?.due ? `$${data?.due}` : ''
  }
]

export const tableColumns = [
  {
    Header: 'Order #',
    accessor: 'code'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Phone',
    accessor: 'phone'
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'Total',
    accessor: 'total'
  },
  {
    Header: 'Fee',
    accessor: 'fee'
  },
  {
    Header: 'Shipping',
    accessor: 'isDelivery'
  },
  {
    Header: 'Withdrawn',
    accessor: 'isTransfer'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }  
]
