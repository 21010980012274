import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopProducts from '../ShopProducts'

const ShopProfileProducts = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopProducts shopId={shopId} />
  )
}

export default ShopProfileProducts

ShopProfileProducts.propTypes = {}
