import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const TicketDetailsWrap = styled(VStack)`
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 150px;
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 16px;
  & > :first-child {
    margin-right: 12px;
  }
`
export const TicketImage = styled.div`
  background: url(${({ bg }) => bg}) transparent;
  background-size: cover;
  width: 82px;
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  height: 82px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
`
export const ImagesWrap = styled(HStack)`
  width: 100%;
  flex-wrap: wrap;
`
