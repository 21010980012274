import React from 'react'
import { isEmpty } from 'lodash/lang'
import PropTypes from 'prop-types'
import { Store } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import { ShopLogoImage, EmptyShopLogo } from './styles'
import { getImageFromServer, getImageBase64 } from '../../utils/helpers'

const ShopLogo = ({ errors, source, logoUrl, size, theme }) =>
  !isEmpty(source) || !isEmpty(logoUrl) ? (
    <ShopLogoImage
      size={size}
      error={!isEmpty(errors)}
      src={getImageBase64({ source }) ?? getImageFromServer(logoUrl)}
      alt="store logo"
    />
  ) : (
    <EmptyShopLogo size={size}>
      <Store size={24} color={theme.colors.skyNeutral3} />
    </EmptyShopLogo>
  )

export default withTheme(ShopLogo)
ShopLogo.defaultProps = {
  errors: false,
  source: [],
  logoUrl: '',
  size: 72
}
ShopLogo.propTypes = {
  errors: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  source: PropTypes.array,
  logoUrl: PropTypes.string,
  size: PropTypes.number
}
