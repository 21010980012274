import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'

export const TagNameWrap = styled(HStack)`
  align-items: center;
  & > :first-child {
    margin-right: 20px;
  }
`
export const TagIconWrap = styled(VStack)`
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  min-width: 28px;
  height: 28px;
  margin-right: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
