import React from 'react'
import { PeopleAlt, Unpublished } from '@styled-icons/material'

export const tableColumns = [
  {
    Header: 'Customer Name',
    accessor: 'customerName'
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber'
  },
  {
    Header: 'State',
    accessor: 'stateName'
  },
  {
    Header: 'Email address',
    accessor: 'email'
  },
  {
    Header: 'Friends list',
    accessor: 'friendsList'
  },
  {
    Header: 'Favorite Stores',
    accessor: 'favoriteShops'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }
]

export const getMetrics = (data) => [
  {
    description: ' Users already register',
    icon: <PeopleAlt />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Customers Active',
    numberAndIconColor: 'greenBase',
    onClick: () => {},
    value: data?.activeUsers ? `${data.activeUsers}` : '0'
  },
  {
    description: 'Customers non active in 60D',
    icon: <Unpublished />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Customers Non-active',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: data?.inactiveUsers ? `${data.inactiveUsers}` : '0'
  }
]
