import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTheme } from 'styled-components'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
} from '../../const/UIvariants'
import Button from '../UI/Buttons/Button'
import Typography from '../UI/Typography'
import { ErrorMessageWrap } from '../../global/styles'
import {
  ActionButtonsSection,
  ButtonWrap,
  StyledForm,
  TitleWrap
} from './styles'

const ShowErrorModal = ({ title, error, onClose }) =>  (
  <Formik>
    <StyledForm>
      <TitleWrap>
        <Typography align="center" variant="headingM">{ title }</Typography>
      </TitleWrap>
      <ErrorMessageWrap>
        <Typography variant="textS" color="red">{ error }</Typography>
      </ErrorMessageWrap>  
      <ActionButtonsSection>
        <ButtonWrap>
          <Button
            type="button"
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTONS_SIZES.MED}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonWrap>
      </ActionButtonsSection> 
    </StyledForm>
  </Formik>
)

export default withTheme(ShowErrorModal)

ShowErrorModal.defaultProps = {
}

ShowErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
