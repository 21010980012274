import React from 'react'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { TableText } from '../../../global/styles'
import { formatDate } from '../../../utils/helpers'

const HolidayTableRow = (holiday) => ({
  id: holiday.id,
  name: <TableText>{holiday.name}</TableText>,  
  shop: <TableText>{holiday.shopName}</TableText>,
  dateFrom: (
    <Badge
      variant={ BADGE_VARIANTS.PINK }
      label={formatDate(holiday.dateFrom)}
    />
  ),
  dateTo: (
    <Badge
      variant={ BADGE_VARIANTS.PINK }
      label={formatDate(holiday.dateTo)}
    />
  ),
  isOrganizationHoliday: 
    holiday.isOrganizationHoliday 
      ? <Badge label="Yes" variant={BADGE_VARIANTS.GREEN} size={BADGE_SIZES.SMALL}/>
      : '',  
  canEdit: !holiday.isOrganizationHoliday,
  forSearch: `${holiday.name} ${holiday.dateFrom} ${holiday.dateTo}`
})

export default HolidayTableRow

HolidayTableRow.propTypes = {}
