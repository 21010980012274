/*eslint-disable*/
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import Typography from '../Typography'
import { globalTheme } from '../../../global/theme'

const itemMouseover = keyframes`
  from{
    background-color:${globalTheme.colors.skyCreator(65, 35)};
  }
  to {
    background-color:${globalTheme.colors.skyCreator(67, 41)};
}

`

const labelMouseover = keyframes`
  from{
    margin-left: 0;}
  to{
    margin-left: 10px;
  }
  `
const labelMouseoverReverse = keyframes`
  from{
    margin-left: 10px;
  }
  to{
    margin-left: 0;
  }
  `
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 13px 0 13px 15px;
  width: 100%;
  text-decoration: none;

  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.skyCreator(67, 41) : 'transparent'};
  p {
    animation: ${labelMouseoverReverse} 0.2s ease-in-out forwards;
  }
  &:hover {
    animation: ${itemMouseover} 0.2s ease-in-out forwards;
    p {
      color: ${({ theme }) => theme.colors.white};
      animation: ${labelMouseover} 0.2s ease-in-out forwards;
    }
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const MenuItemLabel = styled(Typography)`
  padding-left: 15px;
  word-wrap: normal;

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : theme.colors.skyCreator(78, 89)};
`
