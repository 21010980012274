import React, { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash/string'
import { Language, LocationOn, Mail, Phone } from '@styled-icons/material'
import { keys } from 'lodash/object'
import { withTheme } from 'styled-components'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash/lang'
import { useGetShopDetailsQuery } from '../../api/shopApi'
import { 
  BADGE_SIZES, 
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
} from '../../const/UIvariants'
import { getHourAMPM } from '../../utils/helpers'
import ROUTES from '../../const/routes'
import Button from '../UI/Buttons/Button'
import Typography from '../UI/Typography'
import Badge from '../UI/Badge'
import LoadingSpinner from '../LoadingSpinner'
import { HStack } from '../../global/styles'
import ShipstationImage from '../../images/shipstation.png';
import EditShopTag from '../EditShopTag'
import {
  AboutThisStore,
  ContactItem,
  ContactLabel,
  DayItem,
  DayLabel,
  ShopInfoDataWrap,
  ShopAboutSection,
  CategoryWrap,
  ShopDescription
} from './styles'

const ShopAbout = ({ theme, shopId }) => {
  const history = useHistory()
  const [editTagId, setEditTagId] = useState(null)
  const [showEditTagModal, setShowEditTagModal] = useState(false)
  const { data: shopDetails, isLoading } = useGetShopDetailsQuery(shopId)

  const stripeConnectClick = () => {
    history.push(generatePath(ROUTES.STRIPE_CONNECT, { shopId }))
  }

  const editTagClick = (tagId) => {
    setEditTagId(tagId)
    setShowEditTagModal(true)
  }

  const handleCloseEditTagModal = () => {
    setShowEditTagModal(false)
  }

  const getTagLabel = () => {
    const taga = shopDetails.tags.find(tag => tag.id === editTagId)
    console.log(taga)
    return taga.label
  }
  
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {showEditTagModal && 
        <EditShopTag
          tagId={editTagId}
          tagLabel={getTagLabel()}
          isOpen={showEditTagModal}
          onClose={handleCloseEditTagModal}
        />
      }        
      {shopDetails && (
        <>
          <ShopDescription variant="textM" color={theme.colors.skyNeutral1}>
            {shopDetails.description}
          </ShopDescription>
          <Typography bold variant="textS" color={theme.colors.skyNeutral_2}>
            About this Store
          </Typography>
          <AboutThisStore>
            <ShopAboutSection>
              <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                Opening hours
              </Typography>
              <ShopInfoDataWrap>
                {keys(shopDetails.schedule).map((day) => (
                  <DayItem key={day}>
                    <DayLabel
                      variant="textXS"
                      color={theme.colors.skyNeutral_1}
                    >
                      {capitalize(day)}
                    </DayLabel>
                    <Badge
                      variant={BADGE_VARIANTS.GREEN}
                      label={
                        shopDetails.schedule[day].isOpen
                          ? `${getHourAMPM(
                              shopDetails.schedule[day].open
                            ).toUpperCase()} - ${getHourAMPM(
                              shopDetails.schedule[day].close
                            ).toUpperCase()}`
                          : 'Closed'
                      }
                    />
                  </DayItem>
                ))}
              </ShopInfoDataWrap>
            </ShopAboutSection>
            {(shopDetails?.email ||
              shopDetails?.phone ||
              shopDetails?.website ||
              shopDetails?.onlineShopUrl ||
              shopDetails?.instagram ||
              shopDetails?.twitter) && (
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Contacts
                </Typography>
                <ShopInfoDataWrap>
                  {shopDetails.email && (
                    <ContactItem>
                      <Mail color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.email}
                      </ContactLabel>
                    </ContactItem>
                  )}
                  {shopDetails?.phone && (
                    <ContactItem>
                      <Phone color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        +{shopDetails?.phone}
                      </ContactLabel>
                    </ContactItem>
                  )}
                  {shopDetails?.website && (
                    <ContactItem>
                      <Language color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.website}
                      </ContactLabel>
                    </ContactItem>
                  )}
                  {shopDetails?.onlineShopUrl && (
                    <ContactItem>
                      <Language color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.onlineShopUrl}
                      </ContactLabel>
                    </ContactItem>
                  )}
                  {shopDetails?.facebook && (
                    <ContactItem>
                      <Language color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.facebook}
                      </ContactLabel>
                    </ContactItem>
                  )}
                  {shopDetails?.instagram && (
                    <ContactItem>
                      <Language color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.instagram}
                      </ContactLabel>
                    </ContactItem>
                  )}
                  {shopDetails?.twitter && (
                    <ContactItem>
                      <Language color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.twitter}
                      </ContactLabel>
                    </ContactItem>
                  )}
                </ShopInfoDataWrap>
              </ShopAboutSection>
            )}
            <HStack>
              {shopDetails?.displayAddress && (
                <ShopAboutSection>
                  <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                    Location
                  </Typography>
                  <ShopInfoDataWrap>
                    <ContactItem>
                      <LocationOn color={theme.colors.skyNeutral_1} size={16} />
                      <ContactLabel variant="textS">
                        {shopDetails?.displayAddress}
                      </ContactLabel>
                    </ContactItem>
                  </ShopInfoDataWrap>
                </ShopAboutSection>
              )}
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Join Date
                </Typography>
                <ShopInfoDataWrap>
                  <Typography color={theme.colors.skyNeutral_1} variant="textS">
                    {dayjs(shopDetails.joinDate).format('DD MMMM YYYY')}
                  </Typography>
                </ShopInfoDataWrap>
              </ShopAboutSection>
            </HStack>
            <HStack>
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Store enabled
                </Typography>
                <ShopInfoDataWrap>
                  <Typography color={theme.colors.skyNeutral_1} variant="textS">
                    {shopDetails.isStoreEnabled ? 'Yes' : 'No'}
                  </Typography>
                </ShopInfoDataWrap>
              </ShopAboutSection>
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Show products
                </Typography>
                <ShopInfoDataWrap>
                  <Typography color={theme.colors.skyNeutral_1} variant="textS">
                    {shopDetails.showProducts ? 'Yes' : 'No'}
                  </Typography>
                </ShopInfoDataWrap>
              </ShopAboutSection>
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Chat enabled
                </Typography>
                <ShopInfoDataWrap>
                  <Typography color={theme.colors.skyNeutral_1} variant="textS">
                    {shopDetails.isChatEnabled ? 'Yes' : 'No'}
                  </Typography>
                </ShopInfoDataWrap>
              </ShopAboutSection>
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Show chat message
                </Typography>
                <ShopInfoDataWrap>
                  <Typography color={theme.colors.skyNeutral_1} variant="textS">
                    {shopDetails.showChatMessage ? 'Yes' : 'No'}
                  </Typography>
                </ShopInfoDataWrap>
              </ShopAboutSection>
              {shopDetails?.chatMessage && (
                <ShopAboutSection>
                  <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                    Chat message
                  </Typography>
                  <ShopInfoDataWrap>
                    <ContactItem>
                      <ContactLabel variant="textS">
                        {shopDetails?.chatMessage}
                      </ContactLabel>
                    </ContactItem>
                  </ShopInfoDataWrap>
                </ShopAboutSection>
              )}              
              {shopDetails?.volunteerTitle && (
                <ShopAboutSection>
                  <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                    Volunteer title
                  </Typography>
                  <ShopInfoDataWrap>
                    <ContactItem>
                      <ContactLabel variant="textS">
                        {shopDetails?.volunteerTitle}
                      </ContactLabel>
                    </ContactItem>
                  </ShopInfoDataWrap>
                </ShopAboutSection>
              )}              
              {shopDetails?.volunteerMessage && (
                <ShopAboutSection>
                  <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                    Volunteer message
                  </Typography>
                  <ShopInfoDataWrap>
                    <ContactItem>
                      <ContactLabel variant="textS">
                        {shopDetails?.volunteerMessage}
                      </ContactLabel>
                    </ContactItem>
                  </ShopInfoDataWrap>
                </ShopAboutSection>
              )}              
            </HStack>
            <HStack>
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Connected to Stripe
                </Typography>
                <ShopInfoDataWrap>
                  <Typography color={theme.colors.skyNeutral_1} variant="textS">
                    {shopDetails.isStripeConnected ? 'Yes' : 'No'}
                  </Typography>
                </ShopInfoDataWrap>
              </ShopAboutSection>
              {!shopDetails.isStripeConnected &&
              <ShopAboutSection>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={stripeConnectClick}
                >
                  Connect
                </Button>
              </ShopAboutSection>
              }
            </HStack>
            <HStack>
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Connected to Shipstation
                </Typography>
                <ShopInfoDataWrap>
                  <a href="https://www.shipstation.com" target="_blank" rel="noreferrer">
                    <img src={ShipstationImage} alt="shipstation"/>
                  </a>
                </ShopInfoDataWrap>
              </ShopAboutSection>
            </HStack>
	          {!isEmpty(shopDetails?.tags) && (
              <ShopAboutSection>
                <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                  Store Categories
                </Typography>
                { shopDetails.tags.map((tag) => (
                <ShopInfoDataWrap key={tag.id}>
                  <CategoryWrap>
                    <Badge
                      label={tag.label}
                      variant={BADGE_VARIANTS.LIGHT}
                      size={BADGE_SIZES.SMALL}
                    />
                  </CategoryWrap>
                  {keys(tag.schedule).map((day) => (
                    <DayItem key={day}>
                      <DayLabel
                        variant="textXS"
                        color={theme.colors.skyNeutral_1}
                      >
                        {capitalize(day)}
                      </DayLabel>
                      <Badge
                        variant={BADGE_VARIANTS.GREEN}
                        label={
                          tag.schedule[day].isOpen
                            ? `${getHourAMPM(
                              tag.schedule[day].open
                              ).toUpperCase()} - ${getHourAMPM(
                                tag.schedule[day].close
                              ).toUpperCase()}`
                            : 'Closed'
                        }
                      />
                    </DayItem>
                  ))}
                  <CategoryWrap>
                    <Button
                      type="button"
                      variant={BUTTON_VARIANTS.PRIMARY}
                      size={BUTTONS_SIZES.SMALL}
                      onClick={() => { editTagClick(tag.id) }}
                    >
                      Edit
                    </Button>
                  </CategoryWrap>
                </ShopInfoDataWrap>
                ))}  
              </ShopAboutSection>
            )}
          </AboutThisStore>
        </>
      )}
    </>
  )
}

export default withTheme(ShopAbout)

ShopAbout.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
