import React, { useEffect, useState } from 'react'
import ImageUploading from 'react-images-uploading'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { withTheme } from 'styled-components'
import { AddPhotoAlternate } from '@styled-icons/material'
import { getImageFromServer, getErrorMessage } from '../../../utils/helpers'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import EditDeleteMenu from '../../../components/EditDeleteMenu'
import Typography from '../../../components/UI/Typography'
import Paginator from '../../../components/Paginator'
import { Footer, VStack, ErrorMessageTopWrap } from '../../../global/styles'
import {
  useLazyGetShopImagesQuery,
  useDeleteShopImageMutation,
  usePostShopImageMutation
} from '../../../api/shopGalleryApi'
import {
  AddImageWrap,
  ErrorMessage,
  ShopImage,
  ShopImagesWrap,
  StyledImage,
  TimeStampAndMenuWrap
} from './styles'

const ITEMS_PER_PAGE = 50

// todo figure out is that necessary to extend dayjs in every component
dayjs.extend(relativeTime)

const ShopGallery = ({ theme, shopId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [error, setError] = useState('')
  const [getImages, { data: shopImages, isLoading, error: loadError }] = useLazyGetShopImagesQuery()
  const [postShopImage] = usePostShopImageMutation()
  const [deleteShopImage] = useDeleteShopImageMutation()

  const handleAddShopImage = (imageList) => {
    setError("")
    postShopImage({ shopId, image: imageList[0].data_url })
      ?.unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })    
  }

  const handleDeleteShopImages = (imageId) => {
    setError("")
    deleteShopImage(imageId)
      ?.unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })     
  }

  const loadImages = () => {
    setError('')
    getImages({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      ?.unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadImages()
  }, [])

  useEffect(() => {
    loadImages()
  }, [currentPage])

  useEffect(() => {
    if (shopImages) {
      setTotalPages(shopImages.pagination.pages)
      setTotalRows(shopImages.pagination.total)
      if (currentPage >= shopImages.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopImages])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {error &&
        <ErrorMessageTopWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageTopWrap>
      }
      {!isLoading  && !loadError && 
        <ShopImagesWrap>
          <ImageUploading
            onChange={handleAddShopImage}
            acceptType={['jpg', 'gif', 'png']}
            maxFileSize={4000000}
            dataURLKey="data_url"
          >
            {({ onImageUpload, errors }) => (
              <AddImageWrap onClick={onImageUpload}>
                {errors && (
                  <ErrorMessage variant="textXS" align="center">
                    Image is bigger than 4mb{' '}
                  </ErrorMessage>
                )}
                <AddPhotoAlternate width={46} color={theme.colors.greenBase} />
              </AddImageWrap>
            )}
          </ImageUploading>
          {shopImages &&
            (isEmpty(shopImages) ? (
              <VStack
                alignItems="center"
                justify="center"
                style={{ padding: '32px' }}
              >
                <p> No photos added yet</p>
              </VStack>
            ) : (
              shopImages.images.map((image) => (
                <ShopImage key={image.id}>
                  <StyledImage
                    src={getImageFromServer(image.image)}
                    alt="image"
                  />
                  <TimeStampAndMenuWrap>
                    <Typography variant="textXS" color={theme.colors.white}>
                      {dayjs(image.createdAt).fromNow()}
                    </Typography>
                    <EditDeleteMenu
                      onDelete={() => {
                        handleDeleteShopImages(image.id)
                      }}
                    />
                  </TimeStampAndMenuWrap>
                </ShopImage>
              ))
            ))
          }
        </ShopImagesWrap>
      }
      {shopImages && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopImages.images.length} from ${totalRows} Images`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
    </>
  )
}

export default withTheme(ShopGallery)

ShopGallery.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
