import {
  Album,
  Bathtub,
  Checkroom,
  Devices,
  HeadsetMic,
  House,
  LocalMall,
  RestaurantMenu
} from '@styled-icons/material'
import React from 'react'

export const USER_ROLE_STORE = 'storeAdmin'
export const USER_ROLE_SUPER = 'superAdmin'
export const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

export const DEFAULT_OPEN_TIME =
  'Tue Jul 06 2021 09:00:00 GMT+0300 (Eastern European Summer Time)'
export const DEFAULT_CLOSE_TIME =
  'Tue Jul 06 2021 17:00:00 GMT+0300 (Eastern European Summer Time)'
export const stub = '' 

export const TAG_ICONS = {
  checkroom: <Checkroom />,
  bathtub: <Bathtub />,
  house: <House />,
  devices: <Devices />,
  restaurant_menu: <RestaurantMenu />,
  headset_mic: <HeadsetMic />,
  local_mall: <LocalMall />,
  album: <Album />
}

export const TAG_COLORS = {
  BLUE: 'hsla(219, 77%, 54%, 1)',
  GREY: 'hsla(220, 17%, 43%, 1)',
  RUSTY: 'hsla(22, 100%, 38%, 1)',
  BROWN: 'hsla(22, 19%, 39%, 1)',
  YELLOW: 'hsla(47, 86%, 59%, 1)',
  GREEN: 'hsla(119, 49%, 57%, 1)',
  PINK: 'hsla(335, 76%, 69%, 1)',
  CYAN: 'hsla(173, 65%, 52%, 1)',
  SWAMP: 'hsla(172, 16%, 61%, 1)'
}
