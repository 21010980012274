import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ShopInfoSection, ShopPageWrap, ShopTabsInfo } from './styles'
import ShopAsideReviews from '../ShopAsideReviews'
import ShopAsideWizard from '../ShopAsideWizard'
import TabsWrap from '../../../components/UI/Tabs/TabsWrap'
import ShopTopBanner from '../ShopTopBanner'
import { selectShopId, selectIsEditingShop } from '../../../app/selectors'
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary'

const SHOP_TABS = [
  { label: 'About', destination: '' },
  { label: 'Products', destination: '/products' },
  { label: 'Posts', destination: '/posts' },
  { label: 'Donations', destination: '/donations' },
  { label: 'Volunteers', destination: '/volunteers' },
  { label: 'Gallery', destination: '/gallery' },
  { label: 'Videos', destination: '/videos' },
  { label: 'FAQ', destination: '/faq' },
  { label: 'Holidays', destination: '/holidays' },
]
const isStoreSetUp = true

const ShopPageHoc = ({ children }) => {
  const shopId = useSelector(selectShopId)
  const isEditing = useSelector(selectIsEditingShop)
  const { path } = useRouteMatch()
  return (
    <ShopPageWrap>
      <ShopInfoSection>
        <ShopTopBanner shopId={shopId}/>
        <ShopTabsInfo>
          {!isEditing && <TabsWrap tabsOptions={SHOP_TABS} path={path} />}
          <ErrorBoundary>{children}</ErrorBoundary>
        </ShopTabsInfo>
      </ShopInfoSection>
      <ErrorBoundary>
        {isStoreSetUp ? <ShopAsideReviews shopId={shopId} /> : <ShopAsideWizard />}
      </ErrorBoundary>
    </ShopPageWrap>
  )
}

export default ShopPageHoc

ShopPageHoc.propTypes = {
  children: PropTypes.node.isRequired
}
