import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const QuestionWrap = styled(HStack)`
  height: 130px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);
  min-width: 30%;
  width: 32%;
  margin-right: 2%;
  padding: 8px;
  position: relative;
  margin-bottom: 12px;
  &:nth-child(3n) {
    margin-right: 0;
  }
`
export const QuestionDetailsWrap = styled(VStack)`
  margin-left: 16px;
  min-width: 60%;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`
export const QuestionTitleWrap = styled(HStack)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
