import React from 'react'
import { Flag, Dashboard, Store, Unpublished } from '@styled-icons/material'

export const getMetrics = (data, showStore) => [
  {
    value: `${data?.transfer?.createdAt}`,
    iconBgColor: 'pinkBase3',
    icon: <Flag />,
    isActive: false,
    metricName: `Transfer`,
    numberAndIconColor: 'pinkBase',
    onClick: () => {},
    description: `${showStore ? data?.transfer?.shopName : ''}`
  },
  {
    description: '',
    icon: <Store />,
    iconBgColor: 'skyNeutral3',
    isActive: false,
    metricName: 'Total',
    numberAndIconColor: 'skyNeutral_1',
    onClick: () => {},
    value: `$${data?.dashboardInfo?.total}`
  },
  {
    description: '',
    icon: <Dashboard />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Fees',
    numberAndIconColor: 'cyanBase_1',
    onClick: () => {},
    value: `$${data?.dashboardInfo?.fees}`
  },
  {
    description: '',
    icon: <Unpublished />,
    iconBgColor: 'green3',
    isActive: false,
    metricName: 'Withdrawn',
    numberAndIconColor: 'greenBase',
    onClick: () => {},
    value: `$${data?.dashboardInfo?.paid}`
  }
]

export const tableColumns = [
  {
    Header: 'Order #',
    accessor: 'code'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'First Name',
    accessor: 'firstName'
  },
  {
    Header: 'Last Name',
    accessor: 'lastName'
  },
  {
    Header: 'Phone',
    accessor: 'phone'
  },
  {
    Header: 'Address',
    accessor: 'address'
  },
  {
    Header: 'Total',
    accessor: 'total'
  },
  {
    Header: 'Fee',
    accessor: 'fee'
  },
  {
    Header: 'Shipping',
    accessor: 'isDelivery'
  },
  {
    Header: 'Withdrawn',
    accessor: 'isTransfer'
  },
  {
    Header: 'Status',
    accessor: 'status'
  }  
]
