import styled from 'styled-components'
import Typography from '../UI/Typography'

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`
export const StyledRow = styled.tr`
  max-width: 100%;
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.skyNeutral4};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.skyBase3 : 'none'};
  :hover {
    cursor: pointer;
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.skyBase3 : theme.colors.skyBase4};
  }
`
export const StyledTableBody = styled.tbody`
  max-width: 100%;
`
export const TableHeader = styled.thead`
  height: 38px;
  background-color: ${({ theme }) => theme.colors.skyNeutral5};
`
export const HeaderRow = styled(StyledRow)`
  height: 38px;
`
export const TableHeaderTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
export const TableCell = styled.td`
  padding-right: 8px;
  :first-child,
  :last-child {
    width: 48px;
  }
`
export const TableWrap = styled.div`
  display: block;
  width: 100%;
  position: relative;
  margin-top: 28px;
`
