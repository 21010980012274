import React from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { find } from 'lodash/collection'
import { InputError, InputOuterLabel } from '../TextInput/styles'
import { InputWrapper, StyledSelectMany } from './styles'
import { CustomDropDownArrow, CustomRemoveCircle } from './parts'

const SelectManyInput = ({ inputName, label, options, disabled }) => {
  const [field, meta, helpers] = useField({ name: inputName })
  const handleChange = (selectedOption) => {
    const values = selectedOption.map((option) => option.value)
    helpers.setValue(values)
  }
  return (
    <InputWrapper>
      <InputOuterLabel htmlFor={field.name} variant="textS" component="label">
        {label}
      </InputOuterLabel>
      <StyledSelectMany
        isDisabled={disabled}
        isMulti
        id={field.name}
        classNamePrefix="rs"
        onChange={handleChange}
        options={options}
        components={{
          MultiValueRemove: CustomRemoveCircle,
          DropdownIndicator: CustomDropDownArrow
        }}
        value={meta.value.map((item) =>
          find(options, (option) => option.value === item)
        )}
      />
      <InputError>{meta.error ? meta.error : null }</InputError>
    </InputWrapper>
  )
}

export default SelectManyInput

SelectManyInput.defaultProps = {
  label: null,
  disabled: false
}

SelectManyInput.propTypes = {
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ).isRequired
}
