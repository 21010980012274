/*eslint-disable*/
import React from 'react'
import Typography from '../../components/UI/Typography'
import Button from '../../components/UI/Buttons/Button'
import { FallbackUIWrap } from './styles'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.handleReload = this.handleReload.bind(this)
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.info(error, errorInfo)
  }

  handleReload() {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <FallbackUIWrap justify="center" alignItems="center">
          <Typography variant="textS" align="center">
            Something went wrong. Please try to refresh
          </Typography>
          <Button onClick={this.handleReload} type="button">
            Refresh page
          </Button>
        </FallbackUIWrap>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
