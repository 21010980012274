import styled from 'styled-components'
import Typography from '../../Typography'

export const StyledTab = styled.button`
  cursor: pointer;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.skyNeutral : theme.colors.skyNeutral3};
  border-bottom-style: solid;
  border-width: 0 0 2px 0;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
  padding: 16px 0 12px 0;
  position: relative;
  transition: border-bottom-color 0.1s ease-in-out;
  :after {
    content: '';
    display: block;
    border-bottom: 2px solid ${({ theme }) => theme.colors.skyNeutral};
    width: 0;
    position: absolute;
    right: 0;
    bottom: -2px;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
  }
  :hover {
    &:after {
      width: 100%;
    }
    opacity: 1;
    p {
      color: ${({ theme }) => theme.colors.skyNeutral};
    }
  }
`

export const TabLabel = styled(Typography)`
  transition: color 0.2s ease-in-out;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.skyNeutral : theme.colors.skyNeutral3};
`
