import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { FooterWrap, ItemsCounter } from './styles'
import Paginator from '../Paginator'

const TableFooter = ({
  itemsDisplayedPerPage,
  currentPage,
  setCurrentPage,
  amountOfPages,
  itemsCount,
  pageName
}) => {
  const location = useLocation()

  const splittedPathname = location.pathname.split('/')
  return (
    <FooterWrap>
      <ItemsCounter>
        {itemsDisplayedPerPage} from {itemsCount}{' '}
        {pageName || splittedPathname[splittedPathname.length - 1]}
      </ItemsCounter>
      <Paginator {...{ currentPage, setCurrentPage, amountOfPages }} />
    </FooterWrap>
  )
}

export default TableFooter

TableFooter.defaultProps = {
  pageName: null
}

TableFooter.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsDisplayedPerPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  amountOfPages: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  pageName: PropTypes.string
}
