import React, { useState } from 'react'
import { useField } from 'formik'
import { find } from 'lodash/collection'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { InputError, InputOuterLabel } from '../TextInput/styles'
import { StyledSelect, InputWrapper } from './styles'

const FormSelectOneInput = ({ inputName, label, options, theme, value, isGrey }) => {
  const [field, meta, helpers] = useField({ name: inputName })
  const [selected, setSelected] = useState(value)
  const handleChange = (selectedOption) => {
    helpers.setValue(selectedOption.value)
    setSelected(selectedOption.value)
  }
  return (
    <InputWrapper>
      <InputOuterLabel
        htmlFor={field.name}
        variant="textS"
        color={theme.colors.skyNeutral_2}
        component="label"
      >
        {label}
      </InputOuterLabel>
      <StyledSelect
        classNamePrefix="rs"
        onChange={handleChange}
        options={options}
        isGrey={isGrey}
        value={find(options, (option) => option.value === selected)}
      />
      <InputError>{meta.error ? meta.error : null}</InputError>
    </InputWrapper>
  )
}

export default withTheme(FormSelectOneInput)

FormSelectOneInput.defaultProps = {
  value: '',
  isGrey: false
}

FormSelectOneInput.propTypes = {
  theme: PropTypes.object.isRequired,
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.any })
  ).isRequired,
  value: PropTypes.any,
  isGrey: PropTypes.bool
}
