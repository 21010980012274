import React from 'react'
import {
  BADGE_SIZES,
  BADGE_VARIANTS
} from '../../../const/UIvariants'
import Badge from '../../../components/UI/Badge'
import { HStack, TableDarkText, TableText } from '../../../global/styles'
import TableImageLabelCell from '../../../components/TableImageLabelCell'

const ProductTableRow = (product) => ({
  id: product.id,
  name: (
    <TableImageLabelCell
      image={product.image}
      label={product.name}
      isRounded={false}
    />
  ),
  organizationName: (
    <TableText>{product.organizationName}</TableText>
  ),
  price: <TableDarkText>${product.price}</TableDarkText>,
  shippingCost: <TableDarkText>${product.shippingCost}</TableDarkText>,
  isShippingEnabled: (
    <Badge
      key="isShippingEnabled"
      variant={product.isShippingEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
      size={BADGE_SIZES.SMALL}
      label={product.isShippingEnabled ? "Yes" : "No"}
    />
  ),
  isCollectEnabled: (
    <Badge
      key="isCollectEnabled"
      variant={product.isCollectEnabled ? BADGE_VARIANTS.GREEN : BADGE_VARIANTS.LIGHT}
      size={BADGE_SIZES.SMALL}
      label={product.isCollectEnabled ? "Yes" : "No"}
    />
  ),
  tags: (
    <HStack>
      {product.tagNames.map((tag) => (
        <Badge
          key={tag}
          label={tag}
          variant={BADGE_VARIANTS.LIGHT}
          size={BADGE_SIZES.SMALL}
        />
      ))}
    </HStack>
  ),
  forSearch: `${product.name}`
})

export default ProductTableRow

ProductTableRow.propTypes = {}
