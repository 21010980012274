import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const MetricsWrap = styled(HStack)`
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`

export const MetricItemWrap = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
  height: 135px;
  justify-content: space-between;
  margin-right: 24px;
  min-width: 0;
  padding: 16px;
  border: 2px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.skyNeutral2 : 'transparent'};
  box-shadow: 0 6px 16px -4px rgba(15, 18, 20, 0.1);
  &:last-child {
    margin-right: 0;
  }
`

export const MetricLabel = styled(Typography)`
  margin-bottom: 9px;
`

export const IconWrap = styled(VStack)`
  width: 36px;
  height: 36px;
  margin-right: 8px;
  background-color: ${({ bgColor }) => bgColor};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`
