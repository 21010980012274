import React from 'react'
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { withTheme } from 'styled-components'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../const/UIvariants'
import Button from '../UI/Buttons/Button'
import DateInput from '../UI/Inputs/DateInput'
import {
  FormWrap,
  Headline,
  DateWrap,
  ButtonWrap
} from './styles'

const DateRangeModal = ({ isOpen, onClose, onSubmit, dateFrom, dateTo }) => (
  <Modal {...{ isOpen }}>
    <FormWrap>
      <Formik
        initialValues={ {dateFrom, dateTo} }
        validationSchema={Yup.object({
          dateFrom: Yup.date().required('From is required'),
          dateTo: Yup.date().required('To is required')      
        })}
        onSubmit={(values) => { onSubmit(values) }}
      >
        {({ values }) => (
          <Form>
            <Headline>Date Range</Headline>
            <DateWrap>
              <DateInput
                label="From"
                inputName="dateFrom"
                placeholder="Date from"
              />
              <DateInput
                label="To"
                inputName="dateTo"
                placeholder="Date to"
                minValue={values.dateFrom}
              />
            </DateWrap>
            <ButtonWrap>
              <Button
                type="submit"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
              >
                Select
              </Button>
              <Button
                type="button"
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={onClose}
              >
                Cancel
              </Button>
            </ButtonWrap>
          </Form>
        )}
      </Formik>
    </FormWrap>
  </Modal>
)

export default withTheme(DateRangeModal)

DateRangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired
}
