import styled from 'styled-components/macro'
import { Form } from 'formik'
import { HStack, VStack } from '../../../global/styles'

export const FormWrap = styled(VStack)`
  justify-content: flex-start;
  min-height: 500px;
`
export const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`
export const TitleWrap = styled(VStack)`
  padding: 32px;
  width: 100%;
  align-items: stretch;
`
export const OrganisationWrapper = styled(VStack)`
  padding: 0 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const FormInputsWrap = styled(HStack)`
  padding: 0 32px 0 32px;
  width: 100%;
  align-items: stretch;
`
export const LeftHalf = styled(VStack)`
  width: 50%;
  min-width: 365px;
  margin-right: 28px;
  align-items: stretch;
`
export const RightHalf = styled(VStack)`
  width: 50%;
  margin-left: 28px;
  min-width: 365px;
  align-items: stretch;
`
export const ImageUploadWrap = styled(VStack)`
  justify-content: space-between;
  align-items: stretch;
  min-height: 200px;
  width: 100%;
`
export const ImageUploadContent = styled(VStack)`
  min-width: 65%;
  width: 100%;
`
export const ImageControlButtonsWrap = styled(HStack)`
  margin: 10px 0;
  width: 100%;
  justify-content: space-between;
`
export const DateWrap = styled(HStack)`
  width: 100%;
  margin-top: 10px;
  justify-content: space-between;
  & > * :first-child {
    //width: 48%;
    margin-right: 20px;
  }
  input {
    min-width: 164px;
  }
`
export const ActionButtonsSection = styled.div`
  display: flex;
  padding: 10px 32px 32px 32px;
`
export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > :first-child {
    margin-right: 12px;
  }  
`  
