import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { isNull } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { getErrorMessage } from '../../../utils/helpers'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import Button from '../../../components/UI/Buttons/Button'
import TextInput from '../../../components/UI/Inputs/TextInput'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import ShowErrorModal from '../../../components/ShowErrorModal'
import {
  useLazyGetShopVolunteerQuery,
  usePostShopVolunteerMutation,
  usePutShopVolunteerMutation
} from '../../../api/shopVolunteersApi'
import { ErrorMessageWrap } from '../../../global/styles'
import {
  ActionButtonsSection,
  ButtonWrap,
  FormInputsWrap,
  FormWrap,
  LeftHalf,
  RightHalf,
  StyledForm
} from './styles'

const emptyVolunteer = {
  name: '',
  email: '',
  phone: '',
  message: '',
}
const ShopVolunteerModal = ({ isOpen, onClose, editVolunteerId, theme, shopId }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [initialData, setInitialData] = useState(emptyVolunteer)
  const [error, setError] = useState('')
  const [getVolunteer, { data: shopVolunteer, error: loadError }] = useLazyGetShopVolunteerQuery()
  const [postShopVolunteer] = usePostShopVolunteerMutation()
  const [updateShopVolunteer] = usePutShopVolunteerMutation()

  const title = (isNull(editVolunteerId)) ? 'Add Volunteer' : 'Edit Volunteer';

  useEffect(() => {
    if (!isNull(editVolunteerId)) {
      getVolunteer(editVolunteerId)
    } else {
      setInitialData(emptyVolunteer)
      setIsSuccess(true)
    }
  }, [])
  
  useEffect(() => {
    if (shopVolunteer) {
      setInitialData({
        name: shopVolunteer.name,
        email: shopVolunteer.email,
        phone: shopVolunteer.phone,
        message: shopVolunteer.message
      })
      setIsSuccess(true)
    }
  }, [shopVolunteer])  

  const handleSubmitForm = (values) => {
    setError('')

    const data = values;
    if (!isNull(shopId)) {
      data.shopId = shopId
    }    
    if (editVolunteerId) {
      updateShopVolunteer({
        volunteerId: editVolunteerId,
        data
      })
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })
    } else {
      postShopVolunteer(data)
      ?.unwrap()
      .then(() => {
        onClose()
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })      
    }
  }

  return (
    <Modal {...{ isOpen }}>
      {!isSuccess && !loadError && 
      <LoadingSpinner />
      }      
      {loadError && 
        <ShowErrorModal
          title={title}
          error="Network Error"
          onClose={onClose}
        />
      }
      {isSuccess &&     
        <FormWrap>
          <Formik
            initialValues={initialData}
            validationSchema={Yup.object({
              name: Yup.string().required('Name is required'),
              email: Yup.string().required('Email is required'),
              phone: Yup.string().required('Phone is required')
            })}
            onSubmit={(values) => {
              handleSubmitForm(values)
            }}
          >
            <StyledForm>
              <FormInputsWrap>
                <LeftHalf>
                  <Typography variant="headingM" color={theme.colors.skyNeutral_2}>{ title }</Typography>
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="name"
                    label="Name"
                    placeholder="Enter name"
                  />
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="email"
                    label="Email"
                    placeholder="Enter email"
                  />
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="phone"
                    label="Phone"
                    placeholder="Enter phone"
                  />                    
                </LeftHalf>
                <RightHalf>
                  <TextAreaInput
                    inputName="message"
                    label="Message"
                    rows={16}
                    placeholder="Enter message"
                  />   
                </RightHalf>
              </FormInputsWrap>
              {error && 
                <ErrorMessageWrap>
                  <Typography variant="textS" color="red">
                  {error}
                  </Typography>
                </ErrorMessageWrap>          
               }                
              <ActionButtonsSection>
                <ButtonWrap>
                  <Button
                    type="submit"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.MED}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTONS_SIZES.MED}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonWrap>
              </ActionButtonsSection>
            </StyledForm>
          </Formik>
        </FormWrap>
      }
    </Modal>
  )
}

export default withTheme(ShopVolunteerModal)

ShopVolunteerModal.defaultProps = {
  editVolunteerId: null,
  shopId: null
}

ShopVolunteerModal.propTypes = {
  theme: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editVolunteerId: PropTypes.string,
  shopId: PropTypes.string
}
