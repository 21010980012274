export const tableColumns = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }
]

export default tableColumns

