import styled from 'styled-components'
import { HStack, VStack } from '../../global/styles'
import Typography from '../UI/Typography'

export const PostWrap = styled(HStack)`
  height: 150px;
  min-width: 40%;
  width: 48%;
  margin-right: 4%;
  position: relative;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  button:only-of-type {
    max-width: none;
  }
`
export const StoreWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  border-top: 1px solid #ece9ef;
  &:first-child {
    border-top: none;
  }
`
export const InfoWrap = styled(VStack)`
  width: 100%;
  margin-left: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0;
  margin-right: auto;
`
export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const ButtonWrap = styled.div`
  margin-top: auto;
`
export const PostImage = styled.img`
  background-size: cover;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  border-radius: 3px;
  min-width: 153px;
  max-width: 153px;
`

export const PostDetailsWrap = styled(VStack)`
  margin-left: 16px;
  min-width: 40%;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`
export const PostTitleWrap = styled(HStack)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`
export const PostDescription = styled(Typography)`
  max-height: 44px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
export const PostTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.skyNeutral};
`
