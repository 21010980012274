import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

// eslint-disable-next-line import/prefer-default-export
export const InputsWrap = styled(VStack)`
  background: ${({ theme }) => theme.colors.white};
  margin-top: 24px;
`
export const SettingsWrap = styled(VStack)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 24px;
  height: 100%;
`
export const ButtonWrap = styled.div`
  display: flex;
`
export const ActionButtonsSection = styled(HStack)`
  border-top: 1px solid hsla(220, 66%, 8%, 0.1);
  padding: 16px 0;
  width: 100%;
`
