import styled from 'styled-components'
import { DetailsInfoBlock, HStack, VStack } from '../../../global/styles'

export const ShopInfoBlock = styled(DetailsInfoBlock)`
`
export const ShopDetailsWrap = styled(VStack)`
  width: 100%;
`
export const TagsWrap = styled(HStack)`
  flex-wrap: wrap;
  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin: 16px 0;
  & > :first-child {
    margin-right: 12px;
  }
`
