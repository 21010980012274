import { useField } from 'formik'
import { useEffect, useState } from 'react'

const usePhoneNumberInput = (inputName) => {
  const [field, meta, helpers] = useField({ name: inputName })
  const { name, onBlur, value, multiple } = field
  const [maskedValue, setMaskedValue] = useState('')

  const handleChange = (e) => {
    const inputValue = e.target.value.toString()
    const phonePattern = '+## ### ### #####'
    let i = 0
    const unMaskedValue = inputValue.split('+').join('').split(' ').join('')
    helpers.setValue(unMaskedValue)
    setMaskedValue(
      // eslint-disable-next-line no-plusplus
      phonePattern.replace(/#/g, () => unMaskedValue[i++] ?? '').trim()
    )
  }
  useEffect(() => {
    if (value) {
      handleChange({ target: { value } })
    }
  }, [])
  return { meta, name, onBlur, value, multiple, maskedValue, handleChange }
}

export default usePhoneNumberInput
