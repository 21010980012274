import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ColorPreview = styled.label`
  cursor: pointer;
  background-color: ${({ bgColor }) => bgColor};
  width: 30px;
  height: 30px;
  margin-right: 16px;
  min-width: 30px;
  border-radius: 100px;
  border: 2px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.skyNeutral : 'transparent'};
`
