import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from '../features/auth/authSlice'
import shop from '../features/shops/shopSlice'
import { api } from '../api/api.generated'
import hereWegoApiSlice from '../api/hereWegoApi'

const persistConfig = {
  key: 'ophop-admin',
  version: 1,
  blacklist: ['api', 'hereApi'],
  storage
}
export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [hereWegoApiSlice.reducerPath]: hereWegoApiSlice.reducer,
  auth,
  shop,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(api.middleware)
  ]
})
export const persistor = persistStore(store)
export default store
