import React from 'react'
import PropTypes from 'prop-types'
import {
  StoreWrap,
  InfoWrap,
  Title,
  ButtonWrap,
  PostDescription,
  PostDetailsWrap,
  PostImage,
  PostTitle,
  PostTitleWrap,
  PostWrap
} from './styles'
import { formatPostDeadline, getImageFromServer } from '../../utils/helpers'
import EditDeleteMenu from '../EditDeleteMenu/EditDeleteMenu'
import Badge from '../UI/Badge'
import { BADGE_SIZES, BADGE_VARIANTS } from '../../const/UIvariants'
import EntityPicture from '../EntityPicture'

const ShopPostItem = ({
  postId,
  postImage,
  handleEditPost,
  handleDeletePost,
  postTitle,
  postDescription,
  postLikeCount,
  postInterestedCount,
  postAttendedCount,
  postDeadline,
  dateStart,
  dateEnd,
  showCounts,
  showStore,
  storeName,
  storeImage, 
  storeBgColor,
  deadlineText,
  canEdit,
  canDelete
}) => (
  <PostWrap>
    { false &&
    <PostImage src={getImageFromServer(postImage)} />
    }
    <PostDetailsWrap>
      { showStore &&
        <StoreWrap>
          <EntityPicture {...{ storeImage, storeBgColor }} entityName={storeName} />
          <InfoWrap>
            <Title variant="textS">{storeName}</Title>
          </InfoWrap>
        </StoreWrap>
      }
      <PostTitleWrap>
        <PostTitle variant="textS" align="left">
          {postTitle}
        </PostTitle>
        {canEdit && handleEditPost && handleDeletePost && (
          <EditDeleteMenu
            onEdit={() => {
              handleEditPost(postId)
            }}
            onDelete={() => {
              handleDeletePost(postId)
            }}
          />
        )}
        {!canEdit && canDelete && handleDeletePost && (
          <EditDeleteMenu
            onDelete={() => {
              handleDeletePost(postId)
            }}
          />
        )}        
      </PostTitleWrap>
      <PostDescription variant="textXS" align="left">
        {postDescription}
      </PostDescription>
      { showCounts &&
      <PostTitleWrap>
        <PostDescription variant="textXS" align="left">
          Likes: {postLikeCount}
        </PostDescription>
        <PostDescription variant="textXS" align="left">
          Interested: {postInterestedCount}
        </PostDescription>
        <PostDescription variant="textXS" align="left">
          {deadlineText}: {postAttendedCount}
        </PostDescription>
      </PostTitleWrap>
      }
      <ButtonWrap>
        {postDeadline ? (
          <Badge
            label={formatPostDeadline(dateStart, dateEnd)}
            variant={BADGE_VARIANTS.PINK}
          />
        ) : (canEdit &&
          <Badge
            label="Read More"
            variant={BADGE_VARIANTS.LIGHT}
            size={BADGE_SIZES.SMALL}
            onClick={() => {
              handleEditPost(postId)
            }}
          />
        )}
      </ButtonWrap>
    </PostDetailsWrap>
  </PostWrap>
)

export default ShopPostItem

ShopPostItem.defaultProps = {
  handleEditPost: null,
  handleDeletePost: null,
  dateStart: null,
  dateEnd: null,
  canEdit: true,
  canDelete: true,
  postImage: null,
  storeImage: null
}

ShopPostItem.propTypes = {
  postId: PropTypes.string.isRequired,
  postImage: PropTypes.string,
  handleEditPost: PropTypes.func,
  handleDeletePost: PropTypes.func,
  postTitle: PropTypes.string.isRequired,
  postDescription: PropTypes.string.isRequired,
  postLikeCount: PropTypes.number.isRequired,
  postInterestedCount: PropTypes.number.isRequired,
  postAttendedCount: PropTypes.number.isRequired,
  postDeadline: PropTypes.bool.isRequired,
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  showCounts: PropTypes.bool.isRequired,
  showStore: PropTypes.bool.isRequired,
  storeName: PropTypes.string.isRequired,
  storeImage: PropTypes.string,
  storeBgColor: PropTypes.string.isRequired,
  deadlineText: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
}
