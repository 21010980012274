/*eslint-disable*/
import React from 'react'
import {
  IconWrap,
  ListOfRequirements,
  ListWrap,
  ShopProfileTutorialWrap,
  ShopWizardWrap,
  TopSection
} from './styles'
import { Store } from '@styled-icons/material'
import Typography from '../../../components/UI/Typography'
import { withTheme } from 'styled-components'
import WizardListItem from '../../../components/WizardListItem'
import { WIZARD_LIST_ITEM_VARIANTS } from '../../../const/UIvariants'

const WIZARD_GUIDE_STEPS = [
  'Click “Create my own shop” or you can click “Shop profile” in left side',
  'Fill in the complete information about your shop, and the exact store address',
  'Upload the products available in your store (Optional)',
  'Upload 3 Picture of your Stores in Gallery',
  'Yeay! your shop has been successfully activated'
]

const ShopAsideWizard = ({ theme }) => {
  return (
    <ShopWizardWrap>
      <ShopProfileTutorialWrap>
        <TopSection>
          <IconWrap>
            <Store size={44} color="hsla(220, 13%, 87%, 1)" />
          </IconWrap>
          <Typography
            variant="headingM"
            align="center"
            color={theme.colors.skyNeutral_2}
          >
            Shop Profile
          </Typography>
          <Typography
            variant="headingS"
            align="center"
            color={theme.colors.skyNeutral2}
          >
            {`Follow us to add information about your shop and activate it `}
          </Typography>
        </TopSection>
        <ListOfRequirements>
          <Typography
            variant="headingM"
            align="left"
            color={theme.colors.skyNeutral_2}
          >
            How to create your shop
          </Typography>
          <ListWrap>
            {WIZARD_GUIDE_STEPS.map((item, index) => (
              <WizardListItem
                key={item}
                variant={WIZARD_LIST_ITEM_VARIANTS.CHECK}
                position={index + 1}
                text={item}
                isCompleted={index % 2 === 0}
              />
            ))}
          </ListWrap>
        </ListOfRequirements>
      </ShopProfileTutorialWrap>
    </ShopWizardWrap>
  )
}

export default withTheme(ShopAsideWizard)

ShopAsideWizard.propTypes = {}
