import styled from 'styled-components'
import {
  DashboardInfoWrap,
  HStack,
  ManageEntityDashboardWrap
} from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const ShopsDashboardWrap = styled(ManageEntityDashboardWrap)
``
export const ShopsWrap = styled(DashboardInfoWrap)`
  overflow: scroll;
`
export const MetricsItem = styled(HStack)`
  width: 100%;
`
export const TableText = styled(Typography).attrs({
  variant: 'textS'
})`
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
