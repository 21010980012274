import styled from 'styled-components'
import { DetailsInfoBlock, HStack, VStack } from '../../../global/styles'
import Typography from '../../../components/UI/Typography'

export const DayItem = styled(VStack)`
  padding-right: 11px;
  flex-basis: 50%;
  margin-bottom: 12px;
`
export const DayLabel = styled(Typography)`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.skyNeutral_1};
`
export const ShopInfoBlock = styled(DetailsInfoBlock)``
export const ShopDetailsWrap = styled(VStack)`
  width: 100%;
`
export const ScheduleWrap = styled(HStack)`
  flex-wrap: wrap;
`

export const TagsWrap = styled(HStack)`
  flex-wrap: wrap;
  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`
export const ButtonWrap = styled(HStack)`
  width: 100%;
  justify-content: space-between;
  margin: 16px 0;
  & > :first-child {
    margin-right: 12px;
  }
`
