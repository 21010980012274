import React from 'react'
import * as PropTypes from 'prop-types'
import { StyledIconButton } from './styles'

const IconButton = ({ children, onClick, type }) => (
  <StyledIconButton {...{ onClick, type }}>{children}</StyledIconButton>
)

export default IconButton

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string
}

IconButton.defaultProps = {
  onClick: () => {},
  type: 'button'
}
