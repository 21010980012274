import styled from 'styled-components'
import { HStack, VStack } from '../../../global/styles'

export const PostsList = styled(HStack)`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-top: 24px;
`
export const AddNewPostWrap = styled(HStack)`
  height: 116px;
  min-width: 40%;
  width: 48%;
  margin-right: 4%;
  position: relative;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-right: 0;
  }
`
export const ImagePlaceholder = styled(VStack)`
  min-width: 153px;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greenCreator(50, 97)};
`

export const AddNewPostBody = styled(VStack)`
  height: 100%;
  justify-content: space-between;
  margin-left: 15px;
`
export const ButtonWrap = styled.div`
  margin-top: auto;
`
export const ErrorMessageWrap = styled(VStack)`
  padding: 16px 16px 0 16px;
  max-width: 50vw;
  align-items: stretch;
`