import styled from 'styled-components'
import { HStack } from '../../../global/styles'

export const ChatInputWrap = styled(HStack)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 18px 24px;
  box-shadow: inset 0 1px 0 rgba(208, 201, 214, 0.4);
`
export const ActionIconsWrap = styled(HStack)`
  align-items: center;
  margin-bottom: 8px;
  margin-left: 33px;
  & > * {
    cursor: pointer;
    &:first-child {
      margin-right: 34px;
    }
  }
`
