import styled from 'styled-components/macro'
import { INPUT_SIZES } from '../../../../const/UIvariants'
import Typography from '../../Typography'

export const StyledInput = styled.input`
  width: 100%;
  height: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.colors.skyNeutral4};
  color: ${({ theme }) => theme.colors.skyNeutral_1};
  outline: none;
  border: none;
  display: flex;
  border-radius: 8px;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 24px;
  font-size: ${({ size }) => (size === INPUT_SIZES.LARGE ? 17 : 15)}px;
  line-height: ${({ size }) => (size === INPUT_SIZES.LARGE ? 20 : 17)}px;
  &[value='']::-webkit-datetime-edit {
    color: transparent;
    display: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
  &::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    margin: 0;
    -webkit-appearance: none;
  }
  &::-webkit-clear-button {
    display: none;
  }
`
export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;

  & :focus-within {
    input {
      box-shadow: 0 0 0 2px hsla(219, 12%, 51%, 1);
      padding-top: ${({ size }) => (size === INPUT_SIZES.LARGE ? 16 : 0)}px;
    }
    label {
      display: block;
      opacity: 1;
    }

    div > svg:first-child {
      color: ${({ theme }) => theme.colors.skyNeutral_1};
    }
    span {
      display: none;
    }
  }
`
export const RightSideIcon = styled.div`
  display: flex;
  right: 11px;
  justify-content: center;
  align-items: center;
  position: absolute;
  align-self: center;
`

export const InputBodyWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`
export const InputError = styled.p`
  font-size: 13px;
  margin-top: 8px;
  height: 16px;
  color: ${({ theme }) => theme.colors.greenCreator(7, 46)};
`
export const Placeholder = styled(Typography)`
  position: absolute;
  top: 12px;
  left: 15px;
  color: ${({ theme }) => theme.colors.skyNeutral2};
`
