import React, { useEffect, useState } from 'react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@styled-icons/material'
import PropTypes from 'prop-types'
import { PageButton, PaginatorButton, PaginatorWrap } from './styles'

const Paginator = ({ currentPage, setCurrentPage, amountOfPages }) => {
  const [pages, setPages] = useState([])
  const getPages = (page, totalPages, pagesToShow = 5) => {
    const offset = Math.floor(pagesToShow / 2)
    if (page <= pagesToShow && totalPages <= pagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }
    if (page <= pagesToShow && totalPages > pagesToShow) {
      return Array.from({ length: pagesToShow }, (_, i) => i + 1)
    }
    if (page > offset && page < totalPages - offset) {
      const start = page - offset
      const end = page + offset
      const result = []
      for (let i = start; i <= end; i += 1) {
        result.push(i)
      }
      return result
    }
    if (page >= totalPages - offset) {
      const start = totalPages + 1 - pagesToShow
      const end = totalPages
      const result = []
      for (let i = start; i <= end; i += 1) {
        result.push(i)
      }
      return result
    }
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }
  useEffect(() => {
    setPages(getPages(currentPage + 1, amountOfPages))
  }, [currentPage, amountOfPages])

  return amountOfPages > 1 ? (
    <PaginatorWrap>
      <PaginatorButton
        disabled={currentPage === 0}
        onClick={() => {
          setCurrentPage(currentPage - 1)
        }}
      >
        <KeyboardArrowLeft size={18} />
        Previous
      </PaginatorButton>
      {pages.map((page) => (
        <PageButton
          key={page}
          isActive={page === (currentPage + 1)}
          onClick={() => {
            setCurrentPage(page - 1)
          }}
        >
          {page}
        </PageButton>
      ))}
      <PaginatorButton
        disabled={(currentPage + 1) === amountOfPages}
        onClick={() => {
          setCurrentPage(currentPage + 1)
        }}
      >
        Next
        <KeyboardArrowRight size={18} />
      </PaginatorButton>
    </PaginatorWrap>
  ) : null
}

export default Paginator

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  amountOfPages: PropTypes.number.isRequired
}
