import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api/api.generated'

const shopSlice = createSlice(
  {
    name:'shopSlice',
    initialState:{
      citiesOptions:null,
      selectedCityId:null,
    },
    reducers:{
      setSelectedCityId: (state , {payload}) => {
        state.selectedCityId = payload
      }
    },
    extraReducers: (builder ) => {
      builder.addMatcher(api?.endpoints?.getCities?.matchFulfilled,
      (state,{payload}) => {
        state.selectedCityId =payload.cities[0].id
        state.citiesOptions = payload.cities.map((city) => ({
          value: city.id,
          label: city.cityName
        }))
      })
    }
  }
)

export default shopSlice.reducer
export const {setSelectedCityId} = shopSlice.actions
