import React from 'react'
import { useSelector } from 'react-redux'
import { selectShopId } from '../../../app/selectors'
import ShopDonations from '../ShopDonations'

const ShopProfileDonations = () => {
  const shopId = useSelector(selectShopId)

  return (
    <ShopDonations shopId={shopId} />
  )
}

export default ShopProfileDonations

ShopProfileDonations.propTypes = {}
